import { gql } from "@apollo/client";
const CREATE_SHIFT_NOTE = gql`
  mutation CreateShiftNote($shiftNoteArgs: ShiftNoteCreateInput!) {
    createShiftNote(shiftNoteArgs: $shiftNoteArgs) {
      message
      code
      success
    }
  }
`;

const GET_SHIFT_NOTES_BY_SHIFTID = gql`
  query ShiftNotesById($shiftId: Int!) {
    shiftNotesById(shiftId: $shiftId) {
      id
      note
      public
      shiftId
      user {
        id
        email
        firstName
      }
      createdAt
    }
  }
`;

export { CREATE_SHIFT_NOTE, GET_SHIFT_NOTES_BY_SHIFTID };
