import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../app/store";
import { Column } from "react-table";
import AddIcon from "@mui/icons-material/Add";
import { Pagination } from "../../../../types/types";
import BaseTable from "../../../UI/Table/Table";
import Button from "../../../UI/Button/Button";
import ActionsCell from "../../../UI/ActionCell";
import Headline1Variable from "../../../UI/Text/Headline/Headline1Variable";
import TablePagination from "../../../UI/Pagination/Pagination";
import {
  ThirdPartyContractorPaginatedQuery,
  useThirdPartyContractorPaginatedLazyQuery,
} from "../../../../generated/graphql";
import LoadingDialog from "../../../UI/Dialog/LoadingDialog";
import { FormFieldSelect } from "../../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";

// List of options for action col
const options = [{ id: 1, text: "Edit" }];

// Type that is needed for action col cell
interface CellParam {
  row: {
    original: ThirdPartyContractorPaginatedQuery["thirdPartyContractorPaginated"]["thirdPartyContractor"][0];
  };
  rows: {
    original: ThirdPartyContractorPaginatedQuery["thirdPartyContractorPaginated"]["thirdPartyContractor"][0];
  }[];
}

const ThirdPartyContractorReport: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { selectedRegions, sports }: any = useSelector(
    (state: RootState) => state.venueMaster
  );

  //Pagination Page Size
  const pageSize = 10;

  /*** STATES ***/

  // Basic filter add new id types here for more filtering
  const [filter, setFilter] = useState<{
    pagination: Pagination;
    regionId: number;
  }>({
    pagination: {
      page: 0,
      pageCount: 0,
      pageSize: pageSize,
    },
    regionId: 0,
  });

  /*** QUERIES ***/
  // Query to get whatever will be dislayed on the table
  const [getThirdPartyContractors, { loading, data }] =
    useThirdPartyContractorPaginatedLazyQuery({
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        const pageCount = Math.ceil(
          data.thirdPartyContractorPaginated.count / pageSize
        );
        setFilter((prevState) => ({
          ...prevState,
          pagination: {
            ...prevState.pagination,
            pageCount: pageCount,
          },
        }));
      },
    });

  /*** USE EFFECTS ***/
  // Queries the data whenever the filter changes add any new filterable ids to the dependancy array
  useEffect(() => {
    if (filter) {
      getThirdPartyContractors({
        variables: {
          page: filter.pagination.page,
          pageSize: filter.pagination.pageSize,
          regionId: filter.regionId ?? null,
        },
      });
    }
  }, [filter.pagination.page, filter.regionId]);

  /*** UTILITY FUNCTIONS ***/
  // Handles the state for the pagination component
  const handleChangePagination = (value: number) => {
    setFilter((prevState) => ({
      ...prevState,
      pagination: {
        pageCount: prevState.pagination.pageCount,
        pageSize: prevState.pagination.pageSize,
        page: value - 1,
      },
    }));
  };

  // The type for the row should be a single record of the data that is being returned (access the first index of the array)
  const handleSelectedOption = (
    row: ThirdPartyContractorPaginatedQuery["thirdPartyContractorPaginated"]["thirdPartyContractor"][0],
    idx: number
  ) => {
    if (idx === 1) {
      navigate(`/staffing/third-party-contractor-management/${row.id}`);
    }
  };

  // Table col definition
  // The type for the row should be a single record of the data that is being returned (access the first index of the array)
  const COLUMNS: Column<
    ThirdPartyContractorPaginatedQuery["thirdPartyContractorPaginated"]["thirdPartyContractor"][0]
  >[] = [
    {
      Header: "Id",
      id: "Id",
      accessor: (d) => {
        return <div>{d.id}</div>;
      },
    },
    {
      Header: "Name",
      id: "name",
      accessor: (d) => {
        return <div>{d.name}</div>;
      },
    },
    {
      Header: "Email",
      id: "email",
      accessor: (d) => {
        return <div>{d.email}</div>;
      },
    },
    {
      Header: "Region",
      id: "region",
      accessor: (d) => {
        return <div>{d.region ? d.region.name : "All"}</div>;
      },
    },

    {
      Header: " ",
      Cell: (d: CellParam) => {
        return (
          <ActionsCell
            row={d.row.original}
            options={options}
            handleSelectedOption={handleSelectedOption}
          />
        );
      },
    },
  ];

  // Constants for the table
  const columns = useMemo(() => COLUMNS, []);
  const tableData = useMemo(() => {
    if (data) return data.thirdPartyContractorPaginated.thirdPartyContractor;
  }, [data]);

  return (
    <main className="flex flex-col gap-4">
      <LoadingDialog open={loading} />
      <div className="flex flex-row justify-between w-full">
        <Headline1Variable>
          Third Party Contractor Management Report
        </Headline1Variable>

        <div className="mb-4 text-end">
          <Button
            variant="primary"
            onClick={() =>
              navigate("/staffing/third-party-contractor-management/new")
            }
          >
            <AddIcon />
            New Third Party Contractor
          </Button>
        </div>
      </div>
      <div className="flex flex-row gap-4">
        <div className="max-w-60 min-w-60">
          <FormFieldSelect
            value={filter.regionId ? filter.regionId.toString() : "0"}
            inputChange={(value) => {
              setFilter((prevState) => ({
                ...prevState,
                regionId: +value,
              }));
            }}
            name="regionId"
            id="regionId"
            label="Region"
            placeholder="Select Region"
            className="h-[46px] m-0 "
          >
            {selectedRegions.length > 0
              ? [...[{ id: 0, name: "All" }], ...selectedRegions]
              : [...[{ id: 0, name: "None" }]]}
          </FormFieldSelect>
        </div>
      </div>
      {tableData && (
        <BaseTable
          columns={columns}
          data={tableData}
        />
      )}
      {filter.pagination && (
        <div className="flex justify-end mt-4">
          <TablePagination
            page={filter.pagination.page || 0}
            pageCount={filter.pagination.pageCount || 0}
            onChange={handleChangePagination}
          />
        </div>
      )}
    </main>
  );
};

export default ThirdPartyContractorReport;
