import { gql } from "@apollo/client";

const CREATE_SCHEDULE = gql`
  mutation ScheduleCreate($scheduleArgs: CreateScheduleInput!) {
    scheduleCreate(scheduleArgs: $scheduleArgs) {
      code
      success
      message
    }
  }
`;

const UPDATE_SCHEDULE = gql`
  mutation ScheduleUpdate($scheduleArgs: UpdateScheduleInput!) {
    scheduleUpdate(scheduleArgs: $scheduleArgs) {
      code
      success
      message
    }
  }
`;

const GET_SCHEDULE_STATUSES = gql`
  query ScheduleStatuses {
    scheduleStatuses {
      id
      scheduleStatusName
    }
  }
`;

const GET_SCHEDULE_BY_ID = gql`
  query ScheduleById($scheduleById: Int!) {
    scheduleById(id: $scheduleById) {
      regularGames {
        id
        startDateTimeLocal
        isVisible
        week
        countAwayScore
        countHomeScore
        venue {
          id
          name
        }
        homeTeam {
          id
          name
        }
        awayTeam {
          id
          name
        }
        gameType {
          id
          gameTypeName
        }
        gameStatus {
          id
          name
        }
      }
      playoffGames {
        id
        startDateTimeLocal
        isVisible
        week
        gamePlayoffMatchup {
          id
          gameId
          homeTeamType
          homeTeamValue
          awayTeamType
          awayTeamValue
        }
        venue {
          id
          name
        }
        homeTeam {
          id
          name
        }
        awayTeam {
          id
          name
        }
        gameType {
          id
          gameTypeName
        }
        gameStatus {
          id
          name
        }
      }
    }
  }
`;

const DELETE_SCHEDULES_BY_DIVISION_ID = gql`
  mutation DeleteSchedulesByDivisionId($divisionId: Int!) {
    deleteSchedulesByDivisionId(divisionId: $divisionId) {
      code
      success
      message
    }
  }
`;

gql`
  mutation ScheduleRestore($scheduleArgs: UpdateScheduleInput!) {
    scheduleRestore(scheduleArgs: $scheduleArgs) {
      code
      success
      message
    }
  }
`;

export {
  CREATE_SCHEDULE,
  UPDATE_SCHEDULE,
  GET_SCHEDULE_STATUSES,
  GET_SCHEDULE_BY_ID,
};
