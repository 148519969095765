"use client";

import React, { FC, HTMLProps, useEffect, useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton } from "@mui/material";

interface FormFieldPasswordProps extends HTMLProps<HTMLInputElement> {
  /**
   * The placeholder for the formfield
   */
  placeholder?: string;
  /**
   * The label for the formfield (text above the field)
   */
  label?: string;
  /**
   * The assitive text for the field (text under the field)
   */
  assistiveText?: string;
  /**
   * Set to true if the input should be disabled.
   */
  disabled?: boolean;
  /**
   * Set to true if the input should be showing an error state
   */
  error?: boolean;
  /**
   * The function that is called whenever the input changes
   */
  inputChange: (data: any) => void;

  initialValue?: string;
}

const FormFieldPassword: FC<FormFieldPasswordProps> = ({
  placeholder,
  label,
  assistiveText,
  disabled,
  error,
  inputChange,
  initialValue,
  ...props
}: FormFieldPasswordProps) => {
  const [showPass, setShowPass] = useState(false);
  const [inputVal, setInputVal] = useState("");

  useEffect(() => {
    setInputVal(initialValue ? initialValue : "");
  }, [initialValue]);

  const mode = disabled
    ? "border-2 border-neutral-60 bg-neutral-90 focus:outline-none"
    : error
    ? "border border-error-30 hover:border-error-30 focus:outline-2 focus:outline-error-30"
    : "border border-secondary-80 hover:border-secondary-50 focus:outline-2 focus:outline-primary-80";

  return (
    <div className={`flex flex-col w-full text-black `}>
      <label
        className={`px-3 text-xs font-medium ${
          error && !disabled && "text-error-10"
        }`}
      >
        {label}
      </label>
      <div className="relative flex flex-row items-center gap-2">
        <input
          disabled={disabled}
          placeholder={placeholder}
          className={`${mode} w-full font-normal rounded-xl px-3 py-2 my-0.5`}
          {...props}
          type={showPass ? "text" : "password"}
          value={inputVal}
          onChange={(val) => {
            inputChange(val);
            setInputVal(val.target.value);
          }}
        ></input>
        {/* Changes the Icon for a password input */}
        <IconButton
          className="absolute right-0 text-light"
          onClick={() => setShowPass((prev) => !prev)}
        >
          {showPass ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </div>
      <div
        className={`px-3 text-xxs font-normal ${
          error && !disabled ? "text-error-10" : "text-light"
        }`}
      >
        {assistiveText}
      </div>
    </div>
  );
};

export default FormFieldPassword;
