"use client";

import { FC, Fragment } from "react";
import { Pagination, PaginationProps } from "@mui/material";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../UI/shadcn/select";

interface PaginationUIProps extends Omit<PaginationProps, "onChange"> {
  /**
   * Current Page
   */
  page: number;
  /**
   * Total Page Count
   */
  pageCount: number;
  /**
   * onChange event handler for page change
   */
  onChange: (page: number) => void;
  /**
   * Page Size
   */
  pageSize?: number;
  /**
   * onChange event handler for rows per page change
   */
  onRowsPerPageChange?: (rowsPerPage: number) => void;
}

const TablePagination: FC<PaginationUIProps> = ({
  page,
  pageCount,
  pageSize,
  onChange,
  onRowsPerPageChange,
  ...props
}) => {
  return (
    <Fragment>
      {pageSize && onRowsPerPageChange && (
        <div className="mx-2">
          <Select
            value={`${pageSize?.toString()}`}
            onValueChange={(value) => {
              onRowsPerPageChange(Number(value));
            }}
          >
            <SelectTrigger className="h-8 w-[70px]">
              <SelectValue placeholder={pageSize?.toString()} />
            </SelectTrigger>
            <SelectContent side="top">
              {[5, 10, 25, 50].map((pageSize) => (
                <SelectItem
                  key={pageSize}
                  value={`${pageSize}`}
                  className="hover:bg-primary-95"
                >
                  {pageSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      )}
      <div className="my-auto text-xs text-disabled">
        Showing {page + 1} of {pageCount} pages
      </div>
      <Pagination
        page={page}
        count={pageCount || 1}
        onChange={(event, value) => onChange(value)}
        {...props}
      />
    </Fragment>
  );
};

export default TablePagination;
