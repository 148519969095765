import { Outlet } from "react-router-dom";
// import { refToken } from '../app/authSlice';
import classes from "./Home.module.css";
import Navbar from "../components/Home/Navbar";
import LeftNavDrawer from "../components/Home/LeftNavDrawer";

const Home = () => {
  // const dispatch = useDispatch<AppDispatch>();
  // useEffect(() => {
  //   return () => {
  //     dispatch(refToken());
  //   };
  // }, [dispatch]);

  return (
    <div className={classes.app}>
      <Navbar />
      <div className={classes.app__body}>
        <div className={classes.app__main}>
          <LeftNavDrawer />
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Home;
