"use client";

import React, { FC, HTMLProps, ReactNode } from "react";
import "./button.css";
import { cn } from "../../../lib/utils";

interface ButtonProps extends HTMLProps<HTMLButtonElement> {
  /**
   * The label of the button
   */
  label?: string;
  /**
   * The type of button
   */
  variant: "primary" | "secondary" | "negative" | "disabled";
  /**
   * Children of the button component. Allows jsx to be injected into the content of the button.
   */
  children: ReactNode;
  /**
   * Allows custom CSS to be used to style the button.
   */
  width?: "full" | "1/2" | "1/3" | "1/4";
  /**
   * Allows custom CSS to be used to style the button.
   */
  height?: string;
  /**
   * Custom classname for button styling
   */
  className?: string;
  /**
   * Type of button
   */
  type?: "button" | "submit" | "reset" | undefined;
  /**
   * Link to other page
   */
  href?: string;
}

const Button: FC<ButtonProps> = ({
  label,
  variant,
  children,
  width,
  height,
  className,
  type,
  href,
  ...props
}: ButtonProps) => {
  const combinedStyle =
    `storybook-button storybook-button--medium storybook-button--${variant} ` +
    "w-" +
    width +
    " h-" +
    height;

  if (href) {
    return (
      <a href={href}>
        <button
          className={cn(combinedStyle, className)}
          type={type}
          {...props}
        >
          {children}
        </button>
      </a>
    );
  } else {
    return (
      <button
        className={cn(combinedStyle, className)}
        type={type}
        {...props}
      >
        {children}
      </button>
    );
  }
};

export default Button;
