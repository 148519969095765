import React, { useEffect } from "react";
import { LoadingMaterialUI } from "../UI";
import classes from "./CreateSeason.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { useNavigate, useParams } from "react-router-dom";
import CheckBox from "../UI/Checkbox/Checkbox";
import BreadCrumbs from "../UI/Breadcrumbs/Breadcrumbs";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import Button from "../UI/Button/Button";
import Card from "../UI/Card/Card";
import Subtitle1 from "../UI/Text/Subtitle/Subtitle1";
import FormField from "../UI/FormField/FormField";
import dayjs from "dayjs";
import Body1 from "../UI/Text/Body/Body1";
import { DateTimePicker } from "../UI/shadcn/Time/date-time-picker";
import Caption1 from "../UI/Text/Caption/Caption1";
import { z } from "zod";
import { displayAlertError, displayAlertSuccess } from "../../app/globalSlice";
import Alert from "../UI/Alerts/Alert";
import {
  ListAllOperations,
  useRegistrationBatchCreateMutation,
  useRegistrationBatchQuery,
  useRegistrationBatchUpdateMutation,
} from "../../generated/graphql";
import LoadingDialog from "../UI/Dialog/LoadingDialog";
import { client } from "@/src/graphql";

const SeasonSchema = z
  .object({
    id: z.number().optional(),
    name: z.string().trim().min(1, { message: "Season name is required" }),
    regOpenDate: z.date().optional(),
    regCloseDate: z.date().optional(),
    displayPrice: z.boolean(),
    displaySession: z.boolean(),
  })
  .refine(
    (data) => {
      return (
        data.regCloseDate !== undefined &&
        data.regOpenDate !== undefined &&
        data.regCloseDate > data.regOpenDate
      );
    },
    { message: "Reg Close Date must occur after Reg Open Date" }
  );

type SeasonType = z.infer<typeof SeasonSchema>;

const CreateSeason: React.FC = () => {
  const params = useParams();

  const dispatch = useDispatch<AppDispatch>();

  const [season, setSeason] = React.useState<SeasonType>({
    id: undefined,
    name: "",
    regOpenDate: new Date(),
    regCloseDate: new Date(),
    displayPrice: false,
    displaySession: false,
  });
  const [seasonErrorMessages, setSeasonErrorMessages] = React.useState<
    string[]
  >([]);
  const [seasonError, setSeasonError] = React.useState<{
    [key: string]: string;
  }>({
    name: "",
    regOpenDate: "",
    regCloseDate: "",
  });

  const [
    CreateRegistrationBatch,
    { data: dataCreate, loading: loadingCreate, error: errorCreate },
  ] = useRegistrationBatchCreateMutation();
  const [
    UpdateRegistrationBatch,
    { data: dataUpdate, loading: loadingUpdate, error: errorUpdate },
  ] = useRegistrationBatchUpdateMutation();

  const { loading: loadingRegistrationBatch } = useRegistrationBatchQuery({
    variables: {
      registrationBatchId: +params.id!,
    },
    skip: params.id === undefined,
    onCompleted: (data) => {
      console.log(data);
      if (data.registrationBatch) {
        setSeason({
          id: data.registrationBatch.id,
          name: data.registrationBatch.name,
          regOpenDate: data.registrationBatch.regOpenDate
            ? new Date(data.registrationBatch.regOpenDate)
            : undefined,
          regCloseDate: data.registrationBatch.regCloseDate
            ? new Date(data.registrationBatch.regCloseDate)
            : undefined,
          displayPrice: data.registrationBatch.displayPrice,
          displaySession: data.registrationBatch.displaySession,
        });
      } else {
        dispatch(displayAlertError("Couldn't fetch season"));
      }
    },
  });

  const navigate = useNavigate();

  const handleSubmit = () => {
    const result = SeasonSchema.safeParse(season);
    let seasonArgs;
    if (result.success) {
      seasonArgs = {
        name: season.name,
        regOpenDate: season.regOpenDate,
        regCloseDate: season.regCloseDate,
        displayPrice: season.displayPrice,
        displaySession: season.displaySession,
      };
      if (season.id === undefined) {
        CreateRegistrationBatch({
          variables: {
            registrationBatchArgs: { registrationBatchInput: seasonArgs },
          },
          refetchQueries: [ListAllOperations.Query.RegistrationBatch],
          onCompleted: (data) => {
            if (data.registrationBatchCreate.success) {
              dispatch(
                displayAlertSuccess(data.registrationBatchCreate.message)
              );
              navigate(`/ops/seasons`);
            } else {
              dispatch(displayAlertError(data.registrationBatchCreate.message));
            }
          },
          onError: (error) => {
            dispatch(
              displayAlertError(
                `Season: Something Went Wrong. Error: ${error.message}`
              )
            );
          },
        });
      } else {
        UpdateRegistrationBatch({
          variables: {
            registrationBatchArgs: {
              id: +season.id,
              registrationBatchInput: seasonArgs,
            },
          },
          refetchQueries: [ListAllOperations.Query.RegistrationBatch],
          onCompleted: (data) => {
            if (data.registrationBatchUpdate.success) {
              dispatch(
                displayAlertSuccess(data.registrationBatchUpdate.message)
              );
              navigate(`/ops/seasons`);
            } else {
              dispatch(displayAlertError(data.registrationBatchUpdate.message));
            }
          },
          onError: (error) => {
            dispatch(
              displayAlertError(
                `Season: Something Went Wrong. Error: ${error.message}`
              )
            );
          },
        });
      }
    } else {
      const newFormErrors = { ...seasonError };
      const newFormErrorKeys = Object.keys(newFormErrors);
      let messages: string[] = [];
      newFormErrorKeys.forEach((field) => {
        const fieldError = result.error.errors.find(
          (error) => error.path[0] === field
        );
        if (fieldError) {
          newFormErrors[field] = fieldError.message; // Set the error message
          messages.push(`Error ${field}: ${fieldError.message}`);
        } else {
          newFormErrors[field] = ""; // No matching error, set to an empty string
        }
      });
      setSeasonError(newFormErrors);
      setSeasonErrorMessages(messages);
      dispatch(
        displayAlertError(
          `Please fix the form errors before ${
            season.id ? "updating" : "creating"
          } the season.`
        )
      );
    }
  };

  if (loadingCreate || loadingUpdate || loadingRegistrationBatch) {
    return <LoadingDialog open={true} />;
  }

  const pathsBreadcrumbs = [
    { name: "Seasons", url: "/ops/seasons" },
    { name: "Season Details", url: "/ops/seasons" },
  ];

  return (
    <main className="h-[150vp] pb-80">
      <BreadCrumbs
        paths={pathsBreadcrumbs}
        goBackTo="/ops/seasons"
      ></BreadCrumbs>
      <div className="flex flex-row justify-between w-full mt-6">
        <Headline1Variable>
          {season.id ? "Edit Season" : "Create New Season"}
        </Headline1Variable>
        <div className="h-10">
          <Button
            variant="primary"
            onClick={() => handleSubmit()}
          >
            {season.id ? <span>Edit Season</span> : <span>Create Season</span>}
          </Button>
        </div>
      </div>
      {seasonErrorMessages.length > 0 && (
        <div className="flex flex-col gap-2">
          {seasonErrorMessages.map((errorMessage) => {
            return (
              <Alert
                variant="error"
                size="large"
                content={errorMessage}
                persist={true}
              />
            );
          })}
        </div>
      )}
      <Card className="mt-6">
        <Subtitle1>Season Details</Subtitle1>
        <div className="flex flex-row items-center justify-center w-full gap-4 mt-6">
          <FormField
            initialValue={season.name ? season.name : ""}
            inputChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSeason({ ...season, name: e.target.value })
            }
            name="name"
            label="Season Name"
          ></FormField>
          <div className="w-1/3 m-auto">
            <CheckBox
              defaultChecked={season.displayPrice}
              id="displayPrice"
              label="Display Price"
              inputChange={(val: boolean) =>
                setSeason({ ...season, displayPrice: val })
              }
            />
          </div>
          <div className="w-1/3 m-auto">
            <CheckBox
              defaultChecked={season.displaySession}
              id="displaySession"
              label="Display Session"
              inputChange={(val: boolean) =>
                setSeason({ ...season, displaySession: val })
              }
            />
          </div>
        </div>
      </Card>
      <Card className="mt-6">
        <Subtitle1>Registration Dates</Subtitle1>
        <div className="flex flex-row w-full gap-4 mt-6">
          <div className="w-1/2">
            <div className="flex flex-col gap-1">
              <Caption1 className="font-medium">Reg Open Date</Caption1>
              <DateTimePicker
                date={
                  season.regOpenDate ? new Date(season.regOpenDate) : undefined
                }
                setDate={(date) =>
                  setSeason({
                    ...season,
                    regOpenDate: date,
                  })
                }
              />
            </div>
            {season.regOpenDate && (
              <div className="flex flex-col">
                <div className="flex flex-row">
                  <Body1>Toronto Start Time: </Body1>
                  <Body1>
                    {dayjs(new Date(season.regOpenDate))
                      .tz("America/Toronto")
                      .format("YYYY-MM-DD h:mm A")}
                  </Body1>
                </div>
                <div className="flex flex-row">
                  <Body1>Winnepeg Start Time: </Body1>
                  <Body1>
                    {dayjs(new Date(season.regOpenDate))
                      .tz("America/Winnipeg")
                      .format("YYYY-MM-DD h:mm A")}
                  </Body1>
                </div>
              </div>
            )}
          </div>
          <div className="w-1/2">
            <div className="flex flex-col gap-1">
              <Caption1 className="font-medium">Reg Close Date</Caption1>
              <DateTimePicker
                date={
                  season.regCloseDate
                    ? new Date(season.regCloseDate)
                    : undefined
                }
                setDate={(date) =>
                  setSeason({
                    ...season,
                    regCloseDate: date,
                  })
                }
              />
            </div>
            {season.regCloseDate && (
              <div className="flex flex-col">
                <div className="flex flex-row">
                  <Body1>Toronto Close Time: </Body1>
                  <Body1>
                    {dayjs(new Date(season.regCloseDate))
                      .tz("America/Toronto")
                      .format("YYYY-MM-DD h:mm A")}
                  </Body1>
                </div>
                <div className="flex flex-row">
                  <Body1>Winnepeg Close Time: </Body1>
                  <Body1>
                    {dayjs(new Date(season.regCloseDate))
                      .tz("America/Winnipeg")
                      .format("YYYY-MM-DD h:mm A")}
                  </Body1>
                </div>
              </div>
            )}
          </div>
        </div>
      </Card>
      <div className="h-10 mt-5 text-right">
        <Button
          variant="primary"
          onClick={() => handleSubmit()}
        >
          {season.id ? <span> Edit Season</span> : <span> Create Season</span>}
        </Button>
      </div>
    </main>
  );
};

export default CreateSeason;
