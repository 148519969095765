import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../app/store";
import { Column } from "react-table";
import AddIcon from "@mui/icons-material/Add";
import { Pagination } from "../../../types/types";
import BaseTable from "../../UI/Table/Table";
import Button from "../../UI/Button/Button";
import ActionsCell from "../../UI/ActionCell";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import TablePagination from "../../UI/Pagination/Pagination";
import {
  CapacityGroupsPaginatedQuery,
  ListAllOperations,
  useCapacityGroupsPaginatedLazyQuery,
  useMergeCapacityGroupMutation,
  useRegionsQuery,
  useVenuesQuery,
} from "../../../generated/graphql";
import LoadingDialog from "../../UI/Dialog/LoadingDialog";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { getSports } from "../../../app/venueMasterSlice";
import { FormFieldSelectMulti } from "../../UI/FormField/FormFieldSelectMulti/FormFieldSelectMulti";
import { dayOfWeek } from "../../../utils/dayOfWeek";
import Headline2Variable from "../../UI/Text/Headline/Headline2Variable";
import Body1 from "../../UI/Text/Body/Body1";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "../../UI/shadcn/dialog";
import { z, ZodFormattedError } from "zod";
import {
  displayAlertError,
  displayAlertSuccess,
  displayAlertWarning,
} from "../../../app/globalSlice";
import { DataTable } from "../../UI/Table/DataTable";
import { ColumnDef, RowSelectionState } from "@tanstack/react-table";
import { Checkbox } from "../../UI/shadcn/checkbox";
import FormFieldControlled from "../../UI/FormField/FormFieldControlled";
import Caption1 from "../../UI/Text/Caption/Caption1";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../UI/shadcn/tooltip";

const MergeCapacityGroupSchema = z
  .object({
    name: z.string(),
    rootCapacityGroupId: z.number(),
    branchCapacityGroupId: z.array(
      z.object({ id: z.number(), name: z.string() })
    ),
  })
  .refine((data) => data.name.length > 0, {
    message: "Name cannot be empty",
    path: ["name"],
  });

type MergeCapacityGroupFormValues = z.infer<typeof MergeCapacityGroupSchema>;

const initialMergeCapacityGroupData: MergeCapacityGroupFormValues = {
  rootCapacityGroupId: 0,
  name: "",
  branchCapacityGroupId: [],
};

// List of options for action col
const options = [{ id: 1, text: "Edit" }];

const CapacityGroupOverview: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { selectedRegions, sports }: any = useSelector(
    (state: RootState) => state.venueMaster
  );

  //Pagination Page Size
  const pageSize = 10;

  /*** STATES ***/
  const [mergeCapacityGroupData, setMergeCapacityGroupData] =
    useState<MergeCapacityGroupFormValues>(initialMergeCapacityGroupData);
  // Zod errors used to show errors on the form
  const [mergeZodErrors, setMergeZodErrors] = useState<
    ZodFormattedError<MergeCapacityGroupFormValues, string>
  >({ _errors: [] });

  // Basic filter add new id types here for more filtering
  const [filter, setFilter] = useState<{
    pagination: Pagination;
    dayOfWeek: number[];
    venueIds: number[];
    regionIds: number[];
  }>({
    pagination: {
      page: 0,
      pageCount: 0,
      pageSize: pageSize,
    },
    dayOfWeek: [],
    venueIds: [],
    regionIds: [],
  });

  /*** QUERIES ***/
  const [
    getCapacityGroup,
    { loading: loadingCapacityGroups, data: capacityGroupsData },
  ] = useCapacityGroupsPaginatedLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-first",
    nextFetchPolicy: "network-only",
    onCompleted: (data) => {
      const pageCount = Math.ceil(
        data.capacityGroupsPaginated.count / pageSize
      );
      setFilter((prevState) => ({
        ...prevState,
        pagination: {
          ...prevState.pagination,
          pageCount: pageCount,
        },
      }));
    },
  });

  const { loading: loadingVenues, data: venuesData } = useVenuesQuery({
    variables: {
      venueFilters: {
        regionId: filter.regionIds,
      },
    },
  });

  /*** MUTATIONS ***/
  const [MergeCapacityGroup, { loading: loadingMerge }] =
    useMergeCapacityGroupMutation();

  /*** USE EFFECTS ***/
  // Queries the data whenever the filter changes add any new filterable ids to the dependancy array
  useEffect(() => {
    if ((filter && filter.venueIds.length > 0) || filter.dayOfWeek.length > 0) {
      console.log(filter);
      getCapacityGroup({
        variables: {
          page: filter.pagination.page,
          pageSize: filter.pagination.pageSize,
          regionIds: filter.regionIds,
          venueIds: filter.venueIds,
          dayOfWeek: filter.dayOfWeek,
        },
      });
    }
  }, [filter.pagination.page]);

  /*** UTILITY FUNCTIONS ***/
  async function handleSubmitMerge() {
    // Parses the form and sets errors if there is an issue with validation
    const result = MergeCapacityGroupSchema.safeParse(mergeCapacityGroupData);
    if (!result.success) {
      // setMergeZodErrors(result.error.format());
      dispatch(displayAlertWarning("There is with the merge form"));
      return;
    }
    // Creates or updates data
    await MergeCapacityGroup({
      variables: {
        name: mergeCapacityGroupData.name,
        branchCapacityGroupIds: mergeCapacityGroupData.branchCapacityGroupId
          .map((branch) => branch.id)
          .filter(
            (branch) => branch !== mergeCapacityGroupData.rootCapacityGroupId
          ),
        rootCapacityGroupId: mergeCapacityGroupData.rootCapacityGroupId,
      },
      refetchQueries: [ListAllOperations.Query.CapacityGroupsPaginated],
      onCompleted: (data) => {
        if (data.mergeCapacityGroup.success) {
          dispatch(displayAlertSuccess(data.mergeCapacityGroup.message));
        } else {
          dispatch(displayAlertError(data.mergeCapacityGroup.message));
        }
      },
      onError: (error) => {
        console.log(error);
        dispatch(displayAlertError(error.message));
      },
    });

    return;
  }

  async function handleApplyFilter() {
    if (filter) {
      getCapacityGroup({
        variables: {
          page: filter.pagination.page,
          pageSize: filter.pagination.pageSize,
          regionIds: filter.regionIds,
          venueIds: filter.venueIds,
          dayOfWeek: filter.dayOfWeek,
        },
      });
    }
  }

  // Handles the state for the pagination component
  const handleChangePagination = (value: number) => {
    setFilter((prevState) => ({
      ...prevState,
      pagination: {
        pageCount: prevState.pagination.pageCount,
        pageSize: prevState.pagination.pageSize,
        page: value - 1,
      },
    }));
  };

  // The type for the row should be a single record of the data that is being returned (access the first index of the array)
  const handleSelectedOption = (
    row: CapacityGroupsPaginatedQuery["capacityGroupsPaginated"]["capacityGroups"][0],
    idx: number
  ) => {
    if (idx === 1) {
      navigate(`/ops/capacity-group/${row.id}`);
    }
  };

  // Table col definition
  // The type for the row should be a single record of the data that is being returned (access the first index of the array)
  const COLUMNS: ColumnDef<
    CapacityGroupsPaginatedQuery["capacityGroupsPaginated"]["capacityGroups"][0]
  >[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            table.getIsSomePageRowsSelected()
          }
          onCheckedChange={(value) => {
            setMergeCapacityGroupData((prevState) => ({
              ...prevState,
              branchCapacityGroupId: value
                ? (() => {
                    let branchs: { id: number; name: string }[] = [];
                    for (let i = 0; i < table.getRowCount(); i++) {
                      branchs.push({
                        id: table.getRow(i.toString()).original.id,
                        name: table.getRow(i.toString()).original.name,
                      });
                    }
                    return branchs;
                  })()
                : [],
            }));
            table.setRowSelection((current) => {
              const newSelection: RowSelectionState = {};
              for (let i = 0; i < table.getRowCount(); i++) {
                const row = table.getRow(i.toString());
                newSelection[i] = !!value;
              }
              return newSelection;
            });
          }}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => {
        return (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => {
              if (value) {
                setMergeCapacityGroupData((prevState) => ({
                  ...prevState,
                  branchCapacityGroupId: [
                    ...prevState.branchCapacityGroupId,
                    { id: row.original.id, name: row.original.name },
                  ],
                }));
              } else {
                setMergeCapacityGroupData((prevState) => ({
                  ...prevState,
                  branchCapacityGroupId: prevState.branchCapacityGroupId.filter(
                    (branch) => branch.id !== row.original.id
                  ),
                }));
              }
              row.toggleSelected(!!value);
            }}
            aria-label="Select row"
          />
        );
      },
    },
    {
      header: "Id",
      id: "Id",
      accessorFn: (row) => row.id,
    },
    {
      header: "Name",
      id: "name",
      accessorFn: (row) => row.name,
    },
    {
      id: "action",
      header: "",
      cell: ({ row }) => {
        return (
          <ActionsCell
            row={row.original}
            options={options}
            handleSelectedOption={handleSelectedOption}
          />
        );
      },
    },
  ];

  // Constants for the table
  const tableData = useMemo(() => {
    if (capacityGroupsData)
      return capacityGroupsData.capacityGroupsPaginated.capacityGroups;
  }, [capacityGroupsData]);
  const columns = useMemo(() => COLUMNS, []);

  return (
    <main className="flex flex-col gap-4">
      <LoadingDialog open={loadingCapacityGroups || loadingMerge} />
      <div className="flex flex-row justify-between w-full">
        <Headline1Variable>Capacity Group Overview</Headline1Variable>
        <Dialog>
          <DialogTrigger
            disabled={mergeCapacityGroupData.branchCapacityGroupId.length <= 1}
          >
            <Button
              variant={
                mergeCapacityGroupData.branchCapacityGroupId.length <= 1
                  ? "disabled"
                  : "secondary"
              }
              disabled={
                mergeCapacityGroupData.branchCapacityGroupId.length <= 1
              }
              className="w-60"
              onClick={() => {
                if (capacityGroupsData) {
                  setMergeCapacityGroupData((prevState) => ({
                    ...prevState,
                    rootCapacityGroupId: prevState.branchCapacityGroupId[0].id,
                    name: capacityGroupsData.capacityGroupsPaginated
                      .capacityGroups[0].name,
                    branchCapacityGroupId:
                      prevState.branchCapacityGroupId.filter(
                        (branch) =>
                          branch.id !== prevState.branchCapacityGroupId[0].id
                      ),
                  }));
                }
              }}
            >
              Merge
            </Button>
          </DialogTrigger>
          <DialogContent className="max-w-[600px]">
            <DialogHeader>Merge capacity groups</DialogHeader>
            <DialogDescription>
              <div className="flex flex-col gap-2">
                <Body1>
                  The following capactiy groups will be merged into one with a
                  new name
                </Body1>
                <div className="flex flex-col gap-1">
                  {mergeCapacityGroupData.branchCapacityGroupId.map(
                    (branch) => {
                      return <Caption1>{branch.name}</Caption1>;
                    }
                  )}
                </div>
                <Body1>This will be the new name of the capacity group</Body1>
                <FormFieldControlled
                  name="name"
                  value={mergeCapacityGroupData.name}
                  label="New Name"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setMergeCapacityGroupData((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }));
                  }}
                />
              </div>
            </DialogDescription>
            <DialogFooter>
              <div className="flex flex-row gap-4">
                <DialogClose>
                  <Button variant="secondary">Cancel</Button>
                </DialogClose>
                <DialogClose>
                  <Button
                    variant="primary"
                    onClick={handleSubmitMerge}
                  >
                    Confirm
                  </Button>
                </DialogClose>
              </div>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
      {/* Filtering section */}
      <div className="flex flex-row items-end gap-4">
        <div className="max-w-60 min-w-60">
          <FormFieldSelectMulti
            name="regionIds"
            input={selectedRegions ?? []}
            addAllOption={false}
            selectedItems={filter.regionIds}
            setSelectedItems={(values) => {
              setFilter((prevState) => ({
                ...prevState,
                regionIds: values,
                venueIds: values.length === 0 ? [] : prevState.venueIds,
              }));
            }}
            label="Region"
            placeholder="Region"
          />
        </div>
        <div className="max-w-60 min-w-60">
          <FormFieldSelectMulti
            name="venueIds"
            input={venuesData?.venues ?? []}
            addAllOption={false}
            selectedItems={filter.venueIds}
            setSelectedItems={(values) => {
              setFilter((prevState) => ({
                ...prevState,
                venueIds: values,
              }));
            }}
            label="Venue"
            disabled={filter.regionIds.length === 0}
            placeholder={filter.regionIds.length === 0 ? "a Region" : "Venue"}
          />
        </div>
        <div className="max-w-60 min-w-60">
          <FormFieldSelectMulti
            name="dayOfWeek"
            input={dayOfWeek}
            addAllOption={false}
            selectedItems={filter.dayOfWeek}
            setSelectedItems={(values) => {
              setFilter((prevState) => ({
                ...prevState,
                dayOfWeek: values,
              }));
            }}
            label="Day Of Week"
            placeholder="Day Of Week"
          />
        </div>
        <TooltipProvider delayDuration={300}>
          {filter.venueIds.length === 0 && filter.dayOfWeek.length === 0 ? (
            <Tooltip>
              <TooltipTrigger>
                <Button
                  variant="disabled"
                  disabled
                  className="h-fit"
                >
                  Apply
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <Body1>
                  You must select at least 1 venue or 1 day to apply the filter
                </Body1>
              </TooltipContent>
            </Tooltip>
          ) : (
            <Button
              variant="primary"
              onClick={handleApplyFilter}
              className="h-fit"
            >
              Apply
            </Button>
          )}
        </TooltipProvider>
      </div>
      {/* Table section */}
      {tableData ? (
        <>
          {tableData.length > 0 ? (
            <div>
              <DataTable
                columns={columns}
                data={tableData}
              />
              {filter.pagination && (
                <div className="flex justify-end mt-4">
                  <TablePagination
                    page={filter.pagination.page || 0}
                    pageCount={filter.pagination.pageCount || 0}
                    onChange={handleChangePagination}
                  />
                </div>
              )}
            </div>
          ) : (
            <Headline2Variable>
              No capacity groups found with this filter
            </Headline2Variable>
          )}
        </>
      ) : (
        <Headline2Variable>Please apply a filter</Headline2Variable>
      )}
    </main>
  );
};

export default CapacityGroupOverview;
