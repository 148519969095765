import { createContext, useContext, useState } from "react";
import { SessionForSessionHqQuery } from "../generated/graphql";

type SessionWorkflowTabGroupValue =
  | "Registrations"
  | "Waitlist"
  | "Incomplete Carts";

type SessionWorkflowContextType = {
  filter: {
    regionId: number;
    sportId: number;
  };
  setFilter: React.Dispatch<
    React.SetStateAction<{
      regionId: number;
      sportId: number;
    }>
  >;
  selectedSession: SessionForSessionHqQuery["session"] | undefined;
  setSelectedSession: React.Dispatch<
    React.SetStateAction<SessionForSessionHqQuery["session"] | undefined>
  >;
  tabGroupValue: SessionWorkflowTabGroupValue;
  setTabGroupValue: React.Dispatch<
    React.SetStateAction<SessionWorkflowTabGroupValue>
  >;
};

const SessionWorkflowContextDefault: SessionWorkflowContextType = {
  filter: {
    regionId: 0,
    sportId: 0,
  },
  setFilter: () => {},
  selectedSession: undefined,
  setSelectedSession: () => {},
  tabGroupValue: "Registrations",
  setTabGroupValue: () => {},
};

const SessionWorkflowContext = createContext<SessionWorkflowContextType>(
  SessionWorkflowContextDefault
);

export const SessionWorkflowContextProvider = ({ children }: any) => {
  const [filter, setFilter] = useState<{
    regionId: number;
    sportId: number;
  }>({
    regionId: 0,
    sportId: 0,
  });
  const [selectedSession, setSelectedSession] = useState<
    SessionForSessionHqQuery["session"] | undefined
  >(undefined);
  const [tabGroupValue, setTabGroupValue] =
    useState<SessionWorkflowTabGroupValue>("Registrations");

  const value = {
    filter,
    setFilter,
    selectedSession,
    setSelectedSession,
    tabGroupValue,
    setTabGroupValue,
  };

  return (
    <SessionWorkflowContext.Provider value={value}>
      {children}
    </SessionWorkflowContext.Provider>
  );
};

export const useSessionWorkflowContext = () =>
  useContext(SessionWorkflowContext);
