import { useEffect, useMemo, useRef, useState } from "react";
import {
  GetEmailTemplateInstanceQuery,
  GetEmailTemplateInstanceStatusesQuery,
  ListAllOperations,
  useCreateEmailTemplateInstanceMutation,
  useDeleteEmailTemplateInstanceMutation,
  useGetEmailTemplateInstanceLazyQuery,
  useGetEmailTemplateInstanceStatusesQuery,
  useSendTestEmailInstanceMutation,
} from "../../generated/graphql";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { displayAlertError, displayAlertSuccess } from "../../app/globalSlice";
import { Column, useTable } from "react-table";
import Body1 from "../UI/Text/Body/Body1";
import BaseTable from "../UI/Table/Table";
import dayjs from "dayjs";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../UI/shadcn/dropdown";
import { MoreVertical } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "../UI/shadcn/dialog";
import Button from "../UI/Button/Button";
import { useNavigate } from "react-router-dom";
import { SetMealOutlined } from "@mui/icons-material";
import FormFieldControlled from "../UI/FormField/FormFieldControlled";
import { client } from "../../graphql";
import { marketingFooter } from "./Email/Footers";
import { emailButton } from "./Email/EmailButton";
import { header } from "./Email/Headers";
import LoadingDialog from "../UI/Dialog/LoadingDialog";
import { useEmailToolMarketingContext } from "../../context/EmailToolMarketingContext";

const EmailMarketingReport = () => {
  /*** Utility definitions ***/
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { user }: any = useSelector((state: RootState) => state.auth);
  const { selectedReportStatus, setSelectedReportStatus } =
    useEmailToolMarketingContext();

  /*** States ***/
  const [emailTemplateInstances, setEmailTemplateInstances] = useState<
    GetEmailTemplateInstanceQuery["getEmailTemplateInstance"]
  >([]);
  const [emailTemplateInstanceStatuses, setEmailTemplateInstanceStatuses] =
    useState<
      GetEmailTemplateInstanceStatusesQuery["getEmailTemplateInstanceStatuses"]
    >([]);
  const [duplicateEmailName, setDuplicateEmailName] = useState<string>("");
  const [emailToView, setEmailToView] = useState<string>("");
  const [openViewDialog, setOpenViewDialog] = useState<boolean>(false);

  /*** References ***/
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  /*** Queries ***/
  const [
    getEmailTemplateInstances,
    { loading: loadingEmailTemplateInstances },
  ] = useGetEmailTemplateInstanceLazyQuery({
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setEmailTemplateInstances(data.getEmailTemplateInstance);
    },
  });

  const { loading: loadingEmailTemplateInstanceStatuses } =
    useGetEmailTemplateInstanceStatusesQuery({
      onCompleted: (data) => {
        setEmailTemplateInstanceStatuses(data.getEmailTemplateInstanceStatuses);
        if (selectedReportStatus === 0) {
          setSelectedReportStatus(1);
        }
      },
      onError: (error) => {
        dispatch(displayAlertError(error.message ?? "Couldn't fetch statuses"));
      },
    });

  /*** Mutations ***/
  const [
    duplicateEmailTemplateInstance,
    { loading: loadingDuplicateEmailTemplateInstance },
  ] = useCreateEmailTemplateInstanceMutation({
    onCompleted: async (data) => {
      if (data.createEmailTemplateInstance.success) {
        await getEmailTemplateInstances({
          variables: {
            emailTemplateInstanceStatusId: selectedReportStatus,
          },
        });
        dispatch(displayAlertSuccess(data.createEmailTemplateInstance.message));
      } else {
        dispatch(displayAlertError(data.createEmailTemplateInstance.message));
      }
    },
    onError: (error) => {
      dispatch(displayAlertError(error.message));
    },
  });

  const [
    deleteEmailTemplateInstance,
    { loading: loadingDeleteEmailTemplateInstance },
  ] = useDeleteEmailTemplateInstanceMutation({
    onCompleted: async (data) => {
      if (data.deleteEmailTemplateInstance.success) {
        await getEmailTemplateInstances({
          variables: {
            emailTemplateInstanceStatusId: selectedReportStatus,
          },
        });
        dispatch(displayAlertSuccess(data.deleteEmailTemplateInstance.message));
      } else
        dispatch(displayAlertError(data.deleteEmailTemplateInstance.message));
    },
    onError: (error) => {
      dispatch(displayAlertError(error.message ?? "Couldn't fetch statuses"));
    },
  });

  const [sendTestEmailInstance] = useSendTestEmailInstanceMutation({
    onCompleted: async (data) => {
      if (data.sendTestEmailInstance.success) {
        dispatch(displayAlertSuccess(data.sendTestEmailInstance.message));
      } else dispatch(displayAlertError(data.sendTestEmailInstance.message));
    },
    onError: (error) => {
      dispatch(displayAlertError(error.message ?? "Couldn't send test email"));
    },
  });

  /*** Use Effects ***/
  useEffect(() => {
    getEmailTemplateInstances({
      variables: {
        emailTemplateInstanceStatusId: selectedReportStatus,
      },
    });
  }, [selectedReportStatus]);

  useEffect(() => {
    if (iframeRef.current && emailToView !== undefined && emailToView !== "") {
      const formattedBody = `${header}${emailToView.replace(
        /%%button_(https:\/\/[a-zA-Z0-9._%+-\/]+)_([a-zA-Z0-9\s._%+-]+)%%/g,
        (_, link, text) => {
          return emailButton(link, text);
        }
      )}${marketingFooter({
        url: "https://jamsports.com/unsubscribe/test",
        email: "temp@jamgroup.com",
      })}`;
      const blob = new Blob([formattedBody], { type: "text/html" });
      iframeRef.current.src = URL.createObjectURL(blob);
    }
  }, [emailToView]);

  /*** Utility functions ***/
  function hiddenColumns(): string[] {
    if (selectedReportStatus === 1) {
      return ["dateSent", "dateToBeSent"];
    } else if (selectedReportStatus === 2) {
      return ["dateSent"];
    } else if (selectedReportStatus === 3) {
      return [];
    } else {
      return [];
    }
  }

  /*** Table definitions ***/
  const EMAIL_TEMPLATE_INSTANCE_COLUMNS: Column<
    GetEmailTemplateInstanceQuery["getEmailTemplateInstance"][0]
  >[] = [
    {
      Header: "Id",
      id: "id",
      accessor: (d) => {
        return <Body1>{d.id}</Body1>;
      },
    },
    {
      Header: "Name",
      id: "name",
      accessor: (d) => {
        return <Body1>{d.name}</Body1>;
      },
    },
    {
      Header: "Subject",
      id: "subject",
      accessor: (d) => {
        return <Body1>{d.subject}</Body1>;
      },
    },
    {
      Header: "Date To Be Sent",
      id: "dateToBeSent",
      accessor: (d) => {
        if (!d.emailBatch) {
          return <Body1>Not Scheduled</Body1>;
        }
        return (
          <Body1>
            {dayjs(d.emailBatch.timeToSend).format("YYYY-MM-DD h:mm A")}
          </Body1>
        );
      },
    },
    {
      Header: "Date Sent",
      id: "dateSent",
      accessor: (d) => {
        if (!d.emailBatch) {
          return <Body1>Not Sent</Body1>;
        }
        return (
          <Body1>
            {dayjs(d.emailBatch.timeToSend).format("YYYY-MM-DD h:mm A")}
          </Body1>
        );
      },
    },
    {
      Header: "Recipient Count",
      id: "recipeintCount",
      accessor: (d) => {
        return (
          <Body1>
            {d.emailMarketingFilter
              ? d.emailMarketingFilter.recipientCount
              : "Filter not set"}
          </Body1>
        );
      },
    },
    {
      Header: "Actions",
      id: "actions",
      accessor: (d) => {
        return (
          <div>
            <DropdownMenu>
              <DropdownMenuTrigger className="flex items-center justify-center p-2 transition-colors rounded-full hover:bg-gray-200 focus:outline-none ">
                <MoreVertical className="w-5 h-5 text-gray-600" />
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem
                  className="cursor-pointer hover:bg-gray-100"
                  onClick={(e) => {
                    setOpenViewDialog(true);
                    setTimeout(() => {
                      setEmailToView(d.body);
                    }, 500);
                  }}
                >
                  View
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="cursor-pointer hover:bg-gray-100"
                  onClick={async () => {
                    await sendTestEmailInstance({
                      variables: {
                        instanceId: d.id,
                      },
                    });
                  }}
                >
                  Send to Self
                </DropdownMenuItem>
                <a href={`/marketing/email-tool/marketing/${d.id}`}>
                  <DropdownMenuItem
                    className="cursor-pointer hover:bg-gray-100"
                    disabled={d.emailTemplateInstanceStatusId !== 1}
                  >
                    Edit
                  </DropdownMenuItem>
                </a>
                <DropdownMenuItem
                  className="hover:bg-gray-100"
                  onSelect={(e) => e.preventDefault()}
                >
                  <Dialog>
                    <DialogTrigger className="w-full h-full text-start">
                      Duplicate
                    </DialogTrigger>
                    <DialogContent>
                      <DialogHeader>Duplicate {d.name}</DialogHeader>
                      <DialogDescription>
                        <div className="flex flex-col gap-4">
                          <Body1>
                            What do you want to name the new email template
                            instance?
                          </Body1>
                          <FormFieldControlled
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setDuplicateEmailName(event.target.value);
                            }}
                            value={duplicateEmailName}
                            label="Duplicate Email Name"
                          />
                        </div>
                      </DialogDescription>
                      <DialogFooter>
                        <DialogClose>
                          <Button
                            variant="primary"
                            onClick={async () => {
                              await duplicateEmailTemplateInstance({
                                variables: {
                                  createEmailTemplateInstanceInput: {
                                    name: duplicateEmailName,
                                    body: d.body,
                                    subject: d.subject,
                                    emailTemplateInstanceStatusId: 1,
                                    updatedAt: new Date(),
                                    updatedBy: user.id,
                                  },
                                },
                              });
                            }}
                          >
                            Duplicate
                          </Button>
                        </DialogClose>
                        <DialogClose>
                          <Button variant="secondary">Close</Button>
                        </DialogClose>
                      </DialogFooter>
                    </DialogContent>
                  </Dialog>
                </DropdownMenuItem>
                <a href={`/marketing/email-tool/marketing/filter/${d.id}`}>
                  <DropdownMenuItem
                    className="cursor-pointer hover:bg-gray-100"
                    disabled={d.emailTemplateInstanceStatusId !== 1}
                  >
                    Define Recipients
                  </DropdownMenuItem>
                </a>
                <a href={`/marketing/email-tool/marketing/send/${d.id}`}>
                  <DropdownMenuItem
                    className="cursor-pointer hover:bg-gray-100"
                    disabled={
                      d.emailTemplateInstanceStatusId === 3 ||
                      d.emailMarketingFilter === null
                    }
                  >
                    {d.emailTemplateInstanceStatusId === 2 && "Adjust"} Send
                  </DropdownMenuItem>
                </a>
                <DropdownMenuItem
                  className={`hover:bg-gray-100`}
                  disabled={d.emailTemplateInstanceStatusId === 3}
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Dialog>
                    <DialogTrigger className="w-full h-full text-start">
                      Delete
                    </DialogTrigger>
                    <DialogContent>
                      <DialogHeader>Confirm Deletion</DialogHeader>
                      <DialogDescription>
                        Are you sure you want to delete this email instance?
                      </DialogDescription>
                      <DialogFooter>
                        <DialogClose>
                          <Button
                            variant="negative"
                            onClick={async () => {
                              await deleteEmailTemplateInstance({
                                variables: {
                                  id: d.id,
                                },
                              });
                            }}
                          >
                            Delete
                          </Button>
                        </DialogClose>
                        <DialogClose>
                          <Button variant="secondary">Close</Button>
                        </DialogClose>
                      </DialogFooter>
                    </DialogContent>
                  </Dialog>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
  ];

  const emailTemplateInstanceTableData = useMemo(() => {
    return emailTemplateInstances;
  }, [emailTemplateInstances]);

  /*** Rendered content ***/
  return (
    <main className="flex flex-col gap-4 pt-4">
      {(loadingEmailTemplateInstances ||
        loadingDuplicateEmailTemplateInstance ||
        loadingEmailTemplateInstanceStatuses ||
        loadingDeleteEmailTemplateInstance) && <LoadingDialog open={true} />}
      <Headline1Variable>Email Marketing Report</Headline1Variable>
      <div className="flex flex-row gap-4">
        <div className="flex flex-row w-40 max-w-40 h-[43px]">
          <FormFieldSelect
            value={selectedReportStatus.toString()}
            inputChange={(value: string) => {
              setSelectedReportStatus(+value);
            }}
            placeholder="Select a status"
            className="h-fit"
          >
            {[
              { id: 0, name: "Select a Status" },
              ...emailTemplateInstanceStatuses,
            ]}
          </FormFieldSelect>
        </div>
        <Button
          variant="secondary"
          href={`/marketing/email-tool/marketing`}
        >
          Create New Email
        </Button>
      </div>

      <div>
        <BaseTable
          columns={EMAIL_TEMPLATE_INSTANCE_COLUMNS}
          data={emailTemplateInstanceTableData}
          hiddenColumns={hiddenColumns()}
        />
      </div>
      <Dialog
        open={openViewDialog}
        onOpenChange={(open) => {
          setOpenViewDialog(open);
          if (!open) setEmailToView("");
        }}
      >
        <DialogContent className="max-w-[800px] w-[800px] max-h-[80vh] overflow-y-auto">
          <div className="flex items-center justify-center">
            <div className="max-w-[600px] min-w-[600px]">
              <iframe
                ref={iframeRef}
                title="Email Preview"
                style={{
                  width: "100%",
                  height: "600px",
                  border: "none",
                }}
              />
            </div>
          </div>
          <DialogFooter>
            <DialogClose>
              <Button variant="secondary">Close</Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </main>
  );
};

export default EmailMarketingReport;
