import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function getPageTitle(pathname: string) {
  // List of prefixes to remove
  const prefixesToRemove = [
    "/ops",
    "/players",
    "/staffing",
    "/marketing",
    "/finance",
    "/dev",
    "/",
  ];

  // Remove the prefix
  let title = pathname;
  for (let prefix of prefixesToRemove) {
    if (title.startsWith(prefix + "/")) {
      title = title.substring(prefix.length);
      break;
    } else if (title === prefix) {
      title = "";
      break;
    }
  }

  // Remove leading/trailing slashes
  title = title.replace(/^\/|\/$/g, "");

  // Split by '/'
  let parts = title.split("/");

  // Remove parameters like ':id' or ':id?'
  parts = parts.filter((part) => !part.startsWith(":") && part.length > 0);

  // Replace dashes with spaces
  parts = parts.map((part) => part.replace(/-/g, " "));

  // Capitalize words
  parts = parts.map((part) =>
    part
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
  );

  // Join parts into a string
  title = parts.join(" - ");

  // If title is empty, set it to 'Home'
  if (!title) {
    title = "Home";
  }

  return title;
}

type PageTitleContextType = {};

const PageTitleContextDefault: PageTitleContextType = {};

const PageTitleContext = createContext<PageTitleContextType>(
  PageTitleContextDefault
);

export const PageTitleContextProvider = ({ children }: any) => {
  const location = useLocation();

  useEffect(() => {
    document.title = getPageTitle(location.pathname);
  }, [location.pathname]);

  const value = {};

  return (
    <PageTitleContext.Provider value={value}>
      {children}
    </PageTitleContext.Provider>
  );
};

export const usePageTitleContext = () => useContext(PageTitleContext);
