import React, { useEffect, useMemo, useState } from "react";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import Button from "../../UI/Button/Button";
import { PayrollBatch, Shift } from "@/src/types/types";
import { DataTable } from "../../UI/Table/DataTable";
import { ColumnDef, RowSelectionState } from "@tanstack/react-table";
import { Checkbox } from "../../UI/shadcn/checkbox";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/src/app/store";
import LoadingDialog from "../../UI/Dialog/LoadingDialog";
import { Box, Modal } from "@mui/material";
import Headline2Variable from "../../UI/Text/Headline/Headline2Variable";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import {
  displayAlertError,
  displayAlertSuccess,
} from "../../../app/globalSlice";
import { numberToMoney } from "../../../utils/financialHelpers";
import {
  ListAllOperations,
  PayrollBatchesUnpaidQuery,
  ShiftsBeforeCutoffDateQuery,
  ShiftsMarkedAsPaidMutation,
  ShiftsSummaryQuery,
  usePayrollBatchesUnpaidLazyQuery,
  useShiftPaymentStatusesLazyQuery,
  useShiftsBeforeCutoffDateLazyQuery,
  useShiftsMarkedAsPaidMutation,
  useShiftsSummaryLazyQuery,
} from "../../../generated/graphql";
import { FormFieldSelectMulti } from "../../UI/FormField/FormFieldSelectMulti/FormFieldSelectMulti";

const ShiftsForPayroll = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { isLoading } = useSelector((state: RootState) => state.shift);

  const { selectedRegions }: any = useSelector(
    (state: RootState) => state.venueMaster
  );

  const [shiftsBeforeCutoffDate, setShiftsBeforeCutoffDate] =
    useState<ShiftsBeforeCutoffDateQuery["shiftsBeforeCutoffDate"]["shifts"]>();
  const [selectedShifts, setSelectedShifts] = useState<number[]>([]);
  const [shiftFilters, setShiftFilters] = useState<{
    payrollBatchId: number;
    shiftCutoffDate: string;
    shiftPaymentStatusId: number;
    regionIds: number[];
    page: number;
  }>({
    payrollBatchId: 0,
    shiftCutoffDate: "",
    shiftPaymentStatusId: 3,
    regionIds: [],
    page: 0,
  });
  const [payrollBatches, setPayrollBatches] = useState<
    PayrollBatchesUnpaidQuery["payrollBatchesUnpaid"]
  >([]);
  const [confirmPayrollShiftsModal, setConfirmPayrollShiftsModal] =
    useState<boolean>(false);
  const [selectedShiftsSummary, setSelectedShiftsSummary] = useState<
    ShiftsSummaryQuery["shiftsSummary"]["shiftSummary"]
  >({
    numOfShifts: 0,
    totalAmount: 0,
    totalHours: 0,
  });

  const pageSize = 50;

  const [getShiftsBeforeCutoffDate, { data: shiftsData, loading, error }] =
    useShiftsBeforeCutoffDateLazyQuery({
      variables: {
        date: shiftFilters?.shiftCutoffDate || "",
        shiftPaymentStatusId: 3,
        regionIds: shiftFilters.regionIds,
        page: shiftFilters.page,
        pageSize: pageSize,
      },
      fetchPolicy: "network-only",
    });
  const [
    getUnpaidPayrollBatches,
    { data: payrollBatchData, loading: loadingPayroll, error: errorPayroll },
  ] = usePayrollBatchesUnpaidLazyQuery({
    fetchPolicy: "network-only",
  });

  const [shiftsMarkedAsPaid, { loading: shiftsMarkedAsPaidLoading }] =
    useShiftsMarkedAsPaidMutation({
      refetchQueries: [ListAllOperations.Query.ShiftsBeforeCutoffDate],
    });

  const [
    getShiftsSummary,
    {
      data: shiftsSummaryData,
      loading: shiftsSummaryLoading,
      error: shiftsSummaryError,
    },
  ] = useShiftsSummaryLazyQuery({
    variables: {
      shiftsId: selectedShifts,
    },
    fetchPolicy: "network-only",
  });

  async function handleShiftsAsPaid() {
    await shiftsMarkedAsPaid({
      variables: {
        shiftsId: selectedShifts,
        payrollBatchId: shiftFilters.payrollBatchId,
      },
      onCompleted: (data) => {
        if (data.shiftsMarkedAsPaid.success) {
          dispatch(displayAlertSuccess("All shifts are marked as paid"));
        } else if (data.shiftsMarkedAsPaid.message) {
          dispatch(displayAlertSuccess(data.shiftsMarkedAsPaid.message));
        } else {
          dispatch(displayAlertError("Something went wrong"));
        }
      },
      onError: (err) => {
        dispatch(
          displayAlertError(
            err.message ? `Error: ${err.message}` : "Something went wrong"
          )
        );
      },
    });
  }

  const handleRunPayrollAction = () => {
    getShiftsSummary({
      onCompleted: (data) => {
        if (data.shiftsSummary.success) {
          setSelectedShiftsSummary(data.shiftsSummary.shiftSummary);
          setConfirmPayrollShiftsModal(true);
        } else {
          dispatch(displayAlertError("Something went wrong"));
        }
      },
      onError: (err) => {
        displayAlertError(
          err.message ? `Error: ${err.message}` : "Something went wrong"
        );
      },
    }).then((data) => {
      console.log(data);
    });
  };

  useEffect(() => {
    getUnpaidPayrollBatches({
      onCompleted: (data) => {
        setPayrollBatches(data.payrollBatchesUnpaid);
      },
    });
  }, []);

  useEffect(() => {
    if (shiftFilters.payrollBatchId === 0) {
      return;
    }
    getShiftsBeforeCutoffDate({
      onCompleted: (data) => {
        if (shiftFilters.page === 0)
          setShiftsBeforeCutoffDate(data.shiftsBeforeCutoffDate.shifts);
        else
          setShiftsBeforeCutoffDate((prevState) => [
            ...(prevState || []),
            ...data.shiftsBeforeCutoffDate.shifts,
          ]);
      },
    });
    return () => {
      setSelectedShifts([]);
    };
  }, [shiftFilters]);

  const handlePageChange = () => {
    setShiftFilters((prevState) => ({
      ...prevState,
      page: (shiftFilters.page || 0) + 1,
    }));
  };

  const paginationButton = () => {
    if (!shiftsData) return false;
    else if (
      shiftsData.shiftsBeforeCutoffDate.count >= pageSize &&
      ((shiftFilters.page || 0) + 1) * pageSize <
        shiftsData.shiftsBeforeCutoffDate.count
    ) {
      return true;
    }
  };

  const sortedShifts = useMemo(() => {
    if (!shiftsBeforeCutoffDate) return null;
    return shiftsBeforeCutoffDate.slice().sort((a, b) => {
      // Compare region name
      const regionA = a.region.name.toLowerCase();
      const regionB = b.region.name.toLowerCase();
      if (regionA < regionB) return -1;
      if (regionA > regionB) return 1;

      // If region names are equal, compare user's first name
      const firstNameA = (a.user?.firstName || "").toLowerCase();
      const firstNameB = (b.user?.firstName || "").toLowerCase();
      if (firstNameA < firstNameB) return -1;
      if (firstNameA > firstNameB) return 1;

      // If user's first names are equal, compare start date time
      const startA = new Date(a.startLocal).getTime();
      const startB = new Date(b.startLocal).getTime();
      return startA - startB;
    });
  }, [shiftsBeforeCutoffDate]);

  const columnShifts: ColumnDef<
    ShiftsBeforeCutoffDateQuery["shiftsBeforeCutoffDate"]["shifts"][0]
  >[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            table.getIsSomePageRowsSelected()
          }
          onCheckedChange={(value) => {
            setSelectedShifts(
              value ? shiftsBeforeCutoffDate?.map((row) => +row.id) ?? [] : []
            );

            table.setRowSelection((current) => {
              const newSelection: RowSelectionState = {};
              for (let i = 0; i < table.getRowCount(); i++) {
                const row = table.getRow(i.toString());
                newSelection[i] = !!value;
              }
              return newSelection;
            });
          }}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => {
        return (
          <>
            <Checkbox
              checked={row.getIsSelected()}
              onCheckedChange={(value) => {
                if (value) {
                  setSelectedShifts((shifts) => [...shifts, +row.original.id]);
                } else {
                  setSelectedShifts((prevSelectedShifts) =>
                    prevSelectedShifts?.filter((id) => +id !== +row.original.id)
                  );
                }
                row.toggleSelected(!!value);
              }}
              aria-label="Select row"
            />
          </>
        );
      },
    },
    {
      accessorKey: "id",
      header: "ID",
    },
    {
      accessorFn: (row) => {
        return `${dayjs.utc(row.startLocal).format("YYYY-MM-DD")}`;
      },
      header: "Date",
    },
    // day
    {
      header: "Day",
      accessorFn: (row) => {
        return `${dayjs.utc(row.startLocal).format("dddd")}`;
      },
    },
    {
      accessorFn: (row) => {
        return `${dayjs.utc(row.startLocal).format("h:mma")} - ${dayjs
          .utc(row.endLocal)
          .format("h:mma")}`;
      },
      header: "Time",
    },
    // Region
    {
      header: "Region",
      accessorFn: (row) => {
        return `${row.region?.name}`;
      },
    },
    {
      header: "Location",
      accessorFn: (row) => {
        return `${row.venue?.name}`;
      },
    },
    // {f and l name}
    {
      header: "Staff Name",
      accessorFn: (row) => {
        return `${row.user?.firstName} ${row.user?.lastName}`;
      },
    },
    // staff type
    {
      header: "Staff Type",
      accessorFn: (row) => {
        return `${row.staffType?.name}`;
      },
    },
    // shiftStatus
    {
      header: "Shift Status",
      accessorFn: (row) => {
        return `${row.shiftStatus?.value}`;
      },
    },
    // shiftReportStatus
    {
      header: "Shift Report Status",
      accessorFn: (row) => {
        return `${
          row.shiftReport
            ? row.shiftReport.shiftReportStatusId
              ? "Reported"
              : "Not Reported"
            : "Not Reported"
        }`;
      },
    },
    // Pay rate
    {
      header: "Rate",
      accessorFn: (row) => {
        return `${row.rate ? numberToMoney(row.rate) : "Missing Rate"}`;
      },
    },
    // hours
    {
      header: "Hours",
      accessorFn: (row) => {
        return `${row.hours?.toFixed(2)}`;
      },
    },
    // amount
    {
      header: "Amount",
      accessorFn: (row) => {
        return `${row.amount ? numberToMoney(row.amount) : "Missing Amount"}`;
      },
    },
    // Payroll Status
    {
      header: "Payroll Status",
      accessorFn: (row) => {
        return `${
          row.payrollBatch?.payrollBatchStatus?.name
            ? row.payrollBatch?.payrollBatchStatus?.name
            : "Not Paid"
        }`;
      },
    },
  ];

  if (loading || isLoading || loadingPayroll || shiftsMarkedAsPaidLoading)
    return (
      <LoadingDialog
        open={
          loading || isLoading || loadingPayroll || shiftsMarkedAsPaidLoading
        }
      />
    );
  if (error) return <div>Something went wrong</div>;

  return (
    <main>
      <Modal
        open={confirmPayrollShiftsModal}
        onClose={() => setConfirmPayrollShiftsModal(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          {selectedShiftsSummary ? (
            <div className="flex flex-col gap-6">
              <Headline2Variable>
                Confirm Payroll Batch Details
              </Headline2Variable>
              <p>
                Please double check the details below. Once you submit this
                payroll batch you will not be able to add more shifts later.
              </p>
              <p>
                <b>Total Shifts: {selectedShiftsSummary.numOfShifts} </b>
                <br />
                <b>
                  Total Hours: {selectedShiftsSummary.totalHours.toFixed(2)}{" "}
                </b>
                <br />
                <b>
                  Total Payroll Amount:{" "}
                  {selectedShiftsSummary.totalAmount.toFixed(2)}{" "}
                </b>
                <br />
              </p>
              <p>
                Once you run this payroll batch the system will: <br />
                1. Set the selected shifts as paid <br />
                2. Set the payroll batch record as paid <br />
                3. Link the shifts to the payroll batch record <br />
                4. Allow you to export the shifts to .csv <br />
              </p>
              <div className="flex flex-row gap-4">
                <Button
                  variant="primary"
                  onClick={() => {
                    handleShiftsAsPaid();
                    setConfirmPayrollShiftsModal(false);
                  }}
                >
                  Confirm and Run Payroll
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setConfirmPayrollShiftsModal(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          ) : (
            <Headline2Variable>Could not find shift summary</Headline2Variable>
          )}
        </Box>
      </Modal>

      <Headline1Variable>Payroll Finance</Headline1Variable>
      <div className="flex flex-row flex-wrap items-end gap-4 mb-4">
        <div className="w-64">
          <FormFieldSelect
            name="payrollBatch"
            label="Payroll Batch"
            placeholder="Payroll Batch"
            value={shiftFilters.payrollBatchId.toString()}
            inputChange={(value: string) => {
              const selectedPayrollBatch = payrollBatches.find(
                (payrollBatch) => {
                  return payrollBatch.id == +value;
                }
              );
              const cutoffDate = selectedPayrollBatch?.shiftCutoffDate;

              setShiftFilters((prevState) => ({
                ...prevState,
                payrollBatchId: +value,
                shiftCutoffDate: cutoffDate?.toString() ?? "",
                page: 0,
              }));
            }}
          >
            {[{ id: 0, name: "Select Payroll Batch" }, ...payrollBatches]}
          </FormFieldSelect>
        </div>
        <div className="w-64">
          <FormFieldSelectMulti
            name="regionIds"
            input={
              selectedRegions.map((region: any) => {
                return { id: region.id, name: region.name };
              }) ?? []
            }
            addAllOption={true}
            selectedItems={shiftFilters.regionIds}
            setSelectedItems={(values) => {
              setShiftFilters((prevState) => ({
                ...prevState,
                regionIds: values,
              }));
            }}
            label="Region"
            placeholder="Region"
          />
        </div>
      </div>
      <div className="flex flex-row items-end w-full gap-4 mb-2">
        <Button
          className="w-[280px] h-fit"
          // variant="primary"
          variant={
            !selectedShifts.length || shiftFilters.payrollBatchId === 0
              ? "disabled"
              : "primary"
          }
          disabled={!selectedShifts.length || shiftFilters.payrollBatchId === 0}
          onClick={handleRunPayrollAction}
        >
          Run Payroll
        </Button>
      </div>
      <div>
        {sortedShifts && (
          <DataTable
            data={sortedShifts}
            columns={columnShifts}
          />
        )}
      </div>
      {paginationButton() && (
        <div className="pb-10 mt-3">
          <Button
            variant="primary"
            onClick={() => handlePageChange()}
          >
            Load 50 More
          </Button>
        </div>
      )}
    </main>
  );
};

export default ShiftsForPayroll;
