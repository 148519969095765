import {
  Box,
  Chip,
  IconButton,
  Modal,
  Button as MuiButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { LoadingMaterialUI } from "../UI";
import classes from "./CreateDivision.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../app/store";
import {
  createDivision,
  updateDivision,
  getGenderFormat,
  getRegistrationBatch,
  getSports,
  DayOfWeek,
  InitialStateVenueMaster,
} from "../../app/venueMasterSlice";
import { displayAlertError } from "../../app/globalSlice";
import moment from "moment";
import MultipleDatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import BreadCrumbs from "../UI/Breadcrumbs/Breadcrumbs";
import Card from "../UI/Card/Card";
import Subtitle1 from "../UI/Text/Subtitle/Subtitle1";
import FormField from "../UI/FormField/FormField";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import Button from "../UI/Button/Button";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  DivisionByScheduleIdQuery,
  SessionsForDivisionQuery,
  useDivisionByIdLazyQuery,
  useGetTeamsRatingsBySessionLazyQuery,
  useSessionsForDivisionLazyQuery,
} from "../../generated/graphql";
import LoadingDialog from "../UI/Dialog/LoadingDialog";
import Body1 from "../UI/Text/Body/Body1";
import Disclaimer from "../UI/Alerts/Disclaimer";
import { Separator } from "../UI/shadcn/separator";
import { QuestionMark } from "@mui/icons-material";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import FormFieldViewOnly from "../UI/FormField/FormFieldViewOnly/FormFieldViewOnly";
import FormFieldDate from "../UI/FormField/FormFieldDate/FormFieldDate";
import dayjs from "dayjs";
import Alert from "../UI/Alerts/Alert";

type Session = {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
  numberOfWeeks: number;
  weeksOfPlayoffs: number;
  exceptionDates: (string | null)[];
  teams: SessionsForDivisionQuery["sessions"][0]["teams"];
  divisions: DivisionByScheduleIdQuery["division"]["session"]["divisions"];
};

type Division = {
  id: number;
  name: string;
  vmName: string;
  vmColor: string;
  sessionId: number;
  teams: number[];
  skillChoiceId: number | null;
};

type SessionFilter = {
  regionId: number | null;
  sportId: number | null;
  dayOfWeek: number | null;
  registrationBatchId: number | null;
  genderFormatId: number | null;
};

const CreateDivision = () => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const initialSession = {
    id: 0,
    name: "",
    startDate: "",
    endDate: "",
    exceptionDates: [],
    numberOfWeeks: 0,
    weeksOfPlayoffs: 0,
    teams: [],
    divisions: [],
  };

  const initialDivision = {
    id: 0,
    name: "",
    vmName: "",
    vmColor: "",
    sessionId: 0,
    teams: [],
    skillChoiceId: null,
  };

  const {
    isLoading,
    divisionToEdit,
    selectedRegions,
    genderFormat,
    sports,
    registrationBatches,
  }: InitialStateVenueMaster = useSelector(
    (state: RootState) => state.venueMaster
  );

  const weekdaysArray: any = [
    { id: 0, name: "Sunday" },
    { id: 1, name: "Monday" },
    { id: 2, name: "Tuesday" },
    { id: 3, name: "Wednesday" },
    { id: 4, name: "Thursday" },
    { id: 5, name: "Friday" },
    { id: 6, name: "Saturday" },
    { id: 7, name: "All" },
  ];

  const params = useParams();
  const isEditDivision = params.id;
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [division, setDivision] = useState<Division>(initialDivision);
  const [isSchedulePublished, setIsSchedulePublished] = useState(false);
  const [session, setSession] = useState<Session>(initialSession);
  const [sessionFilter, setSessionFilter] = useState<SessionFilter>(() => {
    const urlParamRegBatchId = searchParams.get("regBatchId");
    return {
      regionId: null,
      sportId: null,
      dayOfWeek: 7,
      registrationBatchId: urlParamRegBatchId ? +urlParamRegBatchId : null,
      genderFormatId: null,
    };
  });
  const [selectSessionModal, setSelectSessionModal] = useState(0);
  const [regSkillChoiceList, setRegSkillChoiceList] = useState<
    {
      id: number;
      name: string;
    }[]
  >([]);

  const [sessionsForDivision, setSessionsForDivision] = useState<Session[]>([]);
  const [openInfoModal, setOpenInfoModal] = useState(false);

  const [
    divisionById,
    { data: divisionByIdData, error: divisionError, loading: divisionLoading },
  ] = useDivisionByIdLazyQuery({});

  const [getSessionRatings, { data: sessionRatings, loading: loadingRatings }] =
    useGetTeamsRatingsBySessionLazyQuery({
      fetchPolicy: "network-only",
    });

  const [
    getSessionsForDivision,
    { data: dataSessionsForDivision, loading: loadingSessionsForFilter },
  ] = useSessionsForDivisionLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.sessions) {
        const sessions = data.sessions.map((session) => ({
          id: session.id,
          name: `${DayOfWeek[session.dayOfWeek]} ${
            session.league.name
          } (${moment(session.startDate).format("MMM DD, YYYY")})`,
          startDate: session.startDate,
          endDate: session.endDate,
          numberOfWeeks: session.numberOfWeeks,
          weeksOfPlayoffs: session.weeksOfPlayoffs,
          exceptionDates: session.exceptionDates,
          teams: session.teams,
          divisions: session.divisions,
        }));
        setSessionsForDivision(sessions);
      }
    },
  });

  useEffect(() => {
    if (isEditDivision) {
      divisionById({
        variables: { divisionId: +isEditDivision },
        onCompleted: (data) => {
          if (!(Object.keys(data).length === 0) && isEditDivision) {
            const teams = data.division.teams?.map((team) => team.id);
            const regSkilsChoices =
              data.division.session.regSkillChoices.map((skill) => ({
                id: skill.id,
                name: skill.name,
              })) || [];
            setIsSchedulePublished(
              data.division.latestSchedule?.scheduleStatus.id === 2
            );
            setDivision({
              id: data.division.id,
              name: data.division.name,
              vmName: data.division.vmName,
              vmColor: data.division.vmColor,
              sessionId: data.division.session.id,
              teams: teams,
              skillChoiceId: data.division.skillChoiceId || null,
            });
            setSession({
              id: data.division.session.id,
              name: data.division.session.league.name,
              startDate: moment(data.division.startDate).format("YYYY-MM-DD"),
              endDate: moment(data.division.endDate).format("YYYY-MM-DD"),
              exceptionDates: data.division.exceptionDates || [],
              numberOfWeeks: data.division.numberOfWeeks,
              weeksOfPlayoffs: data.division.weeksOfPlayoffs,
              teams: data.division.session.teams,
              divisions: data.division.session.divisions,
            });
            setRegSkillChoiceList(regSkilsChoices);
            getSessionRatings({
              variables: {
                sessionId: data.division.session.id,
              },
            });
          }
          return data.division;
        },
      });
    } else {
      setSelectSessionModal(0);
      dispatch(getGenderFormat(""));
      dispatch(getSports(""));
      dispatch(getRegistrationBatch(""));
    }
  }, []);

  useEffect(() => {}, [divisionToEdit, isEditDivision]);

  const generateRandomColor = () => {
    // Generate a random hex color
    const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
    setDivision({ ...division, vmColor: randomColor });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDivision({
      ...division,
      [e.target.name]: +e.target.value || e.target.value,
    });
  };

  useEffect(() => {
    const { regionId, ...otherFilters } = sessionFilter;
    getSessionsForDivision({
      variables: {
        sessionFilters: {
          ...otherFilters,
          regions: regionId === 0 || regionId == null ? null : [regionId],
        },
      },
    });
  }, [sessionFilter]);

  useEffect(() => {
    const urlParamSessionId = searchParams.get("sessionId");
    if (
      urlParamSessionId !== null &&
      session.id == 0 &&
      sessionsForDivision.length > 0
    ) {
      handleChangeSession(urlParamSessionId);
    }
  }, [sessionsForDivision]);

  const handleChangeSession = async (selectedValue: string) => {
    if (+selectedValue === 0) {
      return;
    }
    const sessionId = +selectedValue;
    const session = sessionsForDivision?.find((session) => {
      return +session.id === sessionId;
    });

    setDivision({
      ...division,
      sessionId,
    });
    if (session) {
      setSession({
        ...session,
        startDate: moment(session.startDate).format("YYYY-MM-DD"),
        endDate: moment(session.endDate).format("YYYY-MM-DD"),
      });

      await getSessionRatings({
        variables: {
          sessionId: sessionId,
        },
      });
    }
  };

  const handleTeamChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    let updatedList: number[] = [];
    if (division.teams) {
      updatedList = [...division.teams];
    }
    const teamId = +e.target.value;
    if (updatedList.includes(teamId)) {
      updatedList = updatedList.filter((item) => item !== teamId);
    } else {
      updatedList = [...updatedList, teamId];
    }
    setDivision({ ...division, teams: updatedList });
  };

  const handleSubmitForm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (
      !division?.sessionId ||
      !division?.name ||
      !division?.vmName ||
      !division?.vmColor
    ) {
      dispatch(displayAlertError("Please enter all the fields"));
      return;
    }

    const divisionArgs = {
      name: division.name,
      vmName: division.vmName,
      vmColor: division.vmColor,
      teams: division.teams,
      sessionId: +session.id,
      startDate: session.startDate,
      endDate: session.endDate,
      exceptionDates: session.exceptionDates,
      numberOfWeeks: session.numberOfWeeks,
      weeksOfPlayoffs: session.weeksOfPlayoffs,
      skillChoiceId: division.skillChoiceId,
    };
    if (!isEditDivision) {
      dispatch(createDivision(divisionArgs)).then((res) => {
        if (res.payload.divisionCreate.success) {
          navigate(`/ops/divisions/`);
        }
      });
    } else {
      dispatch(
        updateDivision({
          id: +isEditDivision,
          divisionInput: divisionArgs,
        })
      ).then((res) => {
        if (res.payload.divisionUpdate.success) {
          navigate(`/ops/divisions/`);
        }
      });
    }
  };

  const unpooledTeams = (): React.ReactNode => {
    const teams = session.teams;
    let pooledTeams: Session["teams"] = [];
    session.divisions.forEach((division) => {
      pooledTeams = [...pooledTeams, ...division.teams];
    });

    if (teams.length === 0) {
      return (
        <div className="w-1/4 h-full p-4 m-2 text-black bg-neutral-50">
          <p>Teams are yet to be created</p>{" "}
        </div>
      );
    }

    if (teams.length === pooledTeams.length) {
      return (
        <div className="w-1/4 h-full p-4 m-2 text-black bg-neutral-50">
          <p>All teams are pooled</p>{" "}
        </div>
      );
    }

    const unpooledTeams = teams
      .filter(
        (team) => !pooledTeams.some((pooledTeam) => pooledTeam.id === team.id)
      )
      .map((team) => {
        const teamRating = sessionRatings?.getTeamsRatingsForSession.find(
          (rating) => rating?.teamId === team.id
        );

        // Create a new object with the original team properties and add rating/confidence
        return {
          ...team, // Copy existing properties of the team
          rating: teamRating ? teamRating.rating : 0,
          confidence: teamRating ? teamRating.confidence : 0,
          opFlag: teamRating?.opFlag,
          ncFlag: teamRating?.ncFlag,
        };
      })
      .sort((a, b) => (a.rating && b.rating && a.rating < b.rating ? 1 : -1));

    if (unpooledTeams.length > 0) {
      return (
        <div className="w-1/4 h-full p-4 m-2 text-black bg-neutral-50 ">
          <p>Unpooled Teams</p>
          {unpooledTeams.map((team) => {
            const skill = team.registration
              ? team.registration.regQuestionChoice?.regSkillChoice?.name.substring(
                  0,
                  3
                )
              : null;
            return (
              <div key={team.id}>
                <div className="flex flex-row items-center gap-2 my-1">
                  {!isSchedulePublished && (
                    <input
                      type="checkbox"
                      value={team.id}
                      onChange={handleTeamChecked}
                    />
                  )}
                  <div className="flex items-center gap-2">
                    <span>{team.name}</span>
                    {skill && (
                      <Chip
                        size="small"
                        label={skill}
                        variant="outlined"
                      />
                    )}
                    <Chip
                      size="small"
                      label={`${team.rating}R - ${team.confidence}C${
                        team.opFlag ? "+" : team.ncFlag ? "*" : ""
                      }`}
                      variant="outlined"
                    />
                  </div>
                </div>
                <Separator />
              </div>
            );
          })}
        </div>
      );
    } else {
      return <p>All teams are pooled</p>;
    }
  };

  const pathsBreadcrumbs = [{ name: "Divisions", url: "/ops/divisions" }];
  if (divisionLoading) {
    return <LoadingDialog open={true} />;
  }

  return (
    <main>
      <Modal
        open={selectSessionModal ? true : false}
        onClose={() => {
          setSelectSessionModal(0);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          zIndex: 10,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div>
            <Headline1Variable>Select Session</Headline1Variable>
            <div className="mt-5">
              <Subtitle1>Session Filters</Subtitle1>
              <div className="flex flex-col gap-2">
                <FormFieldSelect
                  value={
                    sessionFilter.regionId
                      ? sessionFilter.regionId.toString()
                      : "0"
                  }
                  name="regionId"
                  placeholder="All"
                  inputChange={(value: string) => {
                    setSessionFilter((prevState) => ({
                      ...prevState,
                      regionId: +value,
                    }));
                  }}
                  label="Region"
                >
                  {[...[{ id: 0, name: "All" }, ...selectedRegions]]}
                </FormFieldSelect>
                <FormFieldSelect
                  value={
                    sessionFilter.sportId
                      ? sessionFilter.sportId.toString()
                      : "0"
                  }
                  name="sportId"
                  inputChange={(value: string) => {
                    setSessionFilter((prevState: any) => ({
                      ...prevState,
                      sportId: parseInt(value),
                    }));
                  }}
                  label="Sport"
                  placeholder="All"
                >
                  {[...sports, ...[{ id: 0, name: "All" }]]}
                </FormFieldSelect>
                <FormFieldSelect
                  name="dayOfWeek"
                  value={
                    sessionFilter.dayOfWeek
                      ? sessionFilter.dayOfWeek.toString()
                      : "0"
                  }
                  inputChange={(value: string) => {
                    setSessionFilter((prevState: any) => ({
                      ...prevState,
                      dayOfWeek: parseInt(value),
                    }));
                  }}
                  label="Day"
                  placeholder="All"
                >
                  {[...weekdaysArray]}
                </FormFieldSelect>
                <FormFieldSelect
                  name="registrationBatchId"
                  value={
                    sessionFilter.registrationBatchId
                      ? sessionFilter.registrationBatchId.toString()
                      : "0"
                  }
                  inputChange={(value: string) => {
                    setSessionFilter((prevState: any) => ({
                      ...prevState,
                      registrationBatchId: parseInt(value),
                    }));
                  }}
                  label="Registration Batch"
                  placeholder="All"
                >
                  {[...registrationBatches, ...[{ id: 0, name: "All" }]]}
                </FormFieldSelect>
                <FormFieldSelect
                  name="genderFormatId"
                  value={
                    sessionFilter.genderFormatId
                      ? sessionFilter.genderFormatId.toString()
                      : "0"
                  }
                  inputChange={(value: string) => {
                    setSessionFilter((prevState: any) => ({
                      ...prevState,
                      genderFormatId: parseInt(value),
                    }));
                  }}
                  label="Gender Format"
                  placeholder="All"
                >
                  {[...genderFormat, ...[{ id: 0, name: "All" }]]}
                </FormFieldSelect>
              </div>
            </div>
            {isLoading ? (
              <LoadingMaterialUI />
            ) : (
              <div className="mt-5">
                <Subtitle1>Select Session</Subtitle1>
                <FormFieldSelect
                  name="sessionId"
                  value={
                    division.sessionId ? division.sessionId.toString() : "0"
                  }
                  loading={loadingSessionsForFilter}
                  label="Session"
                  placeholder="Session"
                  inputChange={(value: string) => {
                    handleChangeSession(value);
                    setSelectSessionModal(0);
                  }}
                  disabled={isEditDivision ? true : false}
                >
                  {[
                    { id: "0", name: "Select Session" },
                    ...sessionsForDivision,
                  ]}
                </FormFieldSelect>
              </div>
            )}
          </div>
        </Box>
      </Modal>
      <BreadCrumbs
        paths={pathsBreadcrumbs}
        goBackTo="/ops/divisions"
      ></BreadCrumbs>
      <div className="flex flex-row items-center justify-between w-full mt-6">
        <Headline1Variable>
          {isEditDivision ? "Edit Division" : "Create New Division"}
        </Headline1Variable>

        <div className="flex flex-row gap-2">
          <Button
            variant="primary"
            className="h-fit"
            onClick={(e) => handleSubmitForm(e)}
          >
            {isEditDivision ? (
              <span> Edit Division</span>
            ) : (
              <span> Create New Division</span>
            )}
          </Button>
          <Button
            variant={isEditDivision ? "secondary" : "disabled"}
            className="h-fit"
            disabled={!isEditDivision}
            href={`${+isEditDivision!}/schedule`}
          >
            Schedule
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {divisionByIdData?.division?.latestSchedule?.regularGames.some(
          (game) => game.homeTeam === null || game.awayTeam === null
        ) && (
          <Disclaimer
            variant="error"
            title={`This division has games with missing teams. Please go to the schedule to fix this.`}
            size="large"
            persist={true}
          />
        )}
      </div>
      <form autoComplete="off">
        <Card className="mt-6">
          <Subtitle1>Division Details</Subtitle1>
          <div className="flex flex-col">
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
              <FormField
                type="name"
                name="name"
                value={division.name}
                label="Name"
                inputChange={handleChange}
                initialValue={division.name ? division.name : ""}
              />
              <FormField
                type="vmName"
                name="vmName"
                value={division.vmName}
                label="Venue Master Name"
                initialValue={division.vmName ? division.vmName : ""}
                inputChange={handleChange}
              />
              <div className={classes.color_preview}>
                <FormField
                  type="vmColor"
                  name="vmColor"
                  value={division.vmColor}
                  label="Venue Master Color"
                  inputChange={handleChange}
                  color={division.vmColor}
                  initialValue={division.vmColor ? division.vmColor : ""}
                />
                <div className="mt-3">
                  <Button
                    variant="secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      generateRandomColor();
                    }}
                  >
                    Generate
                  </Button>
                </div>
                <div
                  style={{
                    backgroundColor: division.vmColor,
                    height: "35px",
                    width: "35px",
                    marginTop: "10px",
                    padding: "10px",
                    borderRadius: "20px",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </Card>
        <Card className="mt-6">
          <Subtitle1>
            {isEditDivision ? `Division Info` : `Session Info`}
          </Subtitle1>
          <div className="flex flex-col gap-2">
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
              <div className="flex flex-row gap-2 md:col-span-2">
                {isEditDivision ? (
                  <>
                    <FormFieldViewOnly
                      text={session.name}
                      className="w-full max-w-full"
                    />
                  </>
                ) : (
                  <>
                    <FormFieldSelect
                      name="sessionId"
                      value={
                        division.sessionId ? division.sessionId.toString() : "0"
                      }
                      label="Session"
                      placeholder="Session"
                      inputChange={(value: string) => {
                        handleChangeSession(value);
                      }}
                      disabled={true}
                      loading={loadingSessionsForFilter}
                    >
                      {[
                        { id: 0, name: "Select Session" },
                        ...sessionsForDivision,
                      ]}
                    </FormFieldSelect>
                    <div>
                      <Button
                        className="w-40 mt-4"
                        variant="secondary"
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectSessionModal(1);
                        }}
                      >
                        <FilterListIcon
                          fontSize="small"
                          sx={{
                            width: "18px",
                            height: "18px",
                          }}
                        />
                        <span> Filters</span>
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
            {session.id === 0 ? (
              <Alert
                size="small"
                persist={true}
                variant="info"
                content={"Select a session using filter."}
              />
            ) : (
              <>
                <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
                  <FormFieldDate
                    label="Start Date"
                    initialValue={
                      session.startDate != ""
                        ? dayjs(session.startDate).toDate()
                        : undefined
                    }
                    disabled={session.startDate == ""}
                    dateChange={(date) => {
                      setSession((prevState) => ({
                        ...prevState,
                        startDate: dayjs(date).format("YYYY-MM-DD"),
                      }));
                    }}
                  />
                  <FormFieldDate
                    label="End Date"
                    initialValue={
                      session.endDate != ""
                        ? dayjs(session.endDate).toDate()
                        : undefined
                    }
                    disabled={session.endDate == ""}
                    dateChange={(date) => {
                      setSession((prevState) => ({
                        ...prevState,
                        endDate: dayjs(date).format("YYYY-MM-DD"),
                      }));
                    }}
                  />
                </div>
                <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
                  <div className="md:col-span-2">
                    <div className={`flex flex-col w-full text-black`}>
                      <div className={`px-3 text-xs font-medium`}>
                        Exclusion Dates
                      </div>
                      <MultipleDatePicker
                        multiple
                        plugins={[<DatePanel />]}
                        inputClass={classes["multi-DatePicker-input"]}
                        containerClassName={
                          classes["multi-DatePicker-container"]
                        }
                        value={session.exceptionDates as string[]}
                        name="exclusionDates"
                        //onChange throws Date as DateObject only
                        onChange={(selectedDates: DateObject[]) => {
                          if (Array.isArray(selectedDates)) {
                            const dates = selectedDates?.map((date) => {
                              return new Date(date.format()).toISOString();
                            });
                            setSession({ ...session, exceptionDates: dates });
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
                  <FormField
                    name="numberOfWeeks"
                    label="Total Weeks of Play"
                    initialValue={
                      session.numberOfWeeks
                        ? session.numberOfWeeks.toString()
                        : "0"
                    }
                    inputChange={(e) => {
                      setSession({
                        ...session,
                        numberOfWeeks: +e.target.value,
                      });
                    }}
                  />
                  <FormField
                    name="weeksOfPlayoffs"
                    label="Weeks of PlayOffs"
                    initialValue={
                      session.weeksOfPlayoffs
                        ? session.weeksOfPlayoffs.toString()
                        : "0"
                    }
                    inputChange={(e) => {
                      setSession({
                        ...session,
                        weeksOfPlayoffs: +e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
                  <div className="md:col-span-2">
                    <FormFieldSelect
                      name="skillChoiceId"
                      placeholder="Select skill level"
                      label="Skill Level"
                      value={
                        division.skillChoiceId
                          ? division.skillChoiceId.toString()
                          : "0"
                      }
                      inputChange={(value: string) => {
                        setDivision({
                          ...division,
                          skillChoiceId: parseInt(value),
                        });
                      }}
                    >
                      {[
                        { id: 0, name: "Select Skill Level" },
                        ...regSkillChoiceList,
                      ]}
                    </FormFieldSelect>
                  </div>
                </div>
              </>
            )}
          </div>
        </Card>
        <Card className="mt-6">
          {divisionByIdData?.division.id != null && (
            <Button
              variant="secondary"
              href={`/ops/division/reseed/${divisionByIdData?.division.id}`}
            >
              <Body1>Re-seed Divisions</Body1>
            </Button>
          )}
          <div className="flex gap-2 mt-3">
            <Subtitle1>Teams and Divisions </Subtitle1>
            <IconButton
              sx={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                border: "1px solid #585858",
                padding: "12px",
              }}
              onClick={() => {
                setOpenInfoModal(true);
              }}
            >
              <QuestionMark />
            </IconButton>
          </div>
          {isLoading || loadingRatings ? (
            <LoadingMaterialUI />
          ) : (
            <div className="flex">
              {/* Unpooled Teams */}
              {unpooledTeams()}
              {/* Pooled Teams */}
              <div className="flex flex-wrap w-full">
                {session.divisions.length > 0 &&
                  session.divisions.map((sessionDivision) => {
                    return (
                      <div
                        key={sessionDivision.id}
                        className="w-1/3 p-4 m-2 bg-neutral-70"
                      >
                        <div className="flex flex-row items-center gap-2">
                          <div
                            style={{
                              backgroundColor: sessionDivision.vmColor,
                              height: "16px",
                              width: "16px",
                              borderRadius: "10px",
                            }}
                          ></div>
                          <p>{sessionDivision.name}</p>
                        </div>
                        <div>
                          {sessionDivision.teams
                            .map((team) => {
                              const teamRating =
                                sessionRatings?.getTeamsRatingsForSession.find(
                                  (rating) => rating?.teamId === team.id
                                );

                              // Create a new object with the original team properties and add rating/confidence
                              return {
                                ...team, // Copy existing properties of the team
                                rating: teamRating ? teamRating.rating : 0,
                                confidence: teamRating
                                  ? teamRating.confidence
                                  : 0,
                                nhFlag: teamRating?.ncFlag,
                                opFlag: teamRating?.opFlag,
                              };
                            })
                            .sort((a, b) =>
                              a.rating && b.rating && a.rating < b.rating
                                ? 1
                                : -1
                            )
                            .map((team: any, index: number) => {
                              const skill = team.registration
                                ? team.registration.regQuestionChoice?.regSkillChoice?.name.substring(
                                    0,
                                    3
                                  )
                                : null;

                              return (
                                <div key={team.id}>
                                  <div className="flex items-center my-1">
                                    {!isSchedulePublished &&
                                      division?.id === sessionDivision.id && (
                                        <input
                                          className="mr-2"
                                          type="checkbox"
                                          value={team.id}
                                          defaultChecked={
                                            !sessionDivision.teams.includes(
                                              team.id
                                            )
                                          }
                                          // checked={!division.teams.includes(+team.id)}
                                          onChange={handleTeamChecked}
                                        />
                                      )}
                                    <div className="flex items-center gap-2">
                                      <span>{team.name}</span>
                                      {skill && (
                                        <Chip
                                          size="small"
                                          label={skill}
                                          variant="outlined"
                                        />
                                      )}
                                      <Chip
                                        size="small"
                                        label={`${team.rating}R - ${
                                          team.confidence
                                        }C${
                                          team.opFlag
                                            ? "+"
                                            : team.ncFlag
                                            ? "*"
                                            : ""
                                        }`}
                                        variant="outlined"
                                      />
                                    </div>
                                  </div>
                                  <Separator />
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </Card>
        <div className="flex flex-row-reverse gap-3 mt-5">
          <Button
            variant="primary"
            onClick={(e) => handleSubmitForm(e)}
          >
            {isEditDivision ? (
              <span> Edit Division</span>
            ) : (
              <span> Create New Division</span>
            )}
          </Button>
          <Button
            variant="secondary"
            href={"/ops/divisions"}
          >
            Cancel
          </Button>
          <Modal
            open={openInfoModal}
            onClose={() => {
              setOpenInfoModal(false);
            }}
            aria-labelledby="info-modal"
            aria-describedby="info-modal"
          >
            <Box sx={style}>
              {/* Icons Info */}
              <div className="flex flex-col gap-3">
                <div className="text-xl font-bold">
                  Team Rating and Confidence{" "}
                  <span className="text-light">(eg. 77R - 88C)</span>
                </div>
                <div>
                  <span className="font-extrabold text-dark">
                    Team ratings and confidence are specific to the sport being
                    played.
                  </span>{" "}
                  The left number is a rating that has been calculated for the
                  team, based on playerson the roster. It takes into account
                  many factos. In general, teams should fall within the
                  following:
                </div>
                <ul className="list-disc">
                  <li>Open: 40-80</li>
                  <li>Rec: 50-80</li>
                  <li>Int: 75-95</li>
                  <li>Adv: 85-100</li>
                </ul>
                <div>
                  The higher the team is rated, the higher the division it
                  should be in.
                </div>
                <div>
                  The second number is the confidence in the rating. The more
                  history we have for players on the team the higher the
                  confidence in the rating{" "}
                </div>
                <div>
                  The special case of{" "}
                  <span className="font-extrabold text-dark">0R-0C</span> means
                  we have no history for anybody in the team.
                </div>
                <div>Other thins to note:</div>
                <ul className="list-disc">
                  <li>
                    C* - At least one player on the team has no history (is new
                    to this sport)
                  </li>
                  <li>C+ - The team only has one player on the roster</li>
                </ul>
              </div>
              <div className="flex justify-end gap-3 mt-5">
                <Button
                  variant="secondary"
                  onClick={() => {
                    setOpenInfoModal(false);
                  }}
                >
                  Close
                </Button>
              </div>
            </Box>
          </Modal>
        </div>
      </form>
    </main>
  );
};

export default CreateDivision;
