import { createContext, useContext, useState } from "react";
import { WeatherByRegionIdQuery } from "../generated/graphql";

type WeatherContextType = {
  weather: WeatherByRegionIdQuery["weatherByRegionId"];
  setWeather: React.Dispatch<
    React.SetStateAction<WeatherByRegionIdQuery["weatherByRegionId"]>
  >;
  filter: {
    regionId: number;
  };
  setFilter: React.Dispatch<
    React.SetStateAction<{
      regionId: number;
    }>
  >;
  americanMode: boolean;
  setAmericanMode: React.Dispatch<React.SetStateAction<boolean>>;
};

const WeatherContextDefault: WeatherContextType = {
  weather: [],
  setWeather: () => {},
  filter: {
    regionId: 0,
  },
  setFilter: () => {},
  americanMode: false,
  setAmericanMode: () => {},
};

const WeatherContext = createContext<WeatherContextType>(WeatherContextDefault);

export const WeatherContextProvider = ({ children }: any) => {
  const [weather, setWeather] = useState<
    WeatherByRegionIdQuery["weatherByRegionId"]
  >([]);
  const [filter, setFilter] = useState<{
    regionId: number;
  }>({
    regionId: 0,
  });
  const [americanMode, setAmericanMode] = useState<boolean>(false);

  const value = {
    weather,
    setWeather,
    filter,
    setFilter,
    americanMode,
    setAmericanMode,
  };

  return (
    <WeatherContext.Provider value={value}>{children}</WeatherContext.Provider>
  );
};

export const useWeatherContext = () => useContext(WeatherContext);
