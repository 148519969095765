import React, { FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { loginUser, sendMFACode, verifyMFA } from "../app/authSlice";
import { AppDispatch, RootState } from "../app/store";
import FormRow from "../components/UI/FormRow";
import classes from "./Register.module.css";
import { LoadingMaterialUI } from "../components/UI";
import CheckBox from "../components/UI/Checkbox/Checkbox";
import { displayAlertError } from "../app/globalSlice";
import FormField from "../components/UI/FormField/FormField";
import FormFieldPassword from "../components/UI/FormField/FormFieldPassword/FormFieldPassword";
import Headline1Variable from "../components/UI/Text/Headline/Headline1Variable";
import Button from "../components/UI/Button/Button";
import {
  RadioGroup,
  RadioGroupItem,
} from "../components/UI/shadcn/radio-group";
import { Label } from "../components/UI/shadcn/label";

interface UserCredential {
  email: string;
  password: string;
  rememberMe: boolean;
}

const initialState: UserCredential = {
  email: "",
  password: "",
  rememberMe: false,
};

const Register = () => {
  const [values, setValues] = useState(initialState);
  const dispatch = useDispatch<AppDispatch>();
  const { user, isLoading } = useSelector((state: RootState) => state.auth);
  const [mfaCode, setMfaCode] = useState("");
  const [showMfaInput, setShowMfaInput] = useState(false);
  const [showCommunicationMethod, setShowCommunicationMethod] = useState(false);
  const [communicationMethod, setCommunicationMethod] = useState("email");
  const [communicationCredentials, setCommunicationCredentials] = useState({
    email: "",
    phoneNumber: "",
  });

  const navigate = useNavigate();
  const location = useLocation();

  const { from } = location.state || { from: { pathname: "/" } };
  const submitUserCreds = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { email, password } = values;
    if (!email || !password) {
      dispatch(displayAlertError("Please fill out all fields"));
      return;
    }

    dispatch(loginUser({ email, password }))
      .then((result) => {
        if (result.payload?.status === "success") {
          setShowCommunicationMethod(true);
          setCommunicationCredentials(result.payload.user);
          // setShowMfaInput(true);
          return;
        }
        dispatch(displayAlertError(result.payload || "Something went wrong"));
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(displayAlertError("Something went wrong"));
      });
    return;

    // dispatch(registerUser({ firstName, lastName, email, password, regionId }));
    // return;
  };

  const submitCommunicationMethod = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { email, password } = values;

    if (communicationMethod !== "email" && communicationMethod !== "sms") {
      dispatch(displayAlertError("Please provide a delivery method"));
      return;
    }

    dispatch(sendMFACode({ email, password, communicationMethod }))
      .then((res) => {
        if (res.payload?.status === "success") {
          setShowCommunicationMethod(false);
          setShowMfaInput(true);
          // navigate(from);
          return;
        }
        dispatch(displayAlertError(res.payload || "Something went wrong"));
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(displayAlertError("Something went wrong"));
      });
  };

  const submitMfaCode = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { email, password, rememberMe } = values;

    if (!email || !password || !mfaCode) {
      dispatch(displayAlertError("Something went wrong. Please try again."));
      return;
    }

    dispatch(verifyMFA({ email, password, mfaCode, rememberMe }))
      .then((res) => {
        if (res.payload?.status === "success") {
          navigate(from);
          return;
        }
        dispatch(displayAlertError(res.payload || "Something went wrong"));
      })
      .catch((error) => {
        console.log("error", error);
        dispatch(displayAlertError("Something went wrong"));
      });
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    }
    return () => {
      setShowMfaInput(false);
      setShowCommunicationMethod(false);
    };
  }, [user, navigate]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <LoadingMaterialUI />
      </div>
    );
  }

  if (showCommunicationMethod) {
    return (
      <main>
        <form
          className={classes.form}
          onSubmit={(e: FormEvent<HTMLFormElement>) =>
            submitCommunicationMethod(e)
          }
        >
          <Headline1Variable>Select your delivery method</Headline1Variable>
          <p>
            Please select the method you would like to receive the verification
            code.
          </p>
          <div>
            <RadioGroup
              defaultValue="email"
              onValueChange={(e) => {
                setCommunicationMethod(e);
              }}
            >
              {communicationCredentials.email && (
                <div className="flex items-center space-x-2">
                  <RadioGroupItem
                    value="email"
                    id="r2"
                  />
                  <Label htmlFor="r2">{`Email to ${communicationCredentials.email}`}</Label>
                </div>
              )}
              {communicationCredentials.phoneNumber && (
                <div className="flex items-center space-x-2">
                  <RadioGroupItem
                    value="sms"
                    id="r1"
                  />
                  <Label htmlFor="r1">{`SMS to ${communicationCredentials.phoneNumber}`}</Label>
                </div>
              )}
            </RadioGroup>
          </div>
          <Button
            type="submit"
            className="w-96"
            disabled={isLoading}
            variant="primary"
          >
            Submit
          </Button>
        </form>
      </main>
    );
  }

  if (showMfaInput) {
    return (
      <main>
        <form
          className={classes.form}
          onSubmit={(e: FormEvent<HTMLFormElement>) => submitMfaCode(e)}
        >
          <div className="flex flex-col items-center justify-center gap-4">
            <Headline1Variable>Verify</Headline1Variable>
            <p>Please enter the 6 digit code sent out to you.</p>
            <FormField
              inputChange={(e: any) => setMfaCode(e.target.value)}
              name="mfaCode"
              label="Verification Code"
              className="max-w-96 w-96"
            />
            <Button
              type="submit"
              className="w-96"
              disabled={isLoading}
              variant="primary"
            >
              Submit
            </Button>
          </div>
        </form>
      </main>
    );
  }

  return (
    <main>
      <form
        className="flex flex-col gap-5 items-center w-1/2 mt-[10%] mx-auto"
        onSubmit={(e: FormEvent<HTMLFormElement>) => submitUserCreds(e)}
      >
        <Headline1Variable>Login</Headline1Variable>
        <div className="flex flex-col gap-4 max-w-96 w-96">
          <FormField
            inputChange={(e: any) =>
              setValues((prevState) => ({
                ...prevState,
                email: e.target.value,
              }))
            }
            type="email"
            name="email"
            label="Email"
          />
          <FormFieldPassword
            inputChange={(e: any) =>
              setValues((prevState) => ({
                ...prevState,
                password: e.target.value,
              }))
            }
            name="current-password"
            id="current-password"
            autoComplete="current-password"
            label="Password"
          />
        </div>
        <div>
          <CheckBox
            defaultChecked={values.rememberMe}
            label="Remember Me"
            inputChange={(val: boolean) => {
              setValues({
                ...values,
                rememberMe: val,
              });
            }}
          />
        </div>

        <Button
          type="submit"
          disabled={isLoading}
          variant="primary"
          className="w-96"
        >
          Login
        </Button>
      </form>
    </main>
  );
};

export default Register;
