import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const StaffingShiftsHome = () => {
  return (
    <main>
      <h3>This is the STAFFING Shifts home page</h3>
      <div style={{ display: "flex", gap: "1rem" }}>
        <Link to={"/staffing/create-shift"}>
          <Button
            sx={{
              color: "var(--black)",
              bgcolor: "var(--primary-green-400)",
              "&:hover": { bgcolor: "var(--primary-green-500)" },
              borderRadius: "0.5rem",
            }}
          >
            Create Shift
          </Button>
        </Link>
        <Link to={"/staffing/all-staff-shifts"}>
          <Button
            sx={{
              color: "var(--black)",
              bgcolor: "var(--primary-green-400)",
              "&:hover": { bgcolor: "var(--primary-green-500)" },
              borderRadius: "0.5rem",
            }}
          >
            View All Shifts
          </Button>
        </Link>
        <Link to={"/staffing/all-contractor-shifts"}>
          <Button
            sx={{
              color: "var(--black)",
              bgcolor: "var(--primary-green-400)",
              "&:hover": { bgcolor: "var(--primary-green-500)" },
              borderRadius: "0.5rem",
            }}
          >
            View All Shifts (Contractor)
          </Button>
        </Link>
        <Link to={"/staffing/requested-shifts"}>
          <Button
            sx={{
              color: "var(--black)",
              bgcolor: "var(--primary-green-400)",
              "&:hover": { bgcolor: "var(--primary-green-500)" },
              borderRadius: "0.5rem",
            }}
          >
            Shifts Requested
          </Button>
        </Link>
        <Link to={"/staffing/shifts-finance"}>
          <Button
            sx={{
              color: "var(--black)",
              bgcolor: "var(--primary-green-400)",
              "&:hover": { bgcolor: "var(--primary-green-500)" },
              borderRadius: "0.5rem",
            }}
          >
            Shift (Finance)
          </Button>
        </Link>
        <Link to={"/staffing/my-shifts"}>
          <Button
            sx={{
              color: "var(--black)",
              bgcolor: "var(--primary-green-400)",
              "&:hover": { bgcolor: "var(--primary-green-500)" },
              borderRadius: "0.5rem",
            }}
          >
            My Shifts
          </Button>
        </Link>
        <Link to={"/staffing/open-shifts"}>
          <Button
            sx={{
              color: "var(--black)",
              bgcolor: "var(--primary-green-400)",
              "&:hover": { bgcolor: "var(--primary-green-500)" },
              borderRadius: "0.5rem",
            }}
          >
            Open Shifts
          </Button>
        </Link>
        <Link to={"/staffing/applied-shifts"}>
          <Button
            sx={{
              color: "var(--black)",
              bgcolor: "var(--primary-green-400)",
              "&:hover": { bgcolor: "var(--primary-green-500)" },
              borderRadius: "0.5rem",
            }}
          >
            My Shifts Requests
          </Button>
        </Link>
      </div>
    </main>
  );
};

export default StaffingShiftsHome;
