import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { AppDispatch } from "../../../app/store";
import Button from "../../UI/Button/Button";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import Card from "../../UI/Card/Card";
import ReactQuill from "react-quill";
import { z, ZodFormattedError } from "zod";
import CheckBox from "../../UI/Checkbox/Checkbox";
import { Column } from "react-table";
import {
  ListAllOperations,
  ShiftNotesByIdQuery,
  useCreateShiftNoteMutation,
  useShiftNotesByIdQuery,
} from "../../../generated/graphql";
import BaseTable from "../../UI/Table/Table";
import Subtitle1 from "../../UI/Text/Subtitle/Subtitle1";
import {
  displayAlertError,
  displayAlertSuccess,
  displayAlertWarning,
} from "../../../app/globalSlice";
import Caption2 from "../../UI/Text/Caption/Caption2";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Caption1 from "../../UI/Text/Caption/Caption1";
import LoadingDialog from "../../UI/Dialog/LoadingDialog";

const ShiftNoteSchema = z
  .object({
    note: z.string(),
    isVisible: z.boolean(),
    shiftId: z.number(),
  })
  .refine((data) => data.note !== "<p><br></p>" && data.note !== "", {
    message: "The note cannot be empty",
    path: ["note"],
  });

type ShiftNoteFormValues = z.infer<typeof ShiftNoteSchema>;

declare module "react-quill" {
  interface QuillStatic {
    register: (module: any, suppressWarning?: boolean) => void;
  }
}

const initialShiftNoteData = {
  note: "",
  isVisible: false,
  shiftId: 0,
};

const ShiftNotes = () => {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();

  // The id will be used to know if we're editing or creating data
  const { id } = params;

  /*** STATES ***/
  const [shiftNoteData, setShiftNoteData] = useState<ShiftNoteFormValues>({
    ...initialShiftNoteData,
    shiftId: id ? +id : 0,
  });
  // Zod errors used to show errors on the form
  const [zodErrors, setZodErrors] = useState<
    ZodFormattedError<ShiftNoteFormValues, string>
  >({ _errors: [] });

  const { loading: loadingNotes, data } = useShiftNotesByIdQuery({
    variables: {
      shiftId: +id!,
    },
    skip: id === undefined,
    notifyOnNetworkStatusChange: true,
  });

  const [CreateShiftNote, { loading: loadingCreateShiftNote }] =
    useCreateShiftNoteMutation({});

  async function handleSubmit() {
    const result = ShiftNoteSchema.safeParse(shiftNoteData);
    if (!result.success) {
      setZodErrors(result.error.format());
      dispatch(displayAlertWarning("There is an issue with the form"));
      return;
    }

    await CreateShiftNote({
      variables: {
        shiftNoteArgs: {
          ...shiftNoteData,
        },
      },
      refetchQueries: [ListAllOperations.Query.ShiftNotesById],
      onCompleted: (data) => {
        if (data.createShiftNote.success) {
          dispatch(displayAlertSuccess(data.createShiftNote.message));
          setShiftNoteData(() => ({
            ...initialShiftNoteData,
            shiftId: id ? +id : 0,
          }));
        } else {
          dispatch(displayAlertError(data.createShiftNote.message));
        }
      },
      onError: (error) => {
        dispatch(displayAlertError(error.message));
      },
    });
  }

  const COLUMNS: Column<ShiftNotesByIdQuery["shiftNotesById"][0]>[] = [
    {
      Header: "Id",
      id: "Id",
      accessor: (d) => {
        return <div>{d.id}</div>;
      },
    },
    {
      Header: "Note",
      id: "note",
      accessor: (d) => {
        return <div dangerouslySetInnerHTML={{ __html: d.note }}></div>;
      },
    },
    {
      Header: "Created At",
      id: "createdAt",
      accessor: (d) => {
        return <div>{d.createdAt}</div>;
      },
    },
    {
      Header: "Created By",
      id: "createdBy",
      accessor: (d) => {
        return <div>{d.user?.firstName ?? "N/A"}</div>;
      },
    },
  ];

  useEffect(() => {
    console.log(shiftNoteData);
  }, [shiftNoteData]);
  // Constants for the table
  const columns = useMemo(() => COLUMNS, []);
  const tableData = useMemo(() => {
    if (data) return data.shiftNotesById;
  }, [data]);

  return (
    <main className="pb-20">
      <LoadingDialog open={loadingCreateShiftNote || loadingNotes} />
      <Headline1Variable>LA Shift Notes</Headline1Variable>
      <div className="flex flex-col gap-4">
        <Card className="flex flex-col gap-4 w-min h-min">
          <div className="mb-12">
            <ReactQuill
              style={{ height: "400px", width: "700px" }}
              theme="snow" // you can use 'bubble' theme as well
              value={shiftNoteData.note}
              onChange={(text: string) => {
                if (zodErrors.note && text.length > 0) {
                  setZodErrors((prevState) => ({
                    ...prevState,
                    note: undefined,
                  }));
                }
                setShiftNoteData((prevState) => ({ ...prevState, note: text }));
              }}
            />
          </div>
          {zodErrors.note && (
            <div className="flex flex-row items-center gap-1">
              <ErrorOutlineOutlinedIcon className="text-error-30" />
              <Caption1 className="text-error-10">
                {zodErrors.note._errors[0]}
              </Caption1>
            </div>
          )}
          <CheckBox
            checkedState={shiftNoteData.isVisible}
            inputChange={(value) => {
              setShiftNoteData((prevState) => ({
                ...prevState,
                isVisible: value,
              }));
            }}
            label="Note Visible"
          />
          <Button
            variant="primary"
            onClick={handleSubmit}
            className="w-60"
          >
            Create Note
          </Button>
        </Card>
        {tableData && tableData.length > 0 ? (
          <BaseTable
            columns={columns}
            data={tableData}
          />
        ) : (
          <Subtitle1>Nothing Yet</Subtitle1>
        )}
      </div>
    </main>
  );
};

export default ShiftNotes;
