import React from "react";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "../../UI/shadcn/popover";
import { useNavigate } from "react-router-dom";
import Button from "../../UI/Button/Button";

const WeatherNavBar = () => {
  return (
    <Button
      href={"/weather"}
      variant="secondary"
    >
      Weather
    </Button>
  );
};

export default WeatherNavBar;
