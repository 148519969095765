import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../app/store";
import { Column } from "react-table";
import AddIcon from "@mui/icons-material/Add";
import { Pagination } from "../../../../types/types";
import BaseTable from "../../../UI/Table/Table";
import Button from "../../../UI/Button/Button";
import ActionsCell from "../../../UI/ActionCell";
import Headline1Variable from "../../../UI/Text/Headline/Headline1Variable";
import TablePagination from "../../../UI/Pagination/Pagination";
import {
  GetRulesPaginatedQuery,
  RegistrationBySessionIdQuery,
  useGetRulesPaginatedLazyQuery,
  useRegistrationBySessionIdLazyQuery,
} from "../../../../generated/graphql";
import LoadingDialog from "../../../UI/Dialog/LoadingDialog";
import { FormFieldSelect } from "../../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { getSports } from "../../../../app/venueMasterSlice";
import { useSessionWorkflowContext } from "../../../../context/SessionWorkflowContext";
import dayjs from "dayjs";
import { dayOfWeek } from "../../../../utils/dayOfWeek";
import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "../../../UI/Table/DataTable";

const SessionWorkflowRegistration: React.FC = () => {
  /*** STATES ***/
  const { selectedSession, tabGroupValue } = useSessionWorkflowContext();
  const [registrations, setRegistraions] = useState<
    RegistrationBySessionIdQuery["registrationBySessionId"]
  >([]);

  /*** QUERIES ***/
  const [getRegistrations, { data, loading }] =
    useRegistrationBySessionIdLazyQuery({});

  /*** USE EFFECTS ***/
  // Queries the data whenever the filter changes add any new filterable ids to the dependancy array
  useEffect(() => {
    if (selectedSession && tabGroupValue === "Registrations") {
      getRegistrations({
        variables: {
          sessionId: selectedSession.id,
        },
        onCompleted: (data) => {
          setRegistraions(data.registrationBySessionId);
        },
      });
    } else {
      setRegistraions([]);
    }
  }, [selectedSession]);

  /*** UTILITY FUNCTIONS ***/
  const COLUMNS: ColumnDef<
    RegistrationBySessionIdQuery["registrationBySessionId"][0]
  >[] = [
    {
      id: "createdAt",
      header: "Created At",
      accessorFn: (d) => dayjs(d.createdAt).format("YYYY-MM-DD HH:mm"),
    },
    {
      id: "firstName",
      header: "Player Name",
      cell: ({ row }) => {
        return (
          <a
            className="underline cursor-pointer text-info-50"
            href={`/players/user/${row.original.user.id}`}
          >
            {row.original.user.firstName} {row.original.user.lastName}
          </a>
        );
      },
    },
    {
      id: "gender",
      header: "Gender",
      accessorFn: (row) => row.user.genderIdentity?.name,
    },
    {
      id: "email",
      header: "Email",
      accessorFn: (row) => row.user.email,
    },
    {
      id: "region",
      header: "Region",
      accessorFn: (row) => row.session.league.sportFormat.region.name,
    },
    {
      id: "registrationType",
      header: "Reg Type",
      accessorFn: (row) => row.registrationType.type,
    },
    {
      id: "teamName",
      header: "Team Name",
      accessorFn: (row) => row.team?.name || "N/A",
    },
    {
      id: "season",
      header: "Season",
      accessorFn: (row) => row.session.registrationBatch.name,
    },
    {
      id: "shoppingCartId",
      header: "Shopping Cart Id",
      cell: ({ row }) => {
        return (
          <a
            className="underline cursor-pointer text-info-50"
            href={`/players/shopping-cart/${row.original.shoppingCart.id}`}
          >
            {row.original.shoppingCart.id}
          </a>
        );
      },
    },
    {
      id: "amountPaid",
      header: "Amount",
      accessorFn: (row) => row.shoppingCartItem.amountPaid,
    },
    {
      id: "isDeleted",
      header: "Deleted?",
      accessorFn: (row) => (row.isDeleted ? "Yes" : "No"),
    },
  ];

  // Constants for the table
  const columns = useMemo(() => COLUMNS, []);
  const tableData = useMemo(() => {
    if (registrations) return registrations;
  }, [registrations]);

  return (
    <main className="flex flex-col gap-4">
      {tableData && (
        <DataTable
          columns={columns}
          data={tableData}
        />
      )}
    </main>
  );
};

export default SessionWorkflowRegistration;
