import { cn } from '../../../../lib/utils';
import React, { ReactNode } from 'react';

interface Caption2Props {
  // The text inside the Headline
  children: ReactNode;
  // Extra css customization
  className?: string;
}

const Caption2 = ({ children, className, ...props }: Caption2Props) => {
  return (
    <p
      className={cn(
        'text-txt-1 font-[400] text-[10px] tracking-[0.05em] leading-[1.6]',
        className
      )}
    >
      {children}
    </p>
  );
};

export default Caption2;
