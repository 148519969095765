import React from "react";
import dayjs from "dayjs";
import WeatherDayCard from "./WeatherDayCard"; // Adjust the import path as necessary
import { WeatherByRegionIdQuery } from "../../../generated/graphql";
import Headline2Variable from "../../UI/Text/Headline/Headline2Variable";
import Body1 from "../../UI/Text/Body/Body1";

// Define the props for the WeatherForecast component
type WeatherForecastProps = {
  weatherData: WeatherByRegionIdQuery["weatherByRegionId"];
};

const WeatherForecast: React.FC<WeatherForecastProps> = ({ weatherData }) => {
  // Group weather data by date
  const weatherDataByDate = weatherData.reduce(
    (acc, item) => {
      const dateKey = dayjs(item.dateTime).format("YYYY-MM-DD");
      if (!acc[dateKey]) {
        acc[dateKey] = {
          date: dateKey,
          sunriseDateTime: item.sunriseDateTime,
          sunsetDateTime: item.sunsetDateTime,
          items: [] as WeatherByRegionIdQuery["weatherByRegionId"],
        };
      }
      acc[dateKey].items.push(item);
      return acc;
    },
    {} as Record<
      string,
      {
        date: string;
        sunriseDateTime: Date;
        sunsetDateTime: Date;
        items: WeatherByRegionIdQuery["weatherByRegionId"];
      }
    >
  );

  // Convert the grouped data into an array for mapping
  const groupedDataArray = Object.values(weatherDataByDate);

  return (
    <div className="flex flex-col gap-4">
      {groupedDataArray.map((dayData) => {
        return (
          <div key={dayData.date}>
            <Headline2Variable>
              {dayjs(dayData.date).format("dddd, MMMM D")}
            </Headline2Variable>
            <Body1>
              Sunrise: {dayjs(dayData.sunriseDateTime).format("h:mm A")} |
              Sunset: {dayjs(dayData.sunsetDateTime).format("h:mm A")}
            </Body1>
            <div className="flex flex-row gap-6">
              {dayData.items.map((item) => (
                <WeatherDayCard
                  key={item.id}
                  data={{
                    weatherIcon: item.weatherIcon,
                    weatherDescription: item.weatherDescription,
                    temp: item.temp,
                    rainVolume: item.rainVolume,
                    snowVolume: item.snowVolume,
                    cloudPercent: item.cloudPercent,
                    percentOfPrecipitation: item.percentOfPrecipitation,
                    dateTime: item.dateTime,
                  }}
                />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default WeatherForecast;
