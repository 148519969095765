import { gql } from "@apollo/client";

const GET_THIRD_PARTY_CONTRACTORS = gql`
  query ThirdPartyContractors($regions: [Int]) {
    thirdPartyContractors(regions: $regions) {
      email
      id
      name
      regionId
    }
  }
`;

gql`
  mutation CreateThirdPartyContractor(
    $thirdPartyContractorArgs: CreateThirdPartyContractor!
  ) {
    createThirdPartyContractor(
      thirdPartyContractorArgs: $thirdPartyContractorArgs
    ) {
      code
      message
      success
    }
  }
`;
gql`
  mutation UpdateThirdPartyContractor(
    $thirdPartyContractorArgs: UpdateThirdPartyContractor!
  ) {
    updateThirdPartyContractor(
      thirdPartyContractorArgs: $thirdPartyContractorArgs
    ) {
      code
      message
      success
    }
  }
`;

gql`
  query ThirdPartyContractorById($id: Int!) {
    thirdPartyContractorById(id: $id) {
      id
      name
      email
      regionId
      region {
        id
        name
      }
    }
  }
`;

gql`
  query ThirdPartyContractorPaginated(
    $page: Int
    $pageSize: Int
    $regionId: Int
  ) {
    thirdPartyContractorPaginated(
      page: $page
      pageSize: $pageSize
      regionId: $regionId
    ) {
      count
      thirdPartyContractor {
        id
        name
        email
        regionId
        region {
          id
          name
        }
      }
    }
  }
`;
gql`
  query ThirdPartyContractorByRegionId($regionId: Int) {
    thirdPartyContractorByRegionId(regionId: $regionId) {
      id
      name
      email
      regionId
      region {
        id
        name
      }
    }
  }
`;

export { GET_THIRD_PARTY_CONTRACTORS };
