import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../app/store";
import classes from "./ContractManagement.module.css";
import { Column } from "react-table";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import { Pagination, Season } from "../../types/types";
import { getRegistrationBatch, getSports } from "../../app/venueMasterSlice";
import { useMutation } from "@apollo/client";
import { DELETE_REGISTRATION_BATCH } from "../../graphql/queries";
import { LoadingMaterialUI } from "../UI";
import BaseTable from "../UI/Table/Table";
import Button from "../UI/Button/Button";
import ActionsCell from "../UI/ActionCell";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import TablePagination from "../UI/Pagination/Pagination";
import {
  GetRulesPaginatedQuery,
  useGetRulesPaginatedLazyQuery,
} from "../../generated/graphql";
import LoadingDialog from "../UI/Dialog/LoadingDialog";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";

const options = [{ id: 1, text: "Edit" }];

// Define the type for the Cell parameter
interface CellParam {
  row: { original: GetRulesPaginatedQuery["getRulesPaginated"]["rules"][0] };
  rows: { original: GetRulesPaginatedQuery["getRulesPaginated"]["rules"][0] }[];
}

const RulesReport: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { isLoading, selectedRegions, sports }: any = useSelector(
    (state: RootState) => state.venueMaster
  );

  //Pagination Page Size
  const pageSize = 10;

  const [filter, setFilter] = useState<{
    pagination: Pagination;
    sportId: number | null;
    regionId: number | null;
  }>({
    pagination: {
      page: 0,
      pageCount: 0,
      pageSize: pageSize,
    },
    sportId: 0,
    regionId: 0,
  });

  const [getRules, { loading: loadingRules, data: dataRules }] =
    useGetRulesPaginatedLazyQuery({
      onCompleted: (data) => {
        const pageCount = Math.ceil(data.getRulesPaginated.count / pageSize);

        setFilter((prevState) => ({
          ...prevState,
          pagination: {
            ...prevState.pagination,
            pageCount: pageCount,
          },
        }));
      },
    });

  useEffect(() => {
    if (filter) {
      getRules({
        variables: {
          page: filter.pagination.page,
          pageSize: filter.pagination.pageSize,
          regionId: filter.regionId ?? null,
          sportId: filter.sportId ?? null,
        },
      });
    }
  }, [filter.pagination.page, filter.regionId, filter.sportId]);

  // Get all the sports on load
  useEffect(() => {
    dispatch(getSports(""));
  }, [dispatch]);

  const handleChangePagination = (value: number) => {
    setFilter((prevState) => ({
      ...prevState,
      pagination: {
        pageCount: prevState.pagination.pageCount,
        pageSize: prevState.pagination.pageSize,
        page: value - 1,
      },
    }));
  };

  const handleSelectedOption = (
    row: GetRulesPaginatedQuery["getRulesPaginated"]["rules"][0],
    idx: number
  ) => {
    if (idx === 1) {
      navigate(`/ops/rule/${row.id}`);
    }
    if (idx === 2) {
    }
  };
  const COLUMNS: Column<
    GetRulesPaginatedQuery["getRulesPaginated"]["rules"][0]
  >[] = [
    {
      Header: "Name",
      id: "name",
      accessor: (d) => {
        return (
          <a
            className="underline"
            href={`/ops/rule/${d.id}`}
          >
            {d.name}
          </a>
        );
      },
    },
    {
      Header: "Region",
      id: "region",
      accessor: (d) => {
        return <div>{d.region ? d.region.name : "All"}</div>;
      },
    },
    {
      Header: "Sport",
      id: "sport",
      accessor: (d) => {
        return <div>{d.sport.name}</div>;
      },
    },

    {
      Header: " ",
      Cell: (d: CellParam) => {
        return (
          <ActionsCell
            row={d.row.original}
            options={options}
            handleSelectedOption={handleSelectedOption}
          />
        );
      },
    },
  ];

  const columns: any = useMemo(() => COLUMNS, []);
  const data = useMemo(() => {
    if (dataRules) return dataRules.getRulesPaginated.rules;
  }, [dataRules]);

  return (
    <main className="flex flex-col gap-4">
      <LoadingDialog open={loadingRules} />
      <div className="flex flex-row justify-between w-full">
        <Headline1Variable>Rules</Headline1Variable>
        <div className="mb-4 text-end">
          <Button
            variant="primary"
            href={"/ops/rule"}
          >
            <AddIcon />
            New Rule
          </Button>
        </div>
      </div>
      <div className="flex flex-row gap-4">
        <div className="max-w-60 min-w-60">
          {sports.length > 0 && (
            <FormFieldSelect
              name="sportId"
              id="sportId"
              value={filter.sportId ? filter.sportId.toString() : "0"}
              inputChange={(value) => {
                setFilter((prevState) => ({
                  ...prevState,
                  sportId: +value,
                }));
              }}
              label="Sport"
              placeholder="Select Sport"
              className="m-0"
            >
              {[...sports, ...[{ id: 0, name: "All" }]]}
            </FormFieldSelect>
          )}
        </div>
        <div className="max-w-60 min-w-60">
          <FormFieldSelect
            value={filter.regionId ? filter.regionId.toString() : "0"}
            inputChange={(value) => {
              setFilter((prevState) => ({
                ...prevState,
                regionId: +value,
              }));
            }}
            name="regionId"
            id="regionId"
            label="Region"
            placeholder="Select Region"
            className="m-0 "
          >
            {selectedRegions.length > 0
              ? [...[{ id: 0, name: "All" }], ...selectedRegions]
              : [...[{ id: 0, name: "None" }]]}
          </FormFieldSelect>
        </div>
      </div>
      {dataRules && (
        <BaseTable
          columns={columns}
          data={data}
        />
      )}
      {filter.pagination && (
        <div className="flex justify-end mt-4">
          <TablePagination
            page={filter.pagination.page || 0}
            pageCount={filter.pagination.pageCount || 0}
            onChange={handleChangePagination}
          />
        </div>
      )}
    </main>
  );
};

export default RulesReport;
