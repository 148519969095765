import Caption2 from "../../Text/Caption/Caption2";
import { Textarea } from "../../shadcn/textarea";
import { cn } from "../../../../lib/utils";
import React, { FC, HTMLProps } from "react";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  maxInput?: number;
  value: string;
  onChange: (data: any) => void;
  id: string;
  title: string;
  error?: boolean;
  assistiveText?: string;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      className,
      maxInput,
      value,
      id,
      title,
      onChange,
      assistiveText,
      ...props
    },
    ref
  ) => {
    return (
      <div className="flex flex-col w-full gap-1">
        <label
          htmlFor={id}
          className={`px-3 text-xs font-medium ${
            props.error && !props.disabled && "text-error-10"
          }`}
        >
          {title}
          {/* <Caption1 className="ml-2">{title}</Caption1> */}
        </label>
        <Textarea
          value={value}
          onChange={onChange}
          maxLength={maxInput}
          error={props.error}
          disabled={props.disabled}
        />
        {maxInput && (
          <div className="flex justify-between">
            <div
              className={`px-3 text-xxs font-normal ${
                props.error && !props.disabled ? "text-error-10" : "text-light"
              }`}
            >
              {assistiveText}
            </div>
            <Caption2 className={cn("self-end")}>
              {value.length}/{maxInput}
            </Caption2>
          </div>
        )}
      </div>
    );
  }
);
TextArea.displayName = "TextArea";

export default TextArea;
