import { createContext, useContext, useState } from "react";
import { DivisionByIdQuery } from "../generated/graphql";
import { Pagination } from "../types/types";

type StaffTypeContextType = {
  filter: {
    pagination: Pagination;
    regionId: number;
  };
  setFilter: React.Dispatch<
    React.SetStateAction<{
      pagination: Pagination;
      regionId: number;
    }>
  >;
};

const StaffTypeContextDefault: StaffTypeContextType = {
  filter: {
    pagination: {
      page: 0,
      pageCount: 0,
      pageSize: 10,
    },
    regionId: 0,
  },
  setFilter: () => {},
};

const StaffTypeContext = createContext<StaffTypeContextType>(
  StaffTypeContextDefault
);

export const StaffTypeContextProvider = ({ children }: any) => {
  // Basic filter add new id types here for more filtering
  const [filter, setFilter] = useState<{
    pagination: Pagination;
    regionId: number;
  }>({
    pagination: {
      page: 0,
      pageCount: 0,
      pageSize: 10,
    },
    regionId: 0,
  });

  const value = {
    filter,
    setFilter,
  };

  return (
    <StaffTypeContext.Provider value={value}>
      {children}
    </StaffTypeContext.Provider>
  );
};

export const useStaffTypeContext = () => useContext(StaffTypeContext);
