import { IconButton, Tooltip } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { useNavigate, useParams } from "react-router-dom";
import { getSports } from "../../app/venueMasterSlice";
import { displayAlertError, displayAlertSuccess } from "../../app/globalSlice";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import BreadCrumbs from "../UI/Breadcrumbs/Breadcrumbs";
import Subtitle1 from "../UI/Text/Subtitle/Subtitle1";
import Card from "../UI/Card/Card";
import FormField from "../UI/FormField/FormField";
import FormFieldDropdown from "../UI/FormField/FormFieldDropdown/FormFieldDropdown";
import Button from "../UI/Button/Button";
import TextArea from "../UI/FormField/TextArea/TextArea";
import { z } from "zod";
import {
  ListAllOperations,
  useLeagueCreateMutation,
  useLeagueQuery,
  useLeagueUpdateMutation,
  useSportFormatsLazyQuery,
  useVenuesLazyQuery,
} from "../../../src/generated/graphql";
import Alert from "../UI/Alerts/Alert";
import LoadingDialog from "../UI/Dialog/LoadingDialog";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { rootUrl } from "../../utils/environmentDependantVars";
import {
  DeleteOutlineOutlined,
  FileUploadOutlined,
  FmdGood,
} from "@mui/icons-material";
import LeagueImagesDialog from "../UI/Dialog/LeagueImagesDialog";
import ReactCrop, { Crop, PixelCrop } from "react-image-crop";

const sportFormatFilterSchema = z.object({
  sportId: z.number().optional(),
  regions: z.array(z.number()).optional(),
});

const venueFilterSchema = z.object({
  regionId: z.array(z.number().nullable()),
  parentId: z.number().nullable(),
});

const leagueVenueSchema = z.object({
  id: z.number().optional(),
  name: z.string(),
});

const leagueSchema = z
  .object({
    id: z.number().optional(),
    name: z.string().trim().min(1, { message: "Name is required" }),
    divisionName: z
      .string()
      .trim()
      .min(1, { message: "Division Name is required" }),
    gameDuration: z
      .number()
      .refine((data) => data > 0, { message: "Game Duration is required" }),
    gameTimeslot: z
      .number()
      .refine((data) => data > 0, { message: "Game Timeslot is required" }),
    gamesPerOccurrence: z.number().refine((data) => data > 0, {
      message: "Games Per Occurrence is required",
    }),
    leagueNotes: z.string(),
    sportFormatId: z.number().refine((data) => data !== 0, {
      message: "Sport Format is required",
    }),
    imageLink: z.string().optional(),
    venues: z.array(leagueVenueSchema).refine((data) => data.length > 0, {
      message: "A venue is required",
    }),
    sportId: z.number(),
    regionId: z.number(),
  })
  .refine(
    (data) => {
      return (
        data.gameDuration >= 0 &&
        data.gameTimeslot >= 0 &&
        data.gamesPerOccurrence >= 0 &&
        data.gameDuration <= data.gameTimeslot
      );
    },
    {
      message:
        "Invalide Game details, game duration must be less than or equal to game timeslot",
      path: ["gameDuration"],
    }
  );

type LeagueType = z.infer<typeof leagueSchema>;
type LeagueVenueType = z.infer<typeof leagueVenueSchema>;
type SportFormatFilterType = z.infer<typeof sportFormatFilterSchema>;
type VenueFilterType = z.infer<typeof venueFilterSchema>;

const initialLeague = {
  divisionName: "",
  gameDuration: 0,
  gameTimeslot: 0,
  gamesPerOccurrence: 1,
  leagueNotes: "",
  name: "",
  sportFormatId: 0,
  regions: 0,
  sportId: 0,
  regionId: 0,
  venues: [
    {
      id: 0,
      name: "",
    },
  ],
};

const sportFormatFilterInitial: any = {
  regions: null,
  sportId: null,
};

const venuesFilter: VenueFilterType = {
  regionId: [],
  parentId: null,
  // typeId: null, // Filter by typeId
};

const CreateLeague: React.FC = () => {
  const params = useParams();
  const dispatch = useDispatch<AppDispatch>();

  const {
    regions,
    selectedRegions,
    sports,
    // sportsFormat,
    isLoading,
  }: // venues,
  // league: leagueById,
  any = useSelector((state: RootState) => state.venueMaster);

  const navigate = useNavigate();
  const [league, setLeague] = React.useState<LeagueType>(initialLeague);
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({
    name: "",
    divisionName: "",
    gameDuration: "",
    gameTimeslot: "",
    gamesPerOccurrence: "",
    leagueNotes: "",
    sportFormatId: "",
    regionId: "",
    venues: "",
  });
  const [errorMessages, setErrorMessages] = React.useState<string[]>([]);
  const [sportFormatFilter, setSportFormatFilter] =
    React.useState<SportFormatFilterType>(sportFormatFilterInitial);
  const [sportFormatList, setSportFormatList] = React.useState<
    {
      id: number;
      name: string;
    }[]
  >([]);
  const [venues, setVenues] = React.useState<
    {
      id: number;
      name: string;
    }[]
  >([]);
  const [openLeagueImageDialog, setOpenLeagueImageDialog] =
    React.useState(false);

  const [getSportFormats] = useSportFormatsLazyQuery({
    fetchPolicy: "network-only",
  });
  const [getVenues] = useVenuesLazyQuery({
    fetchPolicy: "network-only",
  });
  const { loading: loadingLeague } = useLeagueQuery({
    fetchPolicy: "network-only",
    skip: params.id === undefined,
    variables: { leagueId: +params.id! },
    onCompleted: async (data) => {
      if (data.league) {
        const leagueData = data.league;
        if (
          leagueData.sportFormat.sport.id ||
          leagueData.sportFormat.region.id
        ) {
          await getSportsFormatList(
            +leagueData.sportFormat.sport.id,
            +leagueData.sportFormat.region.id
          );
          venuesFilter.regionId = [+leagueData.sportFormat.region.id];
          getVenues({
            variables: {
              venueFilters: venuesFilter,
            },
            onCompleted: (data) => {
              if (data.venues) {
                const venues = data.venues.map((venue) => {
                  return {
                    id: venue.id,
                    name: venue.name,
                  };
                });
                setVenues(venues);
              }
            },
          });
          setLeague({
            id: leagueData.id,
            name: leagueData.name,
            divisionName: leagueData.divisionName,
            gameDuration: leagueData.gameDuration,
            gameTimeslot: leagueData.gameTimeslot,
            gamesPerOccurrence: leagueData.gamesPerOccurrence,
            leagueNotes: leagueData.leagueNotes,
            sportFormatId: +leagueData.sportFormat.id,
            regionId: +leagueData.sportFormat.region.id,
            sportId: +leagueData.sportFormat.sport.id,
            venues: leagueData.venues.map((venue: any) => {
              return {
                id: +venue.id,
                name: venue.name,
              };
            }),
            imageLink: leagueData.imageLink || "",
          });
        }
      }
    },
  });

  const getSportsFormatList = async (sportId: number, regionId: number) => {
    await getSportFormats({
      variables: {
        sportFormatFilters: {
          regionId: regionId,
          sportId: sportId,
        },
      },
      onCompleted: (data) => {
        if (data.sportFormats) {
          const sportFormats = data.sportFormats.sportsFormat.map(
            (sportFormat) => {
              return {
                id: sportFormat.id,
                name: sportFormat.name,
              };
            }
          );
          setSportFormatList(sportFormats);
          setSportFormatFilter({
            regions: [regionId],
            sportId: sportId,
          });
        }
      },
    });
  };

  useEffect(() => {
    dispatch(getSports(""));
  }, []);

  const [CreateLeague, { loading: loadingCreate, error: errorCreate }] =
    useLeagueCreateMutation();
  const [
    UpdateLeague,
    { data: dataUpdate, loading: loadingUpdate, error: errorUpdate },
  ] = useLeagueUpdateMutation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const leagueUpdate = {
      ...league,
      [e.target.name]: +e.target.value || e.target.value,
    };
    setLeague(leagueUpdate);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const result = leagueSchema.safeParse(league);
    if (result.success) {
      const venuesList = league.venues
        .map((venue) => venue.id)
        .filter((venue): venue is NonNullable<typeof venue> => venue !== null);

      const leagueArgs = {
        divisionName: league.divisionName,
        gameDuration: league.gameDuration,
        gameTimeslot: league.gameTimeslot,
        gamesPerOccurrence: league.gamesPerOccurrence,
        leagueNotes: league.leagueNotes,
        name: league.name,
        sportFormatId: +league.sportFormatId,
        venues: venuesList,
        imageLink: league.imageLink,
      };
      if (league.id) {
        UpdateLeague({
          variables: {
            leagueArgs: { id: league.id, leagueInput: leagueArgs },
          },
          refetchQueries: [ListAllOperations.Query.League],
          onCompleted: (data) => {
            if (data.leagueUpdate.success) {
              dispatch(displayAlertSuccess(data.leagueUpdate.message));
              navigate(`/ops/leagues`);
            } else {
              dispatch(displayAlertError(data.leagueUpdate.message));
            }
          },
          onError: (error) => {
            dispatch(
              displayAlertError(
                `League: Something Went Wrong. Error: ${error.message}`
              )
            );
          },
        });
      } else {
        CreateLeague({
          variables: { leagueArgs: { leagueInput: leagueArgs } },
          refetchQueries: [ListAllOperations.Query.League],
          onCompleted: (data) => {
            if (data.leagueCreate.success) {
              dispatch(displayAlertSuccess(data.leagueCreate.message));
              navigate(`/ops/leagues`);
            } else {
              dispatch(displayAlertError(data.leagueCreate.message));
            }
          },
          onError: (error) => {
            dispatch(
              displayAlertError(
                `League: Something Went Wrong. Error: ${error.message}`
              )
            );
          },
        });
      }
    } else {
      const newFormErrors = { ...errors };
      const newFormErrorKeys = Object.keys(newFormErrors);
      let messages: string[] = [];
      newFormErrorKeys.forEach((field) => {
        const fieldError = result.error.errors.find(
          (error) => error.path[0] === field
        );
        if (fieldError) {
          newFormErrors[field] = fieldError.message; // Set the error message
          messages.push(`Error ${field}: ${fieldError.message}`);
        } else {
          newFormErrors[field] = ""; // No matching error, set to an empty string
        }
      });
      setErrorMessages(messages);
      setErrors(newFormErrors);
    }
  };

  if (loadingCreate || loadingUpdate || loadingLeague || isLoading) {
    return <LoadingDialog open={true} />;
  }

  const pathsBreadcrumbs = [
    { name: "Leagues", url: "/ops/leagues" },
    { name: "League Details", url: "/ops/leagues" },
  ];

  return (
    <main>
      <BreadCrumbs
        paths={pathsBreadcrumbs}
        goBackTo="/ops/leagues"
      ></BreadCrumbs>
      <form
        autoComplete="off"
        onSubmit={handleSubmit}
        className="flex flex-col gap-4 pb-16"
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Headline1Variable>
              {league.id ? "Edit League" : "Create New League"}
            </Headline1Variable>
            {league.id && (
              <Tooltip
                title="League Preview"
                arrow
              >
                <a
                  className="text-lg text-gray-500 cursor-pointer"
                  href={`${rootUrl}/program-discovery/league-details/${league.id}`}
                  target="_blank"
                >
                  <VisibilityIcon />
                </a>
              </Tooltip>
            )}
          </div>
          <Button
            variant="primary"
            type="submit"
            className="h-fit"
          >
            {league.id ? "Update League" : "Create League"}
          </Button>
        </div>
        {errorMessages.length > 0 && (
          <div className="flex flex-col gap-2">
            {errorMessages.map((errorMessage) => {
              return (
                <Alert
                  variant="error"
                  size="large"
                  content={errorMessage}
                  persist={true}
                />
              );
            })}
          </div>
        )}
        <Card>
          <Subtitle1>League Details</Subtitle1>
          <div className="flex flex-row w-full gap-4">
            <FormField
              initialValue={league.name}
              error={errors["name"] ? true : false}
              inputChange={handleChange}
              name="name"
              label="Name"
            ></FormField>
            <FormFieldDropdown
              name="regionId"
              initialValue={league?.regionId?.toString() ?? "0"}
              error={errors["regionId"] ? true : false}
              inputChange={(value) => {
                setLeague({ ...league, regionId: +value });
                getSportsFormatList(+league.sportId, +value);
              }}
              label="Region"
              placeholder="Select Region"
            >
              {[...selectedRegions, ...[{ id: "0", name: "Select Region" }]]}
            </FormFieldDropdown>
            <FormFieldDropdown
              name="sportId"
              initialValue={league?.sportId?.toString() ?? "0"}
              error={errors["sportId"] ? true : false}
              inputChange={(value) => {
                setLeague({ ...league, sportId: +value });
                getSportsFormatList(+value, +league.regionId);
              }}
              label="Sport"
              placeholder="Select Sport"
            >
              {[...sports, ...[{ id: "0", name: "Select Sport" }]]}
            </FormFieldDropdown>
            <FormFieldDropdown
              name="sportFormatId"
              initialValue={league?.sportFormatId?.toString()}
              error={errors["sportFormatId"] ? true : false}
              inputChange={(value) => {
                if (sportFormatFilter.regions) {
                  venuesFilter.regionId = sportFormatFilter.regions;
                  getVenues({
                    variables: {
                      venueFilters: venuesFilter,
                    },
                    onCompleted: (data) => {
                      if (data.venues) {
                        const venues = data.venues.map((venue) => {
                          return {
                            id: venue.id,
                            name: venue.name,
                          };
                        });
                        setVenues(venues);
                      }
                    },
                  });
                }
                if (!value) return;
                setLeague({ ...league, sportFormatId: +value });
              }}
              label="Sport Format"
              placeholder="Select Sport Format"
            >
              {[
                ...sportFormatList,
                ...[{ id: "0", name: "Select Sports Format" }],
              ]}
            </FormFieldDropdown>
            <FormField
              initialValue={league.divisionName}
              error={errors["divisionName"] ? true : false}
              inputChange={handleChange}
              name="divisionName"
              label="Division Template"
            ></FormField>
          </div>
        </Card>
        <Card>
          <Subtitle1>Game Details</Subtitle1>
          <div className="flex flex-row w-full gap-4">
            <FormField
              type="number"
              initialValue={league.gameDuration?.toString() || "0"}
              error={errors["gameDuration"] ? true : false}
              inputChange={handleChange}
              name="gameDuration"
              label="Game Duration"
            ></FormField>
            <FormField
              type="number"
              initialValue={league.gameTimeslot?.toString() || "0"}
              error={errors["gameTimeslot"] ? true : false}
              inputChange={handleChange}
              name="gameTimeslot"
              label="Game Timeslot"
            ></FormField>
            <FormField
              type="number"
              initialValue={league.gamesPerOccurrence?.toString()}
              error={errors["gamesPerOccurrence"] ? true : false}
              inputChange={handleChange}
              name="gamesPerOccurence"
              label="Games Per Week"
            ></FormField>
          </div>
        </Card>
        <Card>
          <Subtitle1>League Notes</Subtitle1>
          <TextArea
            className="mb-4"
            name="leagueNotes"
            label=""
            inputChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setLeague({ ...league, leagueNotes: event.target.value });
            }}
            value={league.leagueNotes}
            initialValue={league.leagueNotes ?? ""}
            rows={8}
            cols={50}
            error={errors["leagueNotes"] ? true : false}
          ></TextArea>
        </Card>
        <Card>
          <Subtitle1>League Image</Subtitle1>
          <div className="flex flex-row gap-4 lg:w-1/4 md:1/3">
            {league.imageLink && (
              <img
                className="mr-2 rounded-sm bg-neutral-80"
                src={league.imageLink}
                alt="image"
                width={200}
              />
            )}
            {!league.imageLink && (
              <div className="w-[120px] h-[120px] bg-gray-300 rounded-2xl flex items-center justify-center">
                <FmdGood fontSize="large" />
              </div>
            )}
            <div className="flex flex-col self-center gap-2">
              <Button
                variant="secondary"
                onClick={() => {
                  setOpenLeagueImageDialog(true);
                }}
                type="button"
                // disabled={!!facilitySetups.isDeleted}
              >
                <div className="flex gap-2">
                  <FileUploadOutlined />
                  <div className="text-center">Select Image</div>
                </div>
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  setLeague({ ...league, imageLink: "" });
                }}
                type="button"
              >
                <div className="flex font-normal">
                  <div className="mr-1">
                    <DeleteOutlineOutlined />
                  </div>
                  Remove
                </div>
              </Button>
            </div>
          </div>
        </Card>
        <Card>
          <Subtitle1>League Venues</Subtitle1>
          <div className="flex flex-col gap-4">
            {league.venues?.map((venue: any, i: number) => {
              return (
                <div
                  key={venue.id}
                  className="flex flex-row gap-4 max-w-72"
                >
                  <FormFieldDropdown
                    name="venues"
                    initialValue={venue.id?.toString()}
                    inputChange={(value) => {
                      const newVenueClone = [...league.venues];
                      newVenueClone[i] = {
                        id: +value,
                        name: venues.find((v) => v.id === value)?.name || "",
                      };
                      setLeague({ ...league, venues: newVenueClone });
                    }}
                    label=""
                    placeholder="Select Venue"
                  >
                    {[...venues, { id: "0", name: "Select Venue" }]}
                  </FormFieldDropdown>
                  {league.venues.length > 1 && (
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        const newVenueClone = [...league.venues];
                        newVenueClone.splice(i, 1);
                        setLeague({ ...league, venues: newVenueClone });
                      }}
                    >
                      <HighlightOffRoundedIcon />
                    </IconButton>
                  )}
                </div>
              );
            })}
            <Button
              variant="secondary"
              className="max-w-40"
              type="button"
              onClick={() => {
                setLeague({
                  ...league,
                  venues: [...league.venues, { id: 0, name: "" }],
                });
              }}
            >
              Add Venue
            </Button>
          </div>
        </Card>
        <div className="flex flex-row gap-4">
          <Button
            variant="primary"
            type="submit"
          >
            {league.id ? "Update League" : "Create League"}
          </Button>
          <Button
            variant="secondary"
            href="/ops/leagues"
          >
            Cancel
          </Button>
        </div>
      </form>
      <LeagueImagesDialog
        open={openLeagueImageDialog}
        onOpenChange={(value) => {
          setOpenLeagueImageDialog(value);
        }}
        handleApplyImage={(imageUrl) => {
          setLeague({ ...league, imageLink: imageUrl });
        }}
      />
    </main>
  );
};

export default CreateLeague;
