import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/src/app/store";
import { useParams } from "react-router-dom";
import { getRegistrationBatch, getSports } from "../../../app/venueMasterSlice";
import ReactQuill from "react-quill";
import CheckBox from "../../UI/Checkbox/Checkbox";
import Button from "../../UI/Button/Button";
import Body1 from "../../UI/Text/Body/Body1";
import Headline2Variable from "../../UI/Text/Headline/Headline2Variable";
import { IconButton } from "@mui/material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { header } from "./Headers";
import { footer } from "./Footers";
import { emailButton } from "./EmailButton";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import FormFieldControlled from "../../UI/FormField/FormFieldControlled";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import { z } from "zod";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../UI/shadcn/dialog";
import {
  displayAlertError,
  displayAlertSuccess,
  displayAlertWarning,
} from "../../../app/globalSlice";
import { Separator } from "../../UI/shadcn/separator";
import {
  SessionsQuery,
  useGetEmailTemplatesQuery,
  useGetNumberOfPlayersOnTeamsLazyQuery,
  useRegistrationCountByProductAndTypeQuery,
  useSendSessionEmailMutation,
  useSessionForSessionEmailToolQuery,
  useSessionQuery,
  useSessionsQuery,
  useTeamPlayerCountBySessionIdQuery,
} from "../../../generated/graphql";
import { emailHyperLink } from "./EmailHyperLink";
import { rootUrl } from "../../../utils/environmentDependantVars";
import { FormFieldSelectMulti } from "../../UI/FormField/FormFieldSelectMulti/FormFieldSelectMulti";
import { dayOfWeek } from "../../../utils/dayOfWeek";
import Subtitle1 from "../../UI/Text/Subtitle/Subtitle1";
import Alert from "../../UI/Alerts/Alert";

const EmailTemplateSchema = z.object({
  id: z.number().optional(),
  subject: z.string().min(1, "Please enter a valid subject"),
  name: z.string(),
  body: z.string().refine(
    (data) => {
      return data !== "<p><br></p>";
    },
    { message: "Please enter a valid body" }
  ),
});
type EmailTemplate = z.infer<typeof EmailTemplateSchema>;

const EmailSessionFilterSchema = z.object({
  regionId: z.number(),
  sportId: z.number(),
  registrationBatchId: z.number(),
  day: z.number(),
});
type EmailSessionFilter = z.infer<typeof EmailSessionFilterSchema>;

const emptyEmailTemplate = {
  id: 0,
  subject: "",
  name: "",
  body: "<p><br></p>",
};

const EmailToolSessions = () => {
  const params = useParams();
  const sessionIdParam = params.id;

  const dispatch = useDispatch<AppDispatch>();
  const { selectedRegions, sports, registrationBatches }: any = useSelector(
    (state: RootState) => state.venueMaster
  );

  const [emailBody, setEmailBody] = useState<EmailTemplate>(emptyEmailTemplate);
  const [leagueRuleOption, setLeagueRuleOption] = useState<number>(0);
  const [sessionId, setSessionId] = useState<number>(
    sessionIdParam ? +sessionIdParam : 0
  );
  const [sessions, setSessions] = useState<SessionsQuery["sessions"]>([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [numberOfPlayers, setNumberOfPlayers] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [sessionFilters, setSessionFilters] = useState<EmailSessionFilter>({
    regionId: 0,
    day: 7,
    registrationBatchId: 0,
    sportId: 0,
  });
  const [sendOption, setSendOption] = useState<number>(0);

  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  /*** QUERIES ***/
  const { data: dataTemplates, loading: loadingTemplates } =
    useGetEmailTemplatesQuery({
      variables: {
        typeId: 3,
      },
    });

  const { data, loading, error } = useSessionsQuery({
    variables: {
      sessionFilters: {
        regions:
          sessionFilters.regionId !== 0 ? [sessionFilters.regionId] : undefined,
        registrationBatchId: sessionFilters.registrationBatchId,
        sportId: sessionFilters.sportId,
        dayOfWeek: sessionFilters.day,
      },
    },
    nextFetchPolicy: "cache-first",
    onCompleted(data) {
      setSessions(data.sessions);
      if (
        data.sessions.findIndex((session) => session.id === sessionId) === -1
      ) {
        setSessionId(0);
      }
    },
  });

  const { data: sessionById } = useSessionForSessionEmailToolQuery({
    variables: {
      sessionId: sessionId,
    },
    skip: sessionId === 0,
    onCompleted: (data) => {
      if (data.session) {
        setSessionFilters((prevState) => ({
          ...prevState,
          regionId: data.session.league.sportFormat.region.id,
          day: data.session.dayOfWeek,
          registrationBatchId: data.session.registrationBatch.id,
          sportId: +data.session.league.sportFormat.sport.id,
        }));
      }
    },
  });

  const {} = useRegistrationCountByProductAndTypeQuery({
    variables: {
      productId: sessionId,
      registrationTypeId: sendOption === 3 ? undefined : sendOption,
    },
    skip: sendOption === 4 || sendOption === 0,
    onCompleted: (data) => {
      setNumberOfPlayers(data.registrationCountByProductAndType);
    },
  });
  const {} = useTeamPlayerCountBySessionIdQuery({
    variables: {
      sessionId: sessionId,
    },
    skip: sendOption !== 4,
    onCompleted: (data) => {
      setNumberOfPlayers(data.teamPlayerCountBySessionId);
    },
  });

  /*** MUTATIONS ***/
  const [sendSessionEmail] = useSendSessionEmailMutation();

  useEffect(() => {
    dispatch(getSports({}));
    dispatch(getRegistrationBatch({}));
  }, []);

  useEffect(() => {
    if (sessionById == null) {
      return;
    }
    const countryCode =
      sessionById.session?.league?.sportFormat?.region?.country?.countryCode ||
      "CA";
    const ruleId = sessionById.session?.league?.sportFormat?.ruleId || 0;
    const region = sessionById.session?.league?.sportFormat.region;
    if (iframeRef.current && emailBody !== undefined) {
      const formattedBody = `${header}${emailBody.body
        .replace(
          /%%button_(https:\/\/[a-zA-Z0-9._%+-\/]+)_([a-zA-Z0-9\s._%+-]+)%%/g,
          (_, link, text) => {
            return emailButton(link, text);
          }
        )
        .replace(/%%ruleButton_([a-zA-Z0-9\s._%+-]+)%%/g, (_, text) => {
          return emailButton(`${rootUrl}/rules/${ruleId}`, text);
        })
        .replace(/%%ruleText_([a-zA-Z0-9\s._%+-]+)%%/g, (_, text) => {
          return emailHyperLink(`${rootUrl}/rules/${ruleId}`, text);
        })}${footer({
        country: countryCode,
        email: region.email,
        address: region.address,
        websiteLink: region.websiteLink,
      })}`;
      const blob = new Blob([formattedBody], { type: "text/html" });
      iframeRef.current.src = URL.createObjectURL(blob);
    }
  }, [emailBody, sessionById]);

  async function handleSendEmail() {
    const result = EmailTemplateSchema.safeParse(emailBody);
    if (result.success) {
      if (sendOption >= 1 && sendOption <= 4) {
        setOpenConfirmDialog(true);
      } else {
        dispatch(displayAlertError(`Select a sending option.`));
      }
    } else {
      setOpenConfirmDialog(false);
      dispatch(
        displayAlertError(
          `${result.error.issues.map((issue) => issue.message).join(",\n")}`
        )
      );
    }
  }

  async function handleSendEmailToSelf() {
    const result = EmailTemplateSchema.safeParse(emailBody);
    if (result.success) {
      await sendEmailToSelf();
    } else {
      setOpenConfirmDialog(false);
      dispatch(
        displayAlertError(
          `${result.error.issues.map((issue) => issue.message).join(",\n")}`
        )
      );
    }
  }

  const sendEmail = async () => {
    if (sessionId) {
      sendSessionEmail({
        variables: {
          sessionId: +sessionId,
          subject: emailBody.subject,
          body: emailBody.body,
          sendOptionType: sendOption,
        },
        onCompleted(data, clientOptions) {
          if (data.sendSessionEmail) {
            if (data.sendSessionEmail.success) {
              dispatch(displayAlertSuccess(data.sendSessionEmail.message));
            } else {
              dispatch(displayAlertWarning(data.sendSessionEmail.message));
              setErrorMessage(data.sendSessionEmail.message);
            }
          } else {
            dispatch(displayAlertSuccess("Something went wrong sending"));
          }
        },
        onError(error, clientOptions) {
          dispatch(displayAlertError(error.message));
        },
      });
    }
  };

  const sendEmailToSelf = async () => {
    if (sessionId) {
      sendSessionEmail({
        variables: {
          sessionId: +sessionId,
          subject: emailBody.subject,
          body: emailBody.body,
          sendOptionType: sendOption,
          sendToSelf: true,
        },
        onCompleted(data, clientOptions) {
          if (data.sendSessionEmail) {
            if (data.sendSessionEmail.success) {
              dispatch(displayAlertSuccess(data.sendSessionEmail.message));
            } else {
              dispatch(displayAlertWarning(data.sendSessionEmail.message));
              setErrorMessage(data.sendSessionEmail.message);
            }
          } else {
            dispatch(displayAlertSuccess("Something went wrong sending"));
          }
        },
        onError(error, clientOptions) {
          dispatch(displayAlertError(error.message));
        },
      });
    }
  };

  return (
    <main className="flex flex-col gap-4">
      <Headline1Variable>Session Emails</Headline1Variable>

      <Alert
        variant="warning"
        size="large"
        persist={false}
        content={errorMessage}
        open={errorMessage !== ""}
        setOpen={(val) => {
          console.log(val);
          if (!val) {
            setErrorMessage("");
          }
        }}
      />
      <div className="flex flex-row items-center justify-start gap-2 mr-5">
        <FormFieldSelect
          name="regionId"
          value={sessionFilters.regionId?.toString() || "0"}
          inputChange={(value) => {
            setSessionFilters((prevState) => ({
              ...prevState,
              regionId: +value,
            }));
          }}
          label="Region"
          placeholder="Select Region"
        >
          {[{ id: "0", name: "All" }, ...selectedRegions]}
        </FormFieldSelect>
        <FormFieldSelect
          name="sportId"
          value={sessionFilters.sportId?.toString() || "0"}
          inputChange={(value) => {
            setSessionFilters((prevState) => ({
              ...prevState,
              sportId: +value,
            }));
          }}
          label="Sport"
          placeholder="Select Sport"
        >
          {[{ id: "0", name: "All" }, ...sports]}
        </FormFieldSelect>
        <FormFieldSelect
          name="registrationBatchId"
          value={sessionFilters.registrationBatchId?.toString() || "0"}
          inputChange={(value) => {
            setSessionFilters((prevState) => ({
              ...prevState,
              registrationBatchId: +value,
            }));
          }}
          label="Registration Batch"
          placeholder="Select Registration Batch"
        >
          {[{ id: "0", name: "All" }, ...registrationBatches]}
        </FormFieldSelect>
        <FormFieldSelect
          inputChange={(value: string) => {
            setSessionFilters((prevState) => ({
              ...prevState,
              day: +value,
            }));
          }}
          label="Day Of Week"
          placeholder="Day Of Week"
          value={sessionFilters.day?.toString() || "7"}
        >
          {[
            { id: 7, name: "All" },
            { id: 0, name: "Sunday" },
            { id: 1, name: "Monday" },
            { id: 2, name: "Tuesday" },
            { id: 3, name: "Wednesday" },
            { id: 4, name: "Thursday" },
            { id: 5, name: "Friday" },
            { id: 6, name: "Saturday" },
          ]}
        </FormFieldSelect>
      </div>
      <div className="flex flex-row items-center justify-start gap-2">
        <div className="max-w-80 w-80">
          <FormFieldSelect
            name="templateSelect"
            value={emailBody?.id?.toString() ?? "0"}
            inputChange={(value) => {
              if (value === "0" || dataTemplates === undefined) {
                setEmailBody(emptyEmailTemplate);
              } else {
                const newEmail = dataTemplates.getEmailTemplates.find(
                  (template: EmailTemplate) => template.id === +value
                );
                if (newEmail) {
                  setEmailBody(newEmail);
                }
              }
            }}
            loading={loadingTemplates}
            label="Template"
            placeholder="Select Email Temaplte"
          >
            {[
              { id: 0, name: "Select a Template" },
              ...(dataTemplates === undefined
                ? []
                : dataTemplates.getEmailTemplates.map(
                    (template: EmailTemplate) => {
                      return { id: template.id, name: template.name };
                    }
                  )),
            ]}
          </FormFieldSelect>
        </div>
        <div className="max-w-80 w-80">
          <FormFieldSelect
            name="sessionSelect"
            value={sessionId?.toString() ?? "0"}
            inputChange={(value) => {
              setSessionId(+value);
            }}
            loading={loading}
            label="Session"
            placeholder="Select Session"
          >
            {[
              { id: 0, name: "Select a Session" },
              ...sessions.map((session) => {
                return {
                  id: session.id.toString(),
                  name: `${dayOfWeek[session.dayOfWeek].name} - ${
                    session.league.name
                  }`,
                };
              }),
            ]}
          </FormFieldSelect>
        </div>
      </div>
      {sessionId ? (
        <div className="flex flex-col gap-2">
          <Subtitle1>Sending Options</Subtitle1>
          <div className="grid grid-cols-2 gap-2 w-fit">
            <CheckBox
              label="All FA Registrants"
              defaultChecked={false}
              checkedState={sendOption === 1}
              inputChange={(val) => {
                if (val) {
                  setSendOption(1);
                } else {
                  setSendOption(0);
                }
              }}
              id="all-fa-registrants"
            />
            <CheckBox
              label="All Team Registrants"
              defaultChecked={false}
              checkedState={sendOption === 2}
              inputChange={(val) => {
                if (val) {
                  setSendOption(2);
                } else {
                  setSendOption(0);
                }
              }}
              id="all-team-registrants"
            />
            <CheckBox
              label="All Registrants"
              defaultChecked={false}
              checkedState={sendOption === 3}
              inputChange={(val) => {
                if (val) {
                  setSendOption(3);
                } else {
                  setSendOption(0);
                }
              }}
              id="all-registrants"
            />
            <CheckBox
              label="All Player Records"
              defaultChecked={false}
              checkedState={sendOption === 4}
              inputChange={(val) => {
                if (val) {
                  setSendOption(4);
                } else {
                  setSendOption(0);
                }
              }}
              id="all-player-records"
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="flex flex-row justify-between w-full gap-4">
        <div className="flex flex-col w-1/2 gap-4">
          <div>
            <FormFieldControlled
              onChange={(value) => {
                setEmailBody({
                  ...emailBody,
                  subject: value.target.value,
                });
              }}
              value={emailBody.subject}
              label="Subject"
            />
          </div>
          <div className="mb-[46px]">
            <ReactQuill
              theme="snow"
              value={emailBody.body}
              onChange={(text: string) =>
                setEmailBody((prev) => ({
                  ...prev,
                  body: text,
                }))
              }
              style={{ height: "400px", width: "100%" }}
            />
          </div>
          <div className="flex gap-4">
            <Button
              variant={sessionId ? "secondary" : "disabled"}
              disabled={!sessionId}
              onClick={() => handleSendEmail()}
            >
              Send
            </Button>
            <Button
              variant={sessionId ? "primary" : "disabled"}
              disabled={!sessionId}
              onClick={() => handleSendEmailToSelf()}
            >
              Send To Self
            </Button>
          </div>
        </div>
        <div className="flex flex-col w-1/2 gap-4 ">
          <div className="flex flex-col">
            <Headline2Variable>
              Email Variables (click to copy)
            </Headline2Variable>
            {/** Email Variables */}
            <div className="flex flex-col gap-2">
              <div className="flex flex-row gap-2 w-[360px]">
                <Body1 className="w-32">First Name:</Body1>
                <IconButton
                  sx={{
                    "&:hover": { color: "var(--primary-80)" },
                    height: "24px",
                    width: "24px",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText("%%first_name%%");
                  }}
                >
                  <ContentCopyOutlinedIcon />
                </IconButton>
                <Body1 className="w-fit bg-info-90">%%first_name%%</Body1>
              </div>
              <div className="flex flex-row gap-2 w-[360px]">
                <Body1 className="w-32">Day of week:</Body1>
                <IconButton
                  sx={{
                    "&:hover": { color: "var(--primary-80)" },
                    height: "24px",
                    width: "24px",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText("%%day_of_week%%");
                  }}
                >
                  <ContentCopyOutlinedIcon />
                </IconButton>
                <Body1 className="w-fit bg-info-90">%%day_of_week%%</Body1>
              </div>
              <div className="flex flex-row gap-2 w-[360px]">
                <Body1 className="w-32">Sport:</Body1>
                <IconButton
                  sx={{
                    "&:hover": { color: "var(--primary-80)" },
                    height: "24px",
                    width: "24px",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText("%%sport%%");
                  }}
                >
                  <ContentCopyOutlinedIcon />
                </IconButton>
                <Body1 className="w-fit bg-info-90">%%sport%%</Body1>
              </div>
              <div className="flex flex-row gap-2 w-[360px]">
                <Body1 className="w-32">League Name:</Body1>
                <IconButton
                  sx={{
                    "&:hover": { color: "var(--primary-80)" },
                    height: "24px",
                    width: "24px",
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText("%%league_name%%");
                  }}
                >
                  <ContentCopyOutlinedIcon />
                </IconButton>
                <Body1 className="w-fit bg-info-90">%%league_name%%</Body1>
              </div>
              <div className="flex flex-row items-center">
                <div className="flex flex-row gap-2 min-w-[360px]">
                  <Body1 className="min-w-32 whitespace-nowrap">
                    League Rules:
                  </Body1>
                  <IconButton
                    sx={{
                      "&:hover": { color: "var(--primary-80)" },
                      height: "24px",
                      width: "24px",
                    }}
                    onClick={() => {
                      switch (leagueRuleOption) {
                        case 0: {
                          navigator.clipboard.writeText("%%ruleLink%%");
                          break;
                        }
                        case 1: {
                          navigator.clipboard.writeText("%%ruleText_text%%");
                          break;
                        }
                        case 2:
                          {
                            navigator.clipboard.writeText(
                              "%%ruleButton_text%%"
                            );
                          }
                          break;
                        default: {
                          navigator.clipboard.writeText("%%ruleLink%%");
                          break;
                        }
                      }
                    }}
                  >
                    <ContentCopyOutlinedIcon />
                  </IconButton>
                  <Body1 className="w-fit bg-info-90 h-fit">
                    {(() => {
                      switch (leagueRuleOption) {
                        case 0:
                          return "%%ruleLink%%";
                        case 1:
                          return "%%ruleText_text%%";
                        case 2:
                          return "%%ruleButton_text%%";
                        default:
                          return "%%ruleLink%%";
                      }
                    })()}
                  </Body1>
                </div>
                <FormFieldSelect
                  className="w-32 h-fit"
                  name="leagueRuleOption"
                  label=""
                  placeholder="Select "
                  value={leagueRuleOption?.toString() ?? "0"}
                  inputChange={(value: string) => {
                    setLeagueRuleOption(+value);
                  }}
                >
                  {[
                    { id: "0", name: "Link" },
                    { id: "1", name: "Link with text" },
                    { id: "2", name: "Button" },
                  ]}
                </FormFieldSelect>
              </div>

              <div className="flex flex-col gap-1">
                <div className="flex flex-row gap-2 w-[360px]">
                  <Body1 className="w-32">Button:</Body1>
                  <IconButton
                    sx={{
                      "&:hover": { color: "var(--primary-80)" },
                      height: "24px",
                      width: "24px",
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText("%%button_link_text%%");
                    }}
                  >
                    <ContentCopyOutlinedIcon />
                  </IconButton>
                  <Body1 className="w-fit bg-info-90">
                    %%button_link_text%%
                  </Body1>
                </div>
                <Body1>%%button_https://jamsports.com_Home%%</Body1>
              </div>
            </div>
          </div>
          <div className="max-w-[600px]">
            <iframe
              ref={iframeRef}
              title="Email Preview"
              style={{ width: "100%", height: "600px", border: "none" }}
            />
          </div>
        </div>
      </div>
      <Dialog
        open={openConfirmDialog}
        onOpenChange={setOpenConfirmDialog}
      >
        <DialogContent
          className="max-w-80"
          onAbort={() => console.log("nope")}
          onDragExit={() => console.log("exit")}
        >
          <DialogHeader>
            <DialogTitle>Confirm Session Email</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            <div className="flex flex-col">
              <Body1>
                This will send an email to {numberOfPlayers} people.
              </Body1>
            </div>
          </DialogDescription>
          <div className="flex flex-row gap-2">
            <DialogClose asChild>
              <Button
                className="w-full"
                variant={"primary"}
                onClick={() => {
                  setOpenConfirmDialog(false);
                  sendEmail();
                }}
              >
                Confirm
              </Button>
            </DialogClose>
            <DialogClose asChild>
              <Button
                className="w-full"
                variant="negative"
              >
                Cancel
              </Button>
            </DialogClose>
          </div>
        </DialogContent>
      </Dialog>
    </main>
  );
};

export default EmailToolSessions;
