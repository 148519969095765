import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Modal,
  Pagination,
  Radio,
  RadioGroup,
} from "@mui/material";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "../UI/Table/DataTable";
import dayjs from "dayjs";
import LoadingDialog from "../UI/Dialog/LoadingDialog";
import {
  GET_GAMES_SCORE_HISTORY,
  GET_GAMES_WITH_ADMIN_OVERRIDE_SCORES,
  GET_GAMES_WITH_FINAL_SCORES,
  GET_GAMES_WITH_NO_SCORES,
  GET_GAMES_WITH_PENDING_SCORES,
  GET_GAMES_WITH_SYSTEM_GENERATED_SCORES,
} from "../../graphql/queries/games";
import { z } from "zod";
import Caption1 from "../UI/Text/Caption/Caption1";
import { DatePicker } from "../UI/shadcn/Time/date-picker";
import Headline2Variable from "../UI/Text/Headline/Headline2Variable";
import {
  APPROVE_SCORE,
  CREATE_SCORE,
  RESET_SCORE,
} from "../../graphql/queries/scores";
import { useToast } from "../UI/Toast/use-toast";
import ActionsCell from "../UI/ActionCell";
import Subtitle1 from "../UI/Text/Subtitle/Subtitle1";
import FormField from "../UI/FormField/FormField";
import Button from "../UI/Button/Button";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/src/app/store";
import {
  getLeagues,
  getScoreStatuses,
  getSports,
} from "../../app/venueMasterSlice";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../UI/shadcn/select";
import { PaginationType } from "../../types/types";
import { LoaderCircle } from "lucide-react";
import { displayAlertError, displayAlertSuccess } from "../../app/globalSlice";
import Body1 from "../UI/Text/Body/Body1";

const weekdaysArray: any = [
  { id: 0, name: "Sunday" },
  { id: 1, name: "Monday" },
  { id: 2, name: "Tuesday" },
  { id: 3, name: "Wednesday" },
  { id: 4, name: "Thursday" },
  { id: 5, name: "Friday" },
  { id: 6, name: "Saturday" },
];

type Team = {
  id: string;
  name: string;
};

type GameData = {
  id: string;
  homeTeam: Team | null;
  awayTeam: Team | null;
  startDateTimeLocal: string;
  latestScore?: LatestScore;
  venue: { name: string };
};

type ScoreStatus = {
  name: string;
  id: string;
};

type LatestScore = {
  id: number;
  homeScore: number;
  awayScore: number;
  scoreStatus: ScoreStatus;
  submittingTeamId: number;
  submittingUserId: string;
  submittingUser: {
    id: string;
    firstName: string;
    lastName: string;
  };
  dateSubmitted: string;
  dateConfirmed: string;
  submittingTeam: {
    id: string;
    name: string;
  };
  confirmedByUser: {
    id: string;
    firstName: string;
    lastName: string;
  };
};

type GameScoreReport = {
  gamesWithAllStatus: GameData[];
  gamesWithNoScores: GameData[];
  gamesWithPendingScores: GameData[];
  gamesWithFinalScores: GameData[];
  gamesWithSystemGeneratedScores: GameData[];
  gamesWithAdminOverrideScores: GameData[];
};

type NewScore = {
  id: number;
  homeScore: number;
  awayScore: number;
  isWinByDefault: boolean;
};

interface ScoresInput {
  homeTeamScore: number;
  awayTeamScore: number;
}
interface RecordForfeit {
  team: string;
  reason: string;
}

const GameScoreReportFilterSchema = z
  .object({
    startDate: z.date().optional(),
    endDate: z.date().optional(),
    leagueId: z.number().optional(),
    dayOfWeek: z.number().optional(),
    regionId: z.number().optional(),
    sportId: z.number().optional(),
    gameId: z.number().optional(),
    status: z.number().default(2),
  })
  .refine(
    (data) => {
      return (
        data.endDate !== undefined &&
        data.startDate !== undefined &&
        data.endDate > data.startDate
      );
    },
    { message: "End date must occur after start date" }
  );

type GameScoreReportType = z.infer<typeof GameScoreReportFilterSchema>;

const GameScoreReport: React.FC = () => {
  const { selectedRegions, sports, leagues, scoreStatuses }: any = useSelector(
    (state: RootState) => state.venueMaster
  );
  const dispatch = useDispatch<AppDispatch>();

  const [reportedScores, setReportedScores] = useState<LatestScore[]>([]);
  const [reportedScoresModal, setReportedScoresModal] =
    useState<boolean>(false);
  const [newScoreModal, setNewScoreModal] = useState<boolean>(false);
  const [gameInfoData, setGameInfoData] = useState<GameData>();
  const [scores, setScores] = useState<ScoresInput>({
    homeTeamScore: 0,
    awayTeamScore: 0,
  });
  const [gameScoreReportFilter, setGameScoreReportFilter] =
    useState<GameScoreReportType>({
      startDate: dayjs().subtract(1, "week").toDate(),
      endDate: new Date(),
      status: 2,
    });
  const [gameScoreReportData, setGameScoreReportData] =
    useState<GameScoreReport>({
      gamesWithAllStatus: [],
      gamesWithNoScores: [],
      gamesWithPendingScores: [],
      gamesWithFinalScores: [],
      gamesWithSystemGeneratedScores: [],
      gamesWithAdminOverrideScores: [],
    });
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    pageSize: 50,
    pageCount: undefined,
  });
  const [resetScoreModal, setResetScoreModal] = useState<boolean>(false);
  const [resetScoreId, setResetScoreId] = useState<number>(0);
  const [reportDefaultModal, setReportDefaultModal] = useState<boolean>(false);
  const [recordForfeit, setRecordForfeit] = useState<RecordForfeit>({
    team: "homeTeam",
    reason: "1",
  });

  const [
    getGamesWithFinalScores,
    {
      data: finalScoreData,
      loading: finalScoreLoading,
      error: finalScoreError,
    },
  ] = useLazyQuery(GET_GAMES_WITH_FINAL_SCORES, {
    fetchPolicy: "no-cache",
  });

  const [
    getGamesWithPendingScores,
    {
      data: pendingScoreData,
      loading: pendingScoreLoading,
      error: pendingScoreError,
    },
  ] = useLazyQuery(GET_GAMES_WITH_PENDING_SCORES, {
    fetchPolicy: "no-cache",
  });
  const [
    getGamesWithNoScores,
    { data: noScoreData, loading: noScoreLoading, error: noScoreError },
  ] = useLazyQuery(GET_GAMES_WITH_NO_SCORES, {
    fetchPolicy: "no-cache",
  });
  const [
    getGamesWithSystemGeneratedScores,
    {
      data: systemGeneratedScoreData,
      loading: systemGeneratedScoreLoading,
      error: systemGeneratedScoreError,
    },
  ] = useLazyQuery(GET_GAMES_WITH_SYSTEM_GENERATED_SCORES, {
    fetchPolicy: "no-cache",
  });
  const [
    getGamesWithAdminOverrideScores,
    {
      data: adminOverrideScoreData,
      loading: adminOverrideScoreLoading,
      error: adminOverrideScoreError,
    },
  ] = useLazyQuery(GET_GAMES_WITH_ADMIN_OVERRIDE_SCORES, {
    fetchPolicy: "no-cache",
  });

  const [
    getGamesScoreHistory,
    {
      data: scoreHistoryData,
      loading: scoreHistoryLoading,
      error: scoreHistoryError,
    },
  ] = useLazyQuery(GET_GAMES_SCORE_HISTORY, {
    fetchPolicy: "no-cache",
  });

  const [approveScore] = useMutation(APPROVE_SCORE, {
    onCompleted: async (approveScoreData) => {
      await handleGetScoreData();
      if (approveScoreData.approveScore.success) {
        dispatch(displayAlertSuccess(approveScoreData.approveScore.message));
      } else {
        dispatch(displayAlertError(approveScoreData.approveScore.message));
      }
    },
  });

  const [CreateScore] = useMutation(CREATE_SCORE, {
    onCompleted: (scoreData) => {
      handleGetScoreData();
      if (scoreData.scoreCreate.success) {
        dispatch(displayAlertSuccess(scoreData.scoreCreate.message));
      } else {
        dispatch(displayAlertError(scoreData.scoreCreate.message));
      }
    },
  });

  const [ResetScore] = useMutation(RESET_SCORE, {
    onCompleted: (scoreData) => {
      handleGetScoreData();
      if (scoreData.scoreReset.success) {
        dispatch(displayAlertSuccess(scoreData.scoreReset.message));
      } else {
        dispatch(displayAlertError(scoreData.scoreReset.message));
      }
    },
  });

  useEffect(() => {
    if (gameScoreReportFilter.status === 0) {
      setPagination((prevState) => ({
        ...prevState,
        pageCount:
          pendingScoreData.getGamesWithPendingScores.count +
          finalScoreData.getGamesWithFinalScores.count +
          noScoreData.getGamesWithNoScores.count +
          systemGeneratedScoreData.getGamesWithSystemGeneratedScores.count,
      }));
      setGameScoreReportData((prevState) => ({
        ...prevState,
        gamesWithAllStatus: [
          ...pendingScoreData.getGamesWithPendingScores.gameReportData,
          ...finalScoreData.getGamesWithFinalScores.gameReportData,
          ...noScoreData.getGamesWithNoScores.gameReportData,
          ...systemGeneratedScoreData.getGamesWithSystemGeneratedScores
            .gameReportData,
        ],
        gamesWithFinalScores: [],
        gamesWithPendingScores: [],
        gamesWithNoScores: [],
        gamesWithAdminOverrideScores: [],
        gamesWithSystemGeneratedScores: [],
      }));
    }
    if (finalScoreData && gameScoreReportFilter.status === 1) {
      setPagination((prevState) => ({
        ...prevState,
        pageCount: finalScoreData.getGamesWithFinalScores.count,
      }));
      setGameScoreReportData((prevState) => ({
        ...prevState,
        gamesWithFinalScores:
          finalScoreData.getGamesWithFinalScores.gameReportData,
        gamesWithPendingScores: [],
        gamesWithNoScores: [],
        gamesWithAdminOverrideScores: [],
        gamesWithSystemGeneratedScores: [],
      }));
    }
    if (pendingScoreData && gameScoreReportFilter.status === 2) {
      setPagination((prevState) => ({
        ...prevState,
        pageCount: pendingScoreData.getGamesWithPendingScores.count,
      }));
      setGameScoreReportData((prevState) => ({
        ...prevState,
        gamesWithFinalScores: [],
        gamesWithPendingScores:
          pendingScoreData.getGamesWithPendingScores.gameReportData,
        gamesWithNoScores: [],
        gamesWithAdminOverrideScores: [],
        gamesWithSystemGeneratedScores: [],
      }));
    }
    if (noScoreData && gameScoreReportFilter.status === 6) {
      setPagination((prevState) => ({
        ...prevState,
        pageCount: noScoreData.getGamesWithNoScores.count,
      }));
      setGameScoreReportData((prevState) => ({
        ...prevState,
        gamesWithFinalScores: [],
        gamesWithPendingScores: [],
        gamesWithAdminOverrideScores: [],
        gamesWithSystemGeneratedScores: [],
        gamesWithNoScores: noScoreData.getGamesWithNoScores.gameReportData,
      }));
    }
    if (systemGeneratedScoreData && gameScoreReportFilter.status === 5) {
      const data =
        systemGeneratedScoreData.getGamesWithSystemGeneratedScores.gameReportData.filter(
          (score: any) => {
            return score.latestScore.scoreStatus.id == 5;
          }
        );

      setPagination((prevState) => ({
        ...prevState,
        // pageCount:
        //   systemGeneratedScoreData.getGamesWithSystemGeneratedScores.count,
        pageCount: data?.length,
      }));
      setGameScoreReportData((prevState) => ({
        ...prevState,
        gamesWithFinalScores: [],
        gamesWithPendingScores: [],
        gamesWithNoScores: [],
        gamesWithAdminOverrideScores: [],
        gamesWithSystemGeneratedScores: data,
        // gamesWithSystemGeneratedScores:
        //   systemGeneratedScoreData.getGamesWithSystemGeneratedScores
        //     .gameReportData,
      }));
    }
    if (adminOverrideScoreData && gameScoreReportFilter.status === 4) {
      let data: any = [];
      if (
        systemGeneratedScoreData.getGamesWithAdminOverrideScores?.gameReportData
      ) {
        data =
          systemGeneratedScoreData.getGamesWithAdminOverrideScores?.gameReportData?.filter(
            (score: any) => {
              return score.latestScore.scoreStatus.id == 4;
            }
          );
      }

      setPagination((prevState) => ({
        ...prevState,
        // pageCount: adminOverrideScoreData.getGamesWithAdminOverrideScores.count,
        pageCount: data.length,
      }));
      setGameScoreReportData((prevState) => ({
        ...prevState,
        gamesWithFinalScores: [],
        gamesWithPendingScores: [],
        gamesWithNoScores: [],
        gamesWithSystemGeneratedScores: [],
        gamesWithAdminOverrideScores: data,
        // gamesWithAdminOverrideScores:
        //   adminOverrideScoreData.getGamesWithAdminOverrideScores.gameReportData,
      }));
    }
  }, [
    pendingScoreData,
    noScoreData,
    finalScoreData,
    systemGeneratedScoreData,
    adminOverrideScoreData,
  ]);

  useEffect(() => {
    if (sports.length === 0) dispatch(getSports(""));
    if (leagues.length === 0) dispatch(getLeagues(""));
    if (scoreStatuses.length === 0) dispatch(getScoreStatuses(""));
  }, []);

  useEffect(() => {
    handleGetScoreData();
  }, [pagination.page, pagination.pageSize]);

  async function handleGetScoreData() {
    const gameScoreReportInput = {
      startDate: gameScoreReportFilter.startDate
        ? dayjs(gameScoreReportFilter.startDate).format("YYYY-MM-DD")
        : undefined,
      endDate: gameScoreReportFilter.endDate
        ? dayjs(gameScoreReportFilter.endDate).format("YYYY-MM-DD")
        : undefined,
      regionId: gameScoreReportFilter.regionId,
      sportId: gameScoreReportFilter.sportId,
      leagueId: gameScoreReportFilter.leagueId,
      dayOfWeek: gameScoreReportFilter.dayOfWeek,
      page: pagination.page,
      pageSize: pagination.pageSize,
      gameId: gameScoreReportFilter.gameId,
    };
    if (gameScoreReportFilter.status === 1) {
      // final
      await getGamesWithFinalScores({
        variables: {
          gameScoreReportInput: gameScoreReportInput,
        },
      });
    } else if (gameScoreReportFilter.status === 2) {
      // pending
      await getGamesWithPendingScores({
        variables: {
          gameScoreReportInput: gameScoreReportInput,
        },
      });
    } else if (gameScoreReportFilter.status === 6) {
      // no score
      await getGamesWithNoScores({
        variables: {
          gameScoreReportInput: gameScoreReportInput,
        },
      });
    } else if (gameScoreReportFilter.status === 4) {
      // Admin Override
      await getGamesWithAdminOverrideScores({
        variables: {
          gameScoreReportInput: gameScoreReportInput,
        },
      });
    } else if (gameScoreReportFilter.status === 5) {
      // System Generated
      await getGamesWithSystemGeneratedScores({
        variables: {
          gameScoreReportInput: gameScoreReportInput,
        },
      });
    }
  }

  async function handleApproveScore(id: number) {
    await approveScore({
      variables: {
        id: +id,
      },
    });
  }

  async function handleResetScore(id: number) {
    await ResetScore({
      variables: {
        scoreResetId: +id,
      },
    });
  }

  const handleScoreViewAction = async (game: GameData) => {
    setReportedScoresModal(true);
    setReportedScores([]);
    await getGamesScoreHistory({
      variables: {
        id: +game.id,
      },
    }).then((data) => {
      setReportedScores(data.data.game.reportedScores || []);
    });
  };

  const handleSelectedOption = (row: any, idx: number) => {
    setGameInfoData(row.original);
    if (idx === 1) {
      // View Scores
      handleScoreViewAction(row.original);
    }
    if (idx === 2) {
      // approve score
      handleApproveScore(row.original.latestScore.id);
    }
    if (idx === 3) {
      // submit score
      setNewScoreModal(true);
    }
    if (idx === 4) {
      // reset score
      // invoke a modal to confirm the reset
      setResetScoreModal(true);
      setResetScoreId(+row.original.latestScore.id);
      // then use handleResetScore
    }
    if (idx === 5) {
      // report default
      setReportDefaultModal(true);
    }
  };

  const handleChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setRecordForfeit((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const columnsGameScoreReport: ColumnDef<GameData>[] = [
    {
      accessorKey: "id",
      header: "Game ID",
    },
    {
      accessorFn: (row) =>
        dayjs(row.startDateTimeLocal).format("YYYY MMM, DD, HH:mm"),
      id: "startDateTimeLocal",
      header: "Game Date",
    },
    {
      accessorFn: (row) =>
        row.latestScore === undefined
          ? "No Score"
          : row.latestScore.scoreStatus.name,
      header: "Status",
    },
    {
      accessorFn: (row) =>
        row.latestScore !== undefined && row.latestScore.submittingUser
          ? row.latestScore.submittingUser.firstName +
            " " +
            row.latestScore.submittingUser.lastName
          : "N/A",
      id: "submittingUser",
      header: "Submitting User",
    },
    {
      accessorFn: (row) =>
        row.latestScore?.dateSubmitted
          ? dayjs(row.latestScore?.dateSubmitted).format("YYYY MMM, DD, HH:mm")
          : "N/A",
      id: "dateSubmitted",
      header: "Date Submitted",
    },
    {
      accessorFn: (row) => row.homeTeam?.name || "N/A",
      id: "homeTeam",
      header: "Home Team",
    },
    {
      accessorFn: (row) =>
        row.latestScore === undefined ? "N/A" : row.latestScore.homeScore,
      id: "homeScore",
      header: "Home Score",
    },
    {
      accessorFn: (row) => row.awayTeam?.name || "N/A",
      id: "awayTeam",
      header: "Away Team",
    },
    {
      accessorFn: (row) =>
        row.latestScore === undefined ? "N/A" : row.latestScore.awayScore,
      id: "awayScore",
      header: "Away Score",
    },
    {
      accessorFn: (row) =>
        row.latestScore !== undefined && row.latestScore.confirmedByUser
          ? row.latestScore.confirmedByUser.firstName +
            " " +
            row.latestScore.confirmedByUser.lastName
          : "N/A",
      id: "confirmedByUser",
      header: "Confirmed User",
    },
    {
      accessorFn: (row) =>
        row.latestScore?.dateConfirmed
          ? dayjs(row.latestScore?.dateConfirmed).format("YYYY MMM, DD, HH:mm")
          : "N/A",
      id: "dateConfirmed",
      header: "Date Confirmed",
    },
    {
      header: "Action",
      cell: ({ row }) => {
        return (
          <div>
            <ActionsCell
              row={row}
              options={
                row.original.latestScore === undefined
                  ? [
                      { id: 3, text: "Submit Score" },
                      {
                        id: 4,
                        text: "Reset Score",
                      },
                      {
                        id: 5,
                        text: "Report Default",
                      },
                    ]
                  : row.original.latestScore.scoreStatus.id === "1"
                  ? [
                      { id: 1, text: "View Scores" },
                      { id: 3, text: "Submit Score" },
                      {
                        id: 4,
                        text: "Reset Score",
                      },
                    ]
                  : [
                      { id: 1, text: "View Scores" },
                      { id: 2, text: "Approve Score" },
                      { id: 3, text: "Submit Score" },
                      {
                        id: 4,
                        text: "Reset Score",
                      },
                      {
                        id: 5,
                        text: "Report Default",
                      },
                    ]
              }
              handleSelectedOption={handleSelectedOption}
            />
          </div>
        );
      },
    },
  ];

  const columnsForScoreHistory: ColumnDef<LatestScore>[] = [
    {
      accessorKey: "id",
      header: "Score ID",
    },
    {
      accessorFn: (row) => row.homeScore,
      id: "homeScore",
      header: "Home Score",
    },
    {
      accessorFn: (row) => row.awayScore,
      id: "awayScore",
      header: "Away Score",
    },
    {
      accessorFn: (row) => row.scoreStatus.name,
      id: "scoreStatus",
      header: "Score Status",
    },
    {
      header: "Submitted By",
      cell: ({ row }) => {
        return (
          <div>
            {row.original.submittingUser?.firstName}{" "}
            {row.original.submittingUser?.lastName}
          </div>
        );
      },
    },
    {
      header: "Submitting Team",
      cell: ({ row }) => {
        return <div>{row.original.submittingTeam?.name} </div>;
      },
    },
    {
      header: "Submitted At",
      cell: ({ row }) => {
        return (
          <div>
            {dayjs(row.original.dateSubmitted).format("YYYY-MM-DD HH:mm")}
          </div>
        );
      },
    },
    {
      header: "Confirmed By",
      cell: ({ row }) => {
        return (
          <div>
            {row.original.confirmedByUser?.firstName}{" "}
            {row.original.confirmedByUser?.lastName}
          </div>
        );
      },
    },
    {
      header: "Confirmed At",
      cell: ({ row }) => {
        return (
          <div>
            {row.original.dateConfirmed &&
              dayjs(row.original.dateConfirmed).format("YYYY-MM-DD HH:mm")}
          </div>
        );
      },
    },
  ];

  const handleScoreChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setScores((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmitScore = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    CreateScore({
      variables: {
        createScoreArgs: {
          gameId: gameInfoData?.id,
          homeScore: +scores.homeTeamScore,
          awayScore: +scores.awayTeamScore,
        },
      },
    }).then((data) => {
      if (data.data?.scoreCreate.success) {
        setNewScoreModal(false);
      }
    });
  };

  const onSubmitDefaultScore = (e: React.MouseEvent<HTMLButtonElement>) => {
    let homeScore = 0;
    let awayScore = 0;
    if (recordForfeit.team === "homeTeam") {
      homeScore = 0;
      awayScore = 1;
    } else {
      homeScore = 1;
      awayScore = 0;
    }
    // Add reason of forfeit game as well!
    CreateScore({
      variables: {
        createScoreArgs: {
          gameId: gameInfoData?.id,
          homeScore: homeScore,
          awayScore: awayScore,
          isWinByDefault: true,
        },
      },
      onError: (error) => {
        console.log(error);
      },
    }).then((data) => {
      if (data.data?.scoreCreate.success) {
        setReportDefaultModal(false);
      }
    });
  };

  if (finalScoreLoading || noScoreLoading || pendingScoreLoading)
    return <LoadingDialog open={true} />;
  if (finalScoreError || noScoreError || pendingScoreError)
    return <div>Something went wrong</div>;

  return (
    <main className="flex flex-col justify-between w-full pb-4">
      <Modal
        open={resetScoreModal}
        onClose={() => setResetScoreModal(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className="flex flex-col gap-6">
            <Headline2Variable>Reset Score</Headline2Variable>

            <p> Do you want to reset the scores?</p>
            <div className="flex flex-col gap-1">
              <Body1>Game Info</Body1>
              <Caption1>
                Start Date:{" "}
                {dayjs(gameInfoData?.startDateTimeLocal).format(
                  "ddd, MM D, YYYY HH:mm"
                )}
              </Caption1>
              <Caption1>Venue: {gameInfoData?.venue?.name}</Caption1>
              <Caption1>Team 1: {gameInfoData?.homeTeam?.name}</Caption1>
              <Caption1>
                Score (Team 1): {gameInfoData?.latestScore?.homeScore}
              </Caption1>
              <Caption1>Team 2: {gameInfoData?.awayTeam?.name}</Caption1>
              <Caption1>
                Score (Team 2): {gameInfoData?.latestScore?.awayScore}
              </Caption1>
            </div>

            <div className="flex flex-wrap-reverse gap-4">
              <Button
                variant="primary"
                onClick={() => {
                  console.log(resetScoreId);
                  resetScoreId && handleResetScore(resetScoreId);
                  setResetScoreModal(false);
                }}
              >
                <span>Submit</span>
              </Button>
              <Button
                variant="secondary"
                onClick={() => setResetScoreModal(false)}
              >
                <span>Cancel</span>
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={reportedScoresModal}
        onClose={() => setReportedScoresModal(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className="flex flex-col gap-6">
            <Headline2Variable>Past Reported Scores</Headline2Variable>
            {scoreHistoryLoading === true ? (
              <div className="flex flex-row items-center justify-center w-[700px] h-16">
                <LoaderCircle className="w-8 h-8 animate-spin" />
              </div>
            ) : (
              <DataTable
                data={reportedScores}
                columns={columnsForScoreHistory}
              />
            )}
          </div>
        </Box>
      </Modal>
      <Modal
        open={newScoreModal}
        onClose={() => setNewScoreModal(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className="flex flex-col gap-6">
            <Headline2Variable>Submit Score</Headline2Variable>
            <div className="flex flex-row items-center p-5 mb-5 border shadow-lg border-secondary-90 rounded-xl">
              <div className="flex flex-col">
                <div className="self-center">
                  <Subtitle1>{gameInfoData?.homeTeam?.name}</Subtitle1>
                </div>
                <FormField
                  inputChange={handleScoreChange}
                  name="homeTeamScore"
                  label="Final Score *"
                />
              </div>
              <div className="mx-3">vs</div>
              <div className="flex flex-col">
                <div className="self-center">
                  <Subtitle1>{gameInfoData?.awayTeam?.name}</Subtitle1>
                </div>
                <FormField
                  inputChange={handleScoreChange}
                  name="awayTeamScore"
                  label="Final Score *"
                />
              </div>
            </div>
            <div className="flex flex-wrap-reverse gap-4">
              <Button
                variant="primary"
                onClick={onSubmitScore}
              >
                <span>Submit</span>
              </Button>
              <Button
                variant="secondary"
                onClick={() => setNewScoreModal(false)}
              >
                <span>Cancel</span>
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={reportDefaultModal}
        onClose={() => setReportDefaultModal(false)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className="flex flex-col">
            {/* Game Details */}
            <div className="mb-5">
              <Headline2Variable>Report Default</Headline2Variable>
              {/* <Caption1>
                  {gameInfoData?.session.league.sportFormat.name}
                </Caption1>
                <Caption1>
                  {formatTimeRange(
                    gameInfoData?.startDateTimeLocal,
                    gameInfoData?.session.league.gameDuration
                  )}
                  ,{" "}
                  {new Date(gameInfoData?.startDateTimeLocal).toLocaleDateString(
                    "en-us",
                    {
                      month: "short",
                      day: "2-digit",
                    }
                  )}
                </Caption1> */}
              <Caption1>{gameInfoData?.venue.name}</Caption1>
            </div>
            <div className="flex flex-row border items-center border-secondary-90 rounded-xl p-5 shadow-lg mb-5">
              <div className="flex flex-col">
                {/* Select Forfeitng Team- Radio Button */}
                <div className="mb-5">
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      <Subtitle1>Select Forfeiting Team *</Subtitle1>
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={recordForfeit?.team}
                      name="team"
                      onChange={handleChangeRadio}
                    >
                      <FormControlLabel
                        value="homeTeam"
                        control={<Radio />}
                        label={gameInfoData?.homeTeam?.name}
                      />
                      <FormControlLabel
                        value="awayTeam"
                        control={<Radio />}
                        label={gameInfoData?.awayTeam?.name}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                {/* Select Reason- Radio Button */}
                <div className="mb-5">
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      <Subtitle1>Select Reason *</Subtitle1>
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={recordForfeit?.reason}
                      name="reason"
                      onChange={handleChangeRadio}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="Did not attend (with notice)"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label="Did not attend (without notice)"
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio />}
                        label="Not enough total players"
                      />
                      <FormControlLabel
                        value="4"
                        control={<Radio />}
                        label="Not enough women players"
                      />
                      <FormControlLabel
                        value="5"
                        control={<Radio />}
                        label="Not enough men players"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap-reverse gap-4">
              <Button
                variant="primary"
                onClick={onSubmitDefaultScore}
              >
                <span>Submit</span>
              </Button>
              <Button
                variant="secondary"
                onClick={() => setReportDefaultModal(false)}
              >
                <span>Cancel</span>
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <Headline1Variable>Game Score Reports</Headline1Variable>
      <div className="flex flex-col gap-2">
        <div className="grid grid-cols-5 gap-4">
          <FormFieldSelect
            inputChange={(value) => {
              setGameScoreReportFilter((prevState: GameScoreReportType) => {
                return {
                  ...prevState,
                  regionId: +value,
                };
              });
            }}
            label="Region"
            placeholder="Region"
            value={gameScoreReportFilter.regionId?.toString() ?? "0"}
          >
            {[{ id: "0", name: "All" }, ...selectedRegions]}
          </FormFieldSelect>
          <FormFieldSelect
            inputChange={(value) => {
              setGameScoreReportFilter((prevState: GameScoreReportType) => {
                return {
                  ...prevState,
                  sportId: +value,
                };
              });
            }}
            label="Sport"
            placeholder="Sport"
            value={gameScoreReportFilter.sportId?.toString() ?? "0"}
          >
            {[{ id: "0", name: "All" }, ...sports]}
          </FormFieldSelect>
          <FormFieldSelect
            inputChange={(value) => {
              setGameScoreReportFilter((prevState: GameScoreReportType) => {
                return {
                  ...prevState,
                  leagueId: +value,
                };
              });
            }}
            label="League"
            placeholder="League"
            value={gameScoreReportFilter.leagueId?.toString() ?? "0"}
          >
            {[{ id: "0", name: "All" }, ...leagues]}
          </FormFieldSelect>
          <FormFieldSelect
            inputChange={(value) => {
              setGameScoreReportFilter((prevState: GameScoreReportType) => {
                return {
                  ...prevState,
                  dayOfWeek: +value,
                };
              });
            }}
            label="Day"
            placeholder="Day"
            value={gameScoreReportFilter.dayOfWeek?.toString() ?? "-1"}
          >
            {[{ id: "-1", name: "All" }, ...weekdaysArray]}
          </FormFieldSelect>
          <FormFieldSelect
            inputChange={(value) => {
              setGameScoreReportFilter((prevState: GameScoreReportType) => {
                return {
                  ...prevState,
                  status: +value,
                };
              });
            }}
            label="Score Status"
            placeholder="Score Status"
            value={gameScoreReportFilter.status?.toString() ?? "0"}
          >
            {[
              // { id: "0", name: "All" },
              // ...scoreStatuses,
              { id: "1", name: "Final" },
              { id: "2", name: "Pending" },
              // { id: "4", name: "Admin Override" },
              { id: "5", name: "System Generated" },
              { id: "6", name: "No Score" },
            ]}
          </FormFieldSelect>
        </div>
        <div className="flex flex-row items-end gap-4">
          <div className="flex flex-col gap-1">
            <Caption1 className="font-medium">Start Date</Caption1>
            <DatePicker
              date={gameScoreReportFilter.startDate}
              setDate={(date) =>
                setGameScoreReportFilter({
                  ...gameScoreReportFilter,
                  startDate: date,
                })
              }
            />
          </div>
          <div className="flex flex-col gap-1">
            <Caption1 className="font-medium">End Date</Caption1>
            <DatePicker
              date={gameScoreReportFilter.endDate}
              setDate={(date) =>
                setGameScoreReportFilter({
                  ...gameScoreReportFilter,
                  endDate: date,
                })
              }
            />
          </div>
          <div className="w-40">
            <FormField
              type="number"
              className="w-40 h-10"
              inputChange={(value) => {
                console.log(value);
                setGameScoreReportFilter((prevState: GameScoreReportType) => {
                  return {
                    ...prevState,
                    gameId: +value.target.value,
                  };
                });
              }}
              label="Game ID"
              initialValue={
                gameScoreReportFilter.gameId !== undefined
                  ? gameScoreReportFilter.gameId.toString()
                  : undefined
              }
              value={
                gameScoreReportFilter.gameId !== undefined
                  ? gameScoreReportFilter.gameId.toString()
                  : undefined
              }
            />
          </div>
          <Button
            variant="primary"
            onClick={handleGetScoreData}
            className="h-fit"
          >
            Filter
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        {gameScoreReportData &&
          (gameScoreReportData.gamesWithNoScores.length > 0 ||
            gameScoreReportData.gamesWithPendingScores.length > 0 ||
            gameScoreReportData.gamesWithSystemGeneratedScores.length > 0 ||
            gameScoreReportData.gamesWithAdminOverrideScores.length > 0 ||
            gameScoreReportData.gamesWithFinalScores.length > 0) && (
            <div className="flex flex-col gap-4">
              <Headline2Variable>Scores</Headline2Variable>
              <DataTable
                data={[
                  ...gameScoreReportData.gamesWithFinalScores,
                  ...gameScoreReportData.gamesWithPendingScores,
                  ...gameScoreReportData.gamesWithNoScores,
                  ...gameScoreReportData.gamesWithSystemGeneratedScores,
                  ...gameScoreReportData.gamesWithAdminOverrideScores,
                ]}
                columns={columnsGameScoreReport}
              />
              {pagination.pageCount && (
                <Fragment>
                  <div className="my-auto text-xs text-disabled">
                    Page {pagination.page + 1} of{" "}
                    {Math.ceil(pagination.pageCount / pagination.pageSize)}
                  </div>
                  <div className="flex flex-row">
                    <Pagination
                      page={pagination.page + 1}
                      count={Math.ceil(
                        pagination.pageCount / pagination.pageSize
                      )}
                      onChange={(event: any, value: number) => {
                        setPagination((prevState: PaginationType) => {
                          return {
                            ...prevState,
                            page: value - 1,
                          };
                        });
                      }}
                    />
                    <Select
                      value={`${pagination.pageSize.toString()}`}
                      onValueChange={(value) => {
                        setPagination((prevState: PaginationType) => {
                          return {
                            ...prevState,
                            page: Math.floor(
                              (prevState.page * prevState.pageSize) /
                                Number(value)
                            ),
                            pageSize: Number(value),
                          };
                        });
                      }}
                    >
                      <SelectTrigger className="h-8 w-[70px]">
                        <SelectValue
                          placeholder={pagination.pageSize.toString()}
                        />
                      </SelectTrigger>
                      <SelectContent side="top">
                        {[5, 10, 25, 50, 100].map((pageSize) => (
                          <SelectItem
                            key={pageSize}
                            value={`${pageSize}`}
                            className="hover:bg-primary-95"
                          >
                            {pageSize}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                </Fragment>
              )}
            </div>
          )}
        {/* {gameScoreReportData &&
          gameScoreReportData.gamesNoScores.length > 0 && (
            <div className="flex flex-col gap-4">
              <Headline2Variable>Games with no scores</Headline2Variable>
              <DataTable
                data={gameScoreReportData.gamesNoScores}
                columns={columnsNoScores}
              />
            </div>
          )} */}
        {/* {gameScoreReportData &&
          gameScoreReportData.gamesNoScores.length > 0 && (
            <div className="flex flex-col gap-4">
              <Headline2Variable>Games with pending scores</Headline2Variable>
              <DataTable
                data={gameScoreReportData.gamesPendingScores}
                columns={columnsPendingScores}
              />
            </div>
          )}
        */}
        {/* {gameScoreReportData &&
          gameScoreReportData.gamesWithFinalScore.length > 0 && (
            <div className="flex flex-col gap-4">
              <Headline2Variable>Games with Final scores</Headline2Variable>
              <DataTable
                data={gameScoreReportData.gamesWithFinalScore}
                columns={columnsFinalScores}
              />
            </div>
          )}  */}
      </div>
    </main>
  );
};

export default GameScoreReport;
