import { useWeatherContext } from "../../../context/WeatherContext";
import React from "react";
import Card from "../../UI/Card/Card";
import dayjs from "dayjs";
import Body1 from "../../UI/Text/Body/Body1";
import Caption1 from "../../UI/Text/Caption/Caption1";

type WeatherDayCardProps = {
  weatherIcon: string;
  weatherDescription: string;
  temp: number;
  rainVolume: number;
  snowVolume: number;
  cloudPercent: number;
  percentOfPrecipitation: number;
  dateTime: Date;
};

const WeatherDayCard = ({ data }: { data: WeatherDayCardProps }) => {
  const { americanMode } = useWeatherContext();
  /*** UTILITY FUNCTIONS ***/
  const iconUrl = (icon: string) => {
    return `https://openweathermap.org/img/wn/${icon}@2x.png`;
  };
  function celciusToFahrenheit(value: number): number {
    return (value * 9) / 5 + 32;
  }
  function mmToInches(value: number): number {
    return value / 25.4;
  }
  return (
    <div className="flex flex-col items-center gap-2 w-fit">
      <Card className="flex flex-col items-center justify-center gap-6 w-fit">
        <div className="flex flex-col items-center justify-center gap-1">
          <div className="w-16 h-16 rounded-full">
            <img src={iconUrl(data.weatherIcon)} />
          </div>
          <Caption1>{data.weatherDescription}</Caption1>
        </div>
        <div className="flex flex-row gap-2">
          <div className="grid justify-start grid-cols-1">
            <Body1 className="whitespace-nowrap">Temp:</Body1>
            <Body1 className="whitespace-nowrap">POP:</Body1>
            <Body1 className="whitespace-nowrap">Clouds:</Body1>
            <Body1 className="whitespace-nowrap">Rain:</Body1>
            <Body1 className="whitespace-nowrap">Snow:</Body1>
          </div>
          <div className="grid justify-start grid-cols-1">
            <Body1 className="whitespace-nowrap">
              {americanMode
                ? `${celciusToFahrenheit(data.temp).toFixed(0)}F`
                : `${data.temp.toFixed(1)}C`}
            </Body1>
            <Body1 className="whitespace-nowrap">
              {data.percentOfPrecipitation}%
            </Body1>
            <Body1 className="whitespace-nowrap">{data.cloudPercent}%</Body1>
            <Body1 className="whitespace-nowrap">
              {americanMode
                ? `${mmToInches(data.rainVolume).toFixed(2)}In`
                : `${data.rainVolume}mm`}
            </Body1>
            <Body1 className="whitespace-nowrap">
              {americanMode
                ? `${mmToInches(data.snowVolume).toFixed(2)}In`
                : `${data.snowVolume}mm`}
            </Body1>
          </div>
        </div>
      </Card>

      {dayjs(data.dateTime).format("h:mm A")}
    </div>
  );
};

export default WeatherDayCard;
