import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../app/store";
import "react-quill/dist/quill.snow.css";
import {
  ListAllOperations,
  useCreateCapacityGroupVenueMutation,
  useDeleteCapacityGroupSessionMutation,
  useUpdateCapacityGroupMutation,
  useVenuesManagementQuery,
} from "../../../generated/graphql";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import Button from "../../UI/Button/Button";
import {
  displayAlertError,
  displayAlertSuccess,
  displayAlertWarning,
} from "../../../app/globalSlice";
import LoadingDialog from "../../UI/Dialog/LoadingDialog";
import { z, ZodFormattedError } from "zod";
import FormFieldControlled from "../../UI/FormField/FormFieldControlled";
import { useCapacityGroupQuery } from "../../../generated/graphql";
import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "../../UI/Table/DataTable";
import Headline2Variable from "../../UI/Text/Headline/Headline2Variable";
import { Trash2, UndoIcon } from "lucide-react";
import BookmarkRemoveOutlinedIcon from "@mui/icons-material/BookmarkRemoveOutlined";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "../../UI/shadcn/dialog";
import Caption1 from "../../UI/Text/Caption/Caption1";
import Body1 from "../../UI/Text/Body/Body1";

// Replace CapacityGroup with the name of the component
// Schema for the form, used for both update and create
const UpdateCapacityGroupSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    sessions: z.array(
      z.object({
        id: z.number(),
        sessionId: z.number(),
        leagueName: z.string(),
        dayOfWeek: z.string(),
        isDeleted: z.boolean(),
      })
    ),
    venues: z.array(
      z.object({
        id: z.number(),
        venueId: z.number(),
        name: z.string(),
        region: z.string(),
        regionId: z.number(),
        numberOfGameSlot: z.number(),
      })
    ),
  })
  .refine((data) => data.name.length > 0, {
    message: "Name cannot be empty",
    path: ["name"],
  });
type UpdateCapacityGroupFormValues = z.infer<typeof UpdateCapacityGroupSchema>;
const initialCapacityGroupData: UpdateCapacityGroupFormValues = {
  id: 0,
  name: "",
  sessions: [],
  venues: [],
};

const CreateCapacityGroupVenueSchema = z.object({
  capacityGroupId: z.number(),
  venueId: z.number(),
  numberOfGameSlot: z.number(),
});
type CreateCapacityGroupVenue = z.infer<typeof CreateCapacityGroupVenueSchema>;
const initialCapacityGroupVenueData: CreateCapacityGroupVenue = {
  capacityGroupId: 0,
  venueId: 0,
  numberOfGameSlot: 0,
};

const sessionOptions = [{ id: 1, text: "Delete" }];

const CapacityGroup: React.FC = () => {
  /*** IMPORTED FUNTION DECLARATIONS ***/
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams();
  const navigate = useNavigate();

  // The id will be used to know if we're editing or creating data
  const { id } = params;
  // The id should be "new" if we're creating data so this will return false
  const isEdit = id !== undefined && !isNaN(+id);
  if (!isEdit) {
    displayAlertError(`Capacity Group with id ${id} doesn't exist`);
    navigate(-1);
  }
  const { selectedRegions }: any = useSelector(
    (state: RootState) => state.venueMaster
  );

  /*** STATES ***/
  const [capacityGroupData, setCapacityGroupData] =
    useState<UpdateCapacityGroupFormValues>(initialCapacityGroupData);
  const [createCapacityGroupVenue, setCreateCapacityGroupVenue] =
    useState<CreateCapacityGroupVenue>({
      ...initialCapacityGroupVenueData,
      capacityGroupId: +id!,
    });
  // Zod errors used to show errors on the form
  const [updateZodErrors, setUpdateZodErrors] = useState<
    ZodFormattedError<UpdateCapacityGroupFormValues, string>
  >({ _errors: [] });
  const [capacityGroupSessionIsRemoving, setCapacityGroupSessionIsRemoving] =
    useState<
      | {
          id: number;
          sessionId: number;
          leagueName: string;
          dayOfWeek: string;
          isDeleted: boolean;
        }
      | undefined
    >(undefined);

  /*** QUERIES ***/
  // Query to get data based on the id if it's being edited
  const { loading: loadingCapacityGroup } = useCapacityGroupQuery({
    variables: {
      id: +id!,
    },
    skip: !isEdit,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setCapacityGroupData({
        id: data.capacityGroup.id,
        name: data.capacityGroup.name,
        sessions: data.capacityGroup.capacityGroupSessions.map(
          (capacityGroupSession) => {
            return {
              id: capacityGroupSession.id,
              sessionId: capacityGroupSession.session.id,
              leagueName: capacityGroupSession.session.league.name,
              dayOfWeek: capacityGroupSession.session.dayOfWeekType.name,
              isDeleted: false,
            };
          }
        ),
        venues: data.capacityGroup.capacityGroupVenues.map(
          (capacityGroupVenue) => {
            return {
              id: capacityGroupVenue.id,
              venueId: capacityGroupVenue.venue.id,
              name: capacityGroupVenue.venue.name,
              region: capacityGroupVenue.venue.venueDetail?.region.name ?? "",
              regionId: capacityGroupVenue.venue.venueDetail?.region.id,
              numberOfGameSlot: capacityGroupVenue.numberOfGameSlot,
            };
          }
        ),
      });
    },
  });

  const { loading: loadingVenues, data: dataVenues } = useVenuesManagementQuery(
    {
      variables: {
        venueFilters: {
          regionId: capacityGroupData.venues.map((venue) => venue.regionId),
        },
      },
    }
  );

  /*** MUTATIONS ***/
  const [UpdateCapacityGroup, { loading: loadingUpdataCapacityGroup }] =
    useUpdateCapacityGroupMutation({});
  const [
    DeleteCapacityGroupSession,
    { loading: loadingDeleteCapacityGroupSession },
  ] = useDeleteCapacityGroupSessionMutation({});
  const [
    CreateCapacityGroupVenue,
    { loading: loadingCreateCapacityGroupVenue },
  ] = useCreateCapacityGroupVenueMutation();

  /*** UTILITY FUNCTIONS ***/
  async function handleSubmit() {
    const result = UpdateCapacityGroupSchema.safeParse(capacityGroupData);
    if (!result.success) {
      setUpdateZodErrors(result.error.format());
      dispatch(displayAlertWarning("There is with the merge form"));
      return;
    }
    // Creates or updates data
    UpdateCapacityGroup({
      variables: {
        id: capacityGroupData.id,
        name: capacityGroupData.name,
        capacityGroupVenues: capacityGroupData.venues.map((venue) => {
          return {
            id: venue.id,
            numberOfGameSlot: venue.numberOfGameSlot,
          };
        }),
      },
      refetchQueries: [ListAllOperations.Query.CapacityGroup],
      onCompleted: (data) => {
        if (data.updateCapacityGroup.success) {
          dispatch(displayAlertSuccess(data.updateCapacityGroup.message));
        } else {
          dispatch(displayAlertError(data.updateCapacityGroup.message));
        }
      },
      onError: (error) => {
        dispatch(displayAlertError(error.message));
      },
    });

    const cgsToDelete = capacityGroupData.sessions.find((cgS) => cgS.isDeleted);
    if (cgsToDelete !== undefined) {
      handleDeleteCapacityGroupSession({ id: cgsToDelete.id });
    }
    return;
  }

  async function handleDeleteCapacityGroupSession({ id }: { id: number }) {
    DeleteCapacityGroupSession({
      variables: {
        id,
        capacityGroupId: capacityGroupData.id,
      },
      refetchQueries: [ListAllOperations.Query.CapacityGroup],
      onCompleted: (data) => {
        if (data.deleteCapacityGroupSession.success) {
          dispatch(
            displayAlertSuccess(data.deleteCapacityGroupSession.message)
          );
        } else {
          dispatch(displayAlertError(data.deleteCapacityGroupSession.message));
        }
      },
      onError: (error) => {
        dispatch(displayAlertError(error.message));
      },
    });
  }

  async function handleCreateCapacityGroupVenue() {
    CreateCapacityGroupVenue({
      variables: {
        createCapacityGroupVenueInput: {
          capacityGroupId: createCapacityGroupVenue.capacityGroupId,
          venueId: createCapacityGroupVenue.venueId,
          numberOfGameSlot: createCapacityGroupVenue.numberOfGameSlot,
        },
      },
      refetchQueries: [ListAllOperations.Query.CapacityGroup],
      onCompleted: (data) => {
        if (data.createCapacityGroupVenue.success) {
          dispatch(displayAlertSuccess(data.createCapacityGroupVenue.message));
        } else {
          dispatch(displayAlertError(data.createCapacityGroupVenue.message));
        }
      },
      onError: (error) => {
        dispatch(displayAlertError(error.message));
      },
    });
  }

  useEffect(() => {
    const isDeleted = capacityGroupData.sessions.find((cgS) => cgS.isDeleted);
    if (isDeleted) {
      setCapacityGroupSessionIsRemoving(isDeleted);
    }
  }, [capacityGroupData.sessions]);

  /*** TABLE DEFIITIONS ***/
  const sessionColumns: ColumnDef<
    UpdateCapacityGroupFormValues["sessions"][0]
  >[] = [
    {
      id: "id",
      header: "Id",
      accessorFn: (row) => row.id,
    },
    {
      id: "sessionId",
      header: "Session Id",
      accessorFn: (row) => row.sessionId,
    },
    {
      id: "name",
      header: "League Name",
      accessorFn: (row) => row.leagueName,
    },
    {
      id: "dayOfWeek",
      header: "Day of Week",
      accessorFn: (row) => row.dayOfWeek,
    },
    {
      id: "remove",
      header: "Remove",
      cell: ({ row }) => {
        return (
          <div>
            {row.original.isDeleted ? (
              <Button
                variant="secondary"
                onClick={() => {
                  setCapacityGroupData((prevState) => ({
                    ...prevState,
                    sessions: prevState.sessions.map((session) => {
                      if (session.id !== row.original.id) {
                        return session;
                      } else {
                        return {
                          ...session,
                          isDeleted: false,
                        };
                      }
                    }),
                  }));
                }}
              >
                <UndoIcon />
              </Button>
            ) : (
              <Button
                variant={
                  capacityGroupData.sessions.some(
                    (session) => session.isDeleted
                  ) || capacityGroupData.sessions.length === 1
                    ? "disabled"
                    : "negative"
                }
                disabled={
                  capacityGroupData.sessions.some(
                    (session) => session.isDeleted
                  ) || capacityGroupData.sessions.length === 1
                }
                onClick={() => {
                  setCapacityGroupData((prevState) => ({
                    ...prevState,
                    sessions: prevState.sessions.map((session) => {
                      if (session.id !== row.original.id) {
                        return session;
                      } else {
                        return {
                          ...session,
                          isDeleted: true,
                        };
                      }
                    }),
                  }));
                }}
              >
                <Trash2 />
              </Button>
            )}
          </div>
        );
      },
    },
  ];
  // Constants for the table
  const sessionTableData = useMemo(() => {
    if (capacityGroupData) return capacityGroupData.sessions;
  }, [capacityGroupData.sessions]);

  const venueColumns: ColumnDef<UpdateCapacityGroupFormValues["venues"][0]>[] =
    [
      {
        id: "id",
        header: "Id",
        accessorFn: (row) => row.id,
      },
      {
        id: "venueId",
        header: "Venue Id",
        accessorFn: (row) => row.venueId,
      },
      {
        id: "name",
        header: "Name",
        accessorFn: (row) => row.name,
      },
      {
        id: "region",
        header: "Region",
        accessorFn: (row) => row.region,
      },
      {
        id: "numberOfGameSlot",
        header: "Number of Game Slots",
        cell: ({ row }) => {
          return (
            <FormFieldSelect
              name="row.numberOfGameSlot"
              placeholder="Select Label"
              value={row.original.numberOfGameSlot?.toString() ?? "0"}
              inputChange={(value: string) => {
                setCapacityGroupData((prevState) => ({
                  ...prevState,
                  venues: prevState.venues.map((venue) => {
                    return {
                      ...venue,
                      numberOfGameSlot:
                        row.original.id === venue.id
                          ? +value
                          : venue.numberOfGameSlot,
                    };
                  }),
                }));
              }}
            >
              {Array.from(Array(100), (_, i) => {
                return {
                  id: i,
                  name: i.toString(),
                };
              })}
            </FormFieldSelect>
          );
        },
      },
    ];
  const venueTableData = useMemo(() => {
    if (capacityGroupData) return capacityGroupData.venues;
  }, [capacityGroupData.venues]);

  return (
    <main className="flex flex-col w-full gap-4 pb-10">
      <LoadingDialog open={loadingCapacityGroup} />
      <div className="flex flex-row justify-between w-full">
        <Headline1Variable>Edit Capacity Group</Headline1Variable>
        <Button
          variant="secondary"
          href={"/ops/capacity-group-overview"}
          className="h-fit"
        >
          Capacity Group Overview
        </Button>
      </div>
      <div className="flex flex-col w-2/3 gap-4">
        <div className="flex flex-row items-end gap-4">
          <FormFieldControlled
            name="name"
            label="Name"
            error={updateZodErrors.name !== undefined}
            assistiveText={
              updateZodErrors.name !== undefined
                ? updateZodErrors.name._errors[0]
                : ""
            }
            value={capacityGroupData.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (updateZodErrors.name && e.target.value !== "") {
                setUpdateZodErrors((prevState) => ({
                  ...prevState,
                  _errors: prevState?._errors ?? [],
                  name: undefined,
                }));
              }
              setCapacityGroupData((prevState) => ({
                ...prevState,
                name: e.target.value,
              }));
            }}
          />
        </div>
        <Headline1Variable>Sessions</Headline1Variable>
        {sessionTableData !== undefined ? (
          <DataTable
            columns={sessionColumns}
            data={sessionTableData}
          />
        ) : (
          <Headline2Variable>No sessions for capacity group</Headline2Variable>
        )}
        <div className="flex flex-row justify-between">
          <Headline1Variable>Venues</Headline1Variable>
          <Dialog>
            <DialogTrigger className="flex justify-start">
              <Button
                variant="secondary"
                className="w-fit h-fit whitespace-nowrap"
              >
                New Capcity Group Venue
              </Button>
            </DialogTrigger>
            <DialogContent className="">
              <DialogHeader>New Venue for capacity group</DialogHeader>
              <DialogDescription>
                <div className="flex flex-col gap-4">
                  <Body1>
                    This will add a new venue to this capacity group
                  </Body1>
                  <FormFieldSelect
                    name="venue"
                    label="Venue"
                    placeholder="Select Venue"
                    value={createCapacityGroupVenue.venueId.toString() ?? "0"}
                    inputChange={(value: string) => {
                      setCreateCapacityGroupVenue((prevState) => ({
                        ...prevState,
                        venueId: +value,
                      }));
                    }}
                  >
                    {[
                      { id: 0, name: "All" },
                      ...(dataVenues !== undefined
                        ? dataVenues.venues.map((venue) => {
                            return {
                              id: venue.id,
                              name: venue.name,
                            };
                          })
                        : []),
                    ]}
                  </FormFieldSelect>
                  <FormFieldSelect
                    name="numberOfGameSlot"
                    label="Number of Gameslotts"
                    placeholder="Select Gameslot"
                    value={
                      createCapacityGroupVenue.numberOfGameSlot.toString() ??
                      "0"
                    }
                    inputChange={(value: string) => {
                      setCreateCapacityGroupVenue((prevState) => ({
                        ...prevState,
                        numberOfGameSlot: +value,
                      }));
                    }}
                  >
                    {Array.from(Array(100), (_, i) => {
                      return {
                        id: i,
                        name: i.toString(),
                      };
                    })}
                  </FormFieldSelect>
                </div>
              </DialogDescription>
              <DialogFooter>
                <DialogClose>
                  <Button variant="secondary">Close</Button>
                </DialogClose>
                <DialogClose>
                  <Button
                    variant="primary"
                    onClick={handleCreateCapacityGroupVenue}
                  >
                    Confirm
                  </Button>
                </DialogClose>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
        {venueTableData !== undefined ? (
          <DataTable
            columns={venueColumns}
            data={venueTableData}
          />
        ) : (
          <Headline2Variable>No venues for capacity group</Headline2Variable>
        )}
        {capacityGroupSessionIsRemoving ? (
          <Dialog>
            <DialogTrigger className="flex justify-start">
              <Button
                variant="primary"
                className="w-60 h-fit whitespace-nowrap"
              >
                Update Capacity Group
              </Button>
            </DialogTrigger>
            <DialogContent className="">
              <DialogHeader>Confrim New Capacity Group</DialogHeader>
              <DialogDescription>
                <div className="flex flex-col gap-4">
                  <Body1>
                    This update will remove the below session from this capacity
                    group:
                  </Body1>
                  <div className="flex flex-col">
                    <div className="flex flex-row justify-start">
                      <Caption1 className="w-28">id:</Caption1>
                      <Caption1>{capacityGroupSessionIsRemoving.id}</Caption1>
                    </div>
                    <div className="flex flex-row justify-start">
                      <Caption1 className="w-28">sesssion id:</Caption1>
                      <Caption1>
                        {capacityGroupSessionIsRemoving.sessionId}
                      </Caption1>
                    </div>
                    <div className="flex flex-row justify-start">
                      <Caption1 className="w-28 min-w-28">
                        league name:
                      </Caption1>
                      <Caption1 whiteSpace="normal">
                        {capacityGroupSessionIsRemoving.leagueName}
                      </Caption1>
                    </div>
                    <div className="flex flex-row justify-start">
                      <Caption1 className="w-28">day of week:</Caption1>
                      <Caption1>
                        {capacityGroupSessionIsRemoving.dayOfWeek}
                      </Caption1>
                    </div>
                  </div>
                </div>
              </DialogDescription>
              <DialogFooter>
                <DialogClose>
                  <Button variant="secondary">Cancel</Button>
                </DialogClose>
                <DialogClose>
                  <Button
                    variant="primary"
                    onClick={() => handleSubmit()}
                  >
                    Confrim
                  </Button>
                </DialogClose>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        ) : (
          <Button
            variant="primary"
            className="w-60"
            onClick={() => handleSubmit()}
          >
            Update Capacity Group
          </Button>
        )}
      </div>
    </main>
  );
};

export default CapacityGroup;
