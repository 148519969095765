import React, { Fragment, useMemo, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../app/store";
import classes from "./ShoppingCarts.module.css";
import { Column } from "react-table";
import { getShoppingCarts } from "../../app/venueMasterSlice";
import { RemoveRedEye } from "@mui/icons-material";
import dayjs from "dayjs";
import FormFieldDate from "../UI/FormField/FormFieldDate/FormFieldDate";
import Button from "../UI/Button/Button";
import BaseTable from "../UI/Table/Table";
import TablePagination from "../UI/Pagination/Pagination";
import { Pagination } from "@/src/types/types";
import LoadingDialog from "../UI/Dialog/LoadingDialog";
import {
  useShoppingCartLazyQuery,
  useShoppingCartsLazyQuery,
} from "../../generated/graphql";
import { numberToMoney } from "../../utils/financialHelpers";

const ShoppingCarts: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  //Pagination Page Size
  const pageSize = 25;

  const { isLoading, shoppingCarts, shoppingCartsCount } = useSelector(
    (state: RootState) => state.venueMaster
  );

  const [shoppingCartsFilter, setShoppingCartsFilters] = React.useState<any>({
    startDate: null,
    endDate: null,
  });
  const [pagination, setPagination] = React.useState<null | Pagination>(null);
  const countRef = useRef<number>(0);

  const getShoppingCartsData = () => {
    const filters: any = {
      startDate: shoppingCartsFilter.startDate
        ? dayjs(shoppingCartsFilter.startDate).hour(0)
        : null,
      endDate: shoppingCartsFilter.endDate
        ? dayjs(shoppingCartsFilter.endDate).hour(24)
        : null,
    };
    dispatch(
      getShoppingCarts({
        filters,
        page: pagination?.page ? pagination?.page : 0,
        pageSize: pagination?.pageSize ? pagination?.pageSize : 25,
      })
    );
  };

  useEffect(() => {
    if (shoppingCartsCount !== countRef.current) {
      const page = 0;
      const pageCount = Math.ceil(shoppingCartsCount / pageSize);
      setPagination({
        page,
        pageSize,
        pageCount,
      });
      countRef.current = shoppingCartsCount;
    }
  }, [shoppingCartsCount]);

  useEffect(() => {
    if (pagination) {
      getShoppingCartsData();
    }
  }, [pagination]);

  const handleChangePagination = (value: number) => {
    setPagination({
      pageCount: pagination?.pageCount,
      pageSize: pagination?.pageSize,
      page: value - 1,
    });
  };

  const redirectShoppingCart = (id: number) => {
    navigate(`/players/shopping-cart/${id}`, {
      state: { id },
    });
  };

  const onSubmit = () => {
    getShoppingCartsData();
  };

  const resetFilter = () => {
    setShoppingCartsFilters({
      startDate: "",
      endDate: "",
    });
  };

  const handleChange = (name: string, value: string) => {
    const shoppingCartsFilterData = {
      ...shoppingCartsFilter,
      [name]: value,
    };
    setShoppingCartsFilters(shoppingCartsFilterData);
  };

  const COLUMNS: Column<any>[] = [
    {
      Header: "Shopping Cart ID",
      id: "shoppingCartId",
      accessor: (d: any) => {
        return <div>{d.id}</div>;
      },
    },
    {
      Header: "Time Created",
      id: "createdAt",
      accessor: (d: any) => {
        return <div>{dayjs(d.createdAt).format("YYYY-MM-DD HH:mm")}</div>;
      },
    },
    {
      Header: "Player",
      id: "user",
      accessor: (d: any) => {
        return (
          <div>{`${d.user.firstName} ${d.user.lastName} [${d.user.id}]`}</div>
        );
      },
    },
    {
      Header: "Status",
      id: "status",
      accessor: (d: any) => {
        return <div>{d.shoppingCartStatus?.name}</div>;
      },
    },
    {
      Header: "Last Active",
      id: "lastActive",
      accessor: (d: any) => {
        return <div>{dayjs(d.lastActive).format("YYYY-MM-DD HH:mm")}</div>;
      },
    },
    {
      Header: "Total",
      id: "total",
      accessor: (d: any) => {
        let totalValue = 0;
        d.shoppingCartItems.map((shoppingcartItem: any) => {
          totalValue +=
            shoppingcartItem.itemAmount +
            (shoppingcartItem.txnFee ?? 0) +
            shoppingcartItem.tax;
        });
        return <div>{numberToMoney(totalValue)}</div>;
      },
    },
    {
      Header: "Transaction Amount",
      id: "transactionAmount",
      accessor: (d: any) => {
        let transactionAmount = 0;
        d.shoppingCartTransactions.map((transaction: any) => {
          if (transaction.success) {
            if (
              !transaction.transactionType.name.includes("Purchase") &&
              !(transaction.transactionType.name === "League Transfer In")
            )
              transactionAmount -= transaction.transactionAmount;
            else transactionAmount += transaction.transactionAmount;
          }
        });
        return <div>{numberToMoney(transactionAmount)}</div>;
      },
    },
    {
      Header: "Options",
      accessor: (d: any) => {
        return (
          <div className={classes.format__options}>
            <div className={classes.format__option}>
              <Button
                variant="secondary"
                onClick={() => redirectShoppingCart(d.id)}
              >
                {<RemoveRedEye />}
              </Button>
            </div>
          </div>
        );
      },
    },
  ];

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => shoppingCarts, [shoppingCarts]);

  const ShoppingCartsTable = (
    <div className="mt-5 w-[95%]">
      <BaseTable
        columns={columns}
        data={data}
      />
      {pagination && (
        <div className="flex justify-end mt-4">
          <TablePagination
            page={pagination?.page || 0}
            pageCount={pagination?.pageCount || 0}
            onChange={handleChangePagination}
          />
        </div>
      )}
    </div>
  );

  return (
    <main>
      <LoadingDialog open={isLoading} />
      <h1>Shopping Cart List</h1>
      <div className={classes.container}>
        <Fragment>
          <div className="flex items-end justify-start gap-4">
            <div className="w-1/3">
              <FormFieldDate
                type="date"
                name="startDate"
                initialValue={shoppingCartsFilter.startDate}
                dateChange={(e: any) => handleChange("startDate", e)}
                label="Start date"
              ></FormFieldDate>
            </div>
            <div className="w-1/3">
              <FormFieldDate
                type="date"
                name="endDate"
                initialValue={shoppingCartsFilter.endDate}
                dateChange={(e: any) => handleChange("endDate", e)}
                label="End Date"
              ></FormFieldDate>
            </div>
            <Button
              variant="primary"
              onClick={() => onSubmit()}
              className="h-fit"
            >
              Apply filters
            </Button>

            {/* <div className='h-[95%] my-auto'>
                <Button
                  variant="secondary"
                  onClick={() => resetFilter()}
                >
                  Clear Filters
                </Button>
              </div> */}
          </div>
        </Fragment>
        {shoppingCarts.length > 0 && (
          <div className={classes.table__venues}>{ShoppingCartsTable}</div>
        )}
        {!(shoppingCarts.length > 0) && (
          <div className={classes.table__contracts}>No Data Found</div>
        )}
      </div>
    </main>
  );
};

export default ShoppingCarts;
