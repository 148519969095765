import { useMutation, useQuery } from "@apollo/client";
import { Divider, List, ListItem, ListItemText } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { CircleHelp, Trash2 } from "lucide-react";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import "react-datepicker/dist/react-datepicker.css";
import { FileUploader } from "react-drag-drop-files";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast as toastStackable } from "sonner";
import { z, ZodFormattedError } from "zod";
import { AppDispatch, RootState } from "../../../app/store";
import {
  getAllVenuesByVendorId,
  getVendors,
} from "../../../app/venueMasterSlice";
import {
  CREATE_CONTRACT,
  CREATE_CONTRACT_NOTE,
  GET_CONTRACT_APPROVAL_USERS,
  GET_CONTRACT_BY_ID,
  GET_CONTRACT_STATUSES,
  UPDATE_CONTRACT,
} from "../../../graphql/queries/contract";
import {
  CREATE_CONTRACT_ATTACHMENT,
  DELETE_CONTRACT_ATTACHMENT,
  GET_CONTRACT_ATTACHMENTS_BY_CONTRACT_ID,
} from "../../../graphql/queries/contractAttachment";
import { UserAccount } from "../../../types/types";
import BreadCrumbs from "../../UI/Breadcrumbs/Breadcrumbs";
import Button from "../../UI/Button/Button";
import Card from "../../UI/Card/Card";
import LoadingDialog from "../../UI/Dialog/LoadingDialog";
import FormField from "../../UI/FormField/FormField";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import TextArea from "../../UI/FormField/TextArea/TextArea";
import { Checkbox } from "../../UI/shadcn/checkbox";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../UI/shadcn/dialog";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../../UI/shadcn/hoverCard";
import { Label } from "../../UI/shadcn/label";
import { DatePicker } from "../../UI/shadcn/Time/date-picker";
import { TimePickerDemo } from "../../UI/shadcn/Time/time-picker-demo";
import Body1 from "../../UI/Text/Body/Body1";
import Caption1 from "../../UI/Text/Caption/Caption1";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import Headline2Variable from "../../UI/Text/Headline/Headline2Variable";
import Subtitle1 from "../../UI/Text/Subtitle/Subtitle1";
import FormFieldViewOnly from "../../UI/FormField/FormFieldViewOnly/FormFieldViewOnly";
import { dayOfWeek } from "../../../utils/dayOfWeek";
import { useRef } from "react";
import {
  convertLocalToJSDate,
  dateWithoutTimezone,
} from "../../../utils/timeFunctions";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import UndoIcon from "@mui/icons-material/Undo";
import Headline2 from "../../UI/Text/Headline/Headline2";
import { cn } from "../../../lib/utils";
import Disclaimer from "../../UI/Alerts/Disclaimer";
import { debounce } from "lodash";
import Subtitle2 from "../../UI/Text/Subtitle/Subtitle2";
import { multipartFetch } from "../../../app/authSlice";
import {
  displayAlertError,
  displayAlertSuccess,
  displayAlertWarning,
} from "../../../app/globalSlice";

dayjs.extend(utc);

const fileTypes = ["JPEG", "PNG", "GIF", "SVG", "PDF"];

// Define the individual schemas
const ContractItemSchema = z
  .object({
    id: z.number().optional(),
    contractId: z.number(),
    venueId: z.number().refine((value) => value !== 0, {
      message: "A venue must be selected for all contractItems",
    }),
    startDateTimeLocal: z.date(),
    endDateTimeLocal: z.date(),
    goesPastMidnight: z.boolean().optional(),
    day: z.number(),
    isExcluded: z.boolean(),
    isDeleted: z.boolean(),
  })
  .refine(
    (data) => {
      return data.startDateTimeLocal < data.endDateTimeLocal;
    },
    { message: "Start date must be before end date." }
  );

const ContractNoteSchema = z.object({
  id: z.number(),
  note: z.string(),
  createdAt: z.date(),
  createdByUser: z.object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
  }),
});

// Define the full contract schema
const ContractSchema = z
  .object({
    id: z.number().optional(),
    name: z.string().min(1, { message: "Name must cannot be empty." }),
    approvalStatusDate: z.string().optional(),
    grossCost: z.number(),
    taxes: z.number(),
    regionId: z.number(),
    vendorId: z.number(),
    approverId: z
      .string()
      .refine((val) => val != "", { message: "Approver must be selected" }),
    permitStatusId: z.number(),
    typeId: z.number(),
    approvalStatusId: z.number(),
    contractItems: z.array(ContractItemSchema),
    contractNotes: z.array(ContractNoteSchema),
  })
  .superRefine((data, ctx) => {
    // Custom validation for overlapping date-time values in contractItems
    const issues = checkOverlappingDates(data.contractItems);
    issues.map((issue) => {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: issue.message,
        path: ["contractItems", issue.index],
      });
    });
  })
  .superRefine((data, ctx) => {
    Object.entries(data).forEach(([key, value]) => {
      if (
        typeof value === "number" &&
        value === 0 &&
        key !== "grossCost" &&
        key !== "taxes" &&
        key !== "approvalStatusId"
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Please select an option.`,
          path: [key],
        });
      }
    });
  });

function checkOverlappingDates(
  allItems: ContractItemType[]
): { index: number; message: string }[] {
  const items = allItems;
  const issues: { index: number; message: string }[] = [];
  for (let i = 0; i < items.length; i++) {
    const itemA = items[i];
    for (let j = i + 1; j < items.length; j++) {
      const itemB = items[j];

      // Check if the items have the same venue and the same day and are not marked as deleted
      if (
        itemA.venueId === itemB.venueId &&
        itemA.day === itemB.day &&
        !itemA.isDeleted &&
        !itemB.isDeleted
      ) {
        // Check for overlap
        const startA = itemA.startDateTimeLocal.getTime();
        const endA = itemA.endDateTimeLocal.getTime();
        const startB = itemB.startDateTimeLocal.getTime();
        const endB = itemB.endDateTimeLocal.getTime();

        if (
          (startA <= endB && startB < endA) ||
          (startB < endA && startA <= endB)
        ) {
          issues.push({
            index: i,
            message: `Contract items on ${dayjs(
              itemA.startDateTimeLocal
            ).format("MMMM D, YYYY")} have overlapping date-time values.`,
          });
        }
      }
    }
  }
  return issues;
}

interface GroupedItems {
  [key: string]: ContractItemType[];
}

const groupByDay = (items: ContractItemType[]): GroupedItems => {
  return items.reduce((acc: GroupedItems, item: ContractItemType) => {
    const day = dayOfWeek.find((d) => d.id === item.day)?.name;
    if (day) {
      if (!acc[day]) {
        acc[day] = [];
      }
      acc[day].push(item);
    }
    return acc;
  }, {});
};

const groupByDateRanges = (items: ContractItemType[]): string[] => {
  const timeRanges: { [key: string]: number } = {};

  items.forEach((item) => {
    const startTime = item.startDateTimeLocal.toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
      hour12: false,
    });
    const endTime = item.endDateTimeLocal.toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
      hour12: false,
    });
    const range = `${startTime} - ${endTime}`;

    if (!timeRanges[range]) {
      timeRanges[range] = 0;
    }
    timeRanges[range]++;
  });

  return Object.entries(timeRanges).map(
    ([range, count]) => `${count} at ${range}`
  );
};

interface ContractItemsSummaryProps {
  items: ContractItemType[];
}
const ContractItemsSummary = React.memo(
  ({ items }: ContractItemsSummaryProps) => {
    const groupedItems = useMemo(
      () => groupByDay(items.filter((item) => !item.isExcluded)),
      [items]
    );
    const groupedItemsExclusion = useMemo(
      () => groupByDay(items.filter((item) => item.isExcluded)),
      [items]
    );
    const dateRanges = useMemo(() => {
      const ranges: { [key: string]: string[] } = {};
      Object.keys(groupedItems).forEach((day) => {
        ranges[day] = groupByDateRanges(groupedItems[day]);
      });
      return ranges;
    }, [groupedItems]);
    const dateRangesExclusion = useMemo(() => {
      const ranges: { [key: string]: string[] } = {};
      Object.keys(groupedItemsExclusion).forEach((day) => {
        ranges[day] = groupByDateRanges(groupedItemsExclusion[day]);
      });
      return ranges;
    }, [groupedItemsExclusion]);

    return (
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          {dayOfWeek
            .filter((day) => dateRanges[day.name] !== undefined)
            .map((day) => (
              <div
                key={day.id}
                className="flex flex-col gap-1"
              >
                <Headline2 size="s-xs">{day.name}</Headline2>
                <ul>
                  {dateRanges[day.name] &&
                    dateRanges[day.name].map((range, index) => (
                      <Body1 key={index}>{range}</Body1>
                    ))}
                </ul>
              </div>
            ))}
        </div>
        <div className="flex flex-col gap-2">
          <Headline2 size="xl-m">Excluded Dates</Headline2>
          {dayOfWeek
            .filter((day) => dateRangesExclusion[day.name] !== undefined)
            .map((day) => (
              <div
                key={day.id}
                className="flex flex-col gap-1"
              >
                <Headline2 size="s-xs">{day.name}</Headline2>
                <ul>
                  {dateRangesExclusion[day.name] &&
                    dateRangesExclusion[day.name].map((range, index) => (
                      <Body1 key={index}>{range}</Body1>
                    ))}
                </ul>
              </div>
            ))}
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.items === nextProps.items;
  }
);

const handleContractNoteChange = (
  text: string,
  setContractNote: React.Dispatch<React.SetStateAction<string>>
) => {
  setContractNote(text);
};

const handleInputChange = (
  name: string,
  value: any,
  setContract: React.Dispatch<React.SetStateAction<ContractType>>
) => {
  setContract((prevState) => ({
    ...prevState,
    [name]: value,
  }));
};

// Debounce the function to reduce frequent state updates
const debouncedHandleInputChangeString = debounce(handleInputChange, 300);
const debouncedHandleInputChange = debounce(handleInputChange, 50);
const debouncedHandleContractNoteChange = debounce(
  handleContractNoteChange,
  300
);

// Memoized callback for handling state changes
const useHandleInputChange = (
  setContract: React.Dispatch<React.SetStateAction<ContractType>>
) =>
  useCallback(
    (name: string, value: any) => {
      if (typeof value === typeof "") {
        debouncedHandleInputChangeString(name, value, setContract);
      } else {
        debouncedHandleInputChange(name, value, setContract);
      }
    },
    [setContract]
  );

const useHandleContractNoteChange = (
  setContractNote: React.Dispatch<React.SetStateAction<string>>
) =>
  useCallback(
    (text: string) => {
      debouncedHandleContractNoteChange(text, setContractNote);
    },
    [setContractNote]
  );

// Utility function to handle contract item state changes
const handleContractItemChange = (
  index: number,
  changes: { name: string; value: any }[],
  setContract: React.Dispatch<React.SetStateAction<ContractType>>
) => {
  setContract((prevState) => ({
    ...prevState,
    contractItems: prevState.contractItems.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          ...changes.reduce(
            (acc, change) => ({ ...acc, [change.name]: change.value }),
            {}
          ),
        };
      }
      return item;
    }),
  }));
};

// Debounce the function to reduce frequent state updates
const debouncedHandleContractItemChange = debounce(
  handleContractItemChange,
  50
);

// Memoized callback for handling contract item state changes
const useHandleContractItemChange = (
  setContract: React.Dispatch<React.SetStateAction<ContractType>>
) =>
  useCallback(
    (index: number, changes: { name: string; value: any }[]) => {
      debouncedHandleContractItemChange(index, changes, setContract);
    },
    [setContract]
  );

type ContractType = z.infer<typeof ContractSchema>;
type ContractItemType = z.infer<typeof ContractItemSchema>;
type TempContractItemType = Omit<
  ContractItemType,
  "startDateTimeLocal" | "endDateTimeLocal"
> & {
  startDateTimeLocal: string;
  endDateTimeLocal: string;
};
type ContractNoteType = z.infer<typeof ContractNoteSchema>;
type TempContractNoteType = Omit<ContractNoteType, "createdAt"> & {
  createdAt: string;
};
const initialContract: ContractType = {
  id: undefined,
  name: "",
  approvalStatusDate: undefined,
  grossCost: 0,
  taxes: 0,
  regionId: 0,
  vendorId: 0,
  approverId: "",
  permitStatusId: 0,
  typeId: 0,
  approvalStatusId: 0,
  contractItems: [],
  contractNotes: [],
};

const initialContractItem: ContractItemType = {
  id: undefined,
  contractId: 0,
  venueId: 0,
  startDateTimeLocal: dayjs()
    .set("hour", 18)
    .set("minute", 0)
    .set("second", 0)
    .toDate(),
  endDateTimeLocal: dayjs()
    .set("hour", 18)
    .set("minute", 0)
    .set("second", 0)
    .toDate(),
  goesPastMidnight: false,
  day: dayjs().get("day"),
  isExcluded: false,
  isDeleted: false,
};

type StatusType = {
  id: number;
  name: string;
};

type ContractAttachment = {
  id: number;
  originalFileName: string;
  gcpFileName: string;
  publicUrl: string;
};

const EditContract: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const { isLoading, vendorList, venueList, selectedRegions } = useSelector(
    (state: RootState) => state.venueMaster
  );

  const params = useParams();
  const { id } = params;

  const [contract, setContract] = useState<ContractType>(initialContract);
  const [contractItemLength, setPrevContractItemLength] = useState(0);
  const [contractNote, setContractNote] = useState<string>("");
  const [contractAttachment, setContractAttachment] = useState<
    ContractAttachment[]
  >([]);
  const [approvalUsers, setApprovalUsers] = useState<Partial<UserAccount>[]>(
    []
  );
  const [permitStatuses, setPermitStatuses] = useState<StatusType[]>([]);
  const [contractTypes, setContractTypes] = useState<StatusType[]>([]);
  const [files, setFiles] = useState<FileList | null>(null);
  const [allVenue, setAllVenue] = useState<number>(0);
  const [openConfimrModal, setOpenConfirmModal] = useState<boolean>(false);

  const [zodErrors, setZodErrors] = useState<
    ZodFormattedError<ContractType, string>
  >({ _errors: [] });

  const newElementRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const topElement = useRef<HTMLDivElement>(null);

  const handleInputChange = useHandleInputChange(setContract);
  const handleContractItemChange = useHandleContractItemChange(setContract);
  const handleContractNoteChange = useHandleContractNoteChange(setContractNote);

  const { loading: loadingContract } = useQuery(GET_CONTRACT_BY_ID, {
    variables: {
      id: id ? +id : 0,
    },
    skip: id === null || id === undefined,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data) {
        // For each contract recreate a real date from the local dateTime strings
        const tempContractItems: ContractItemType[] =
          data.contractById.contractItems.map(
            (contractItem: TempContractItemType) => {
              const startDateTimeLocal = convertLocalToJSDate(
                data.contractById.regionId,
                contractItem.startDateTimeLocal
              );
              const endDateTimeLocal = convertLocalToJSDate(
                data.contractById.regionId,
                contractItem.endDateTimeLocal
              );
              return {
                ...contractItem,
                startDateTimeLocal: startDateTimeLocal,
                endDateTimeLocal: endDateTimeLocal,
                goesPastMidnight:
                  endDateTimeLocal.getDate() !== startDateTimeLocal.getDate(),
              };
            }
          );
        const tempContractNotes: ContractNoteType[] =
          data.contractById.contractNotes.map(
            (contractNote: TempContractNoteType) => {
              return {
                ...contractNote,
                createdAt: new Date(contractNote.createdAt),
              };
            }
          );
        setContract({
          id: data.contractById.id,
          name: data.contractById.name,
          approvalStatusDate: data.contractById.approvalStatusDate,
          grossCost: data.contractById.grossCost,
          taxes: data.contractById.taxes,
          regionId: data.contractById.regionId,
          vendorId: data.contractById.vendorId,
          approverId: data.contractById.approverId,
          permitStatusId: data.contractById.permitStatusId,
          typeId: data.contractById.typeId,
          approvalStatusId: data.contractById.approvalStatusId,
          contractNotes: tempContractNotes,
          contractItems: tempContractItems,
        });
      }
    },
  });

  const { loading: loadingContractApprovalUsers } = useQuery(
    GET_CONTRACT_APPROVAL_USERS,
    {
      variables: {
        regionIds: selectedRegions.map((region: any) => +region.id),
      },
      onCompleted: (data) => {
        if (data) {
          const users = data.getContractApprovalUsers.map(
            (user: UserAccount) => {
              return {
                id: user.id,
                name: user.firstName + " " + user.lastName,
              };
            }
          );
          setApprovalUsers(users);
        }
      },
    }
  );

  const { loading: loadingContractAttachment } = useQuery(
    GET_CONTRACT_ATTACHMENTS_BY_CONTRACT_ID,
    {
      variables: {
        id: id ? +id : 0,
      },
      skip: id === null || id === undefined,
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data) {
          setContractAttachment(data.getContractAttachmentsByContractId);
        }
      },
    }
  );

  const { loading: loadingContractStatuses } = useQuery(GET_CONTRACT_STATUSES, {
    onCompleted: (data) => {
      if (data) {
        setPermitStatuses(data.getContractStatuses.permitStatus);
        setContractTypes(data.getContractStatuses.contractType);
      }
    },
  });

  const [createContract, { loading: loadingCreateContract }] =
    useMutation(CREATE_CONTRACT);

  const [updateContract, { loading: loadingUpdateContract }] =
    useMutation(UPDATE_CONTRACT);

  const [createContractNote, { loading: loadingCreateContractAttachment }] =
    useMutation(CREATE_CONTRACT_NOTE, {
      refetchQueries: [GET_CONTRACT_BY_ID, "contractById"],
    });

  const [createContractAttachment] = useMutation(CREATE_CONTRACT_ATTACHMENT, {
    refetchQueries: [
      GET_CONTRACT_ATTACHMENTS_BY_CONTRACT_ID,
      "getContractAttachmentsByContractId",
    ],
    onCompleted: (data) => {
      if (data.createContractAttachment.success) {
        dispatch(displayAlertSuccess(data.createContractAttachment.message));
      } else {
        dispatch(displayAlertError(data.createContractAttachment.message));
      }
    },
  });

  const [
    deleteContractAttachment,
    { loading: loadingDeleteContractAttachment },
  ] = useMutation(DELETE_CONTRACT_ATTACHMENT, {
    refetchQueries: [
      GET_CONTRACT_ATTACHMENTS_BY_CONTRACT_ID,
      "getContractAttachmentsByContractId",
    ],
    onCompleted: (data) => {
      if (data.deleteContractAttachment.success) {
        dispatch(displayAlertSuccess(data.deleteContractAttachment.message));
      } else {
        dispatch(displayAlertError(data.deleteContractAttachment.message));
      }
    },
  });

  useEffect(() => {
    dispatch(getVendors({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllVenuesByVendorId(contract.vendorId));
  }, [contract.vendorId]);

  useEffect(() => {
    if (
      contract.contractItems.length > contractItemLength &&
      newElementRef.current &&
      containerRef.current
    ) {
      newElementRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      containerRef.current.scrollBy({ top: 185, behavior: "smooth" });
      setPrevContractItemLength(contract.contractItems.length);
    } else if (
      contract.contractItems.length < contractItemLength &&
      newElementRef.current &&
      containerRef.current
    ) {
      newElementRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      containerRef.current.scrollBy({ top: -185, behavior: "smooth" });
      setPrevContractItemLength(contract.contractItems.length);
    }
  }, [contract.contractItems, contractItemLength]);

  async function onSubmit() {
    // Error Handling: Make sure that venues are from choosen vendor only!
    // Make sure if vendor resets/changes then venues are reset to 0.
    // apply validations
    const validationResult = ContractSchema.safeParse(contract);

    if (!validationResult.success) {
      validationResult.error.issues.map((issue) => {
        dispatch(displayAlertError(issue.message));
      });
      return;
    }

    // If there is no contract Id make a new one
    if (!contract.id) {
      const createResult = await createContract({
        variables: {
          createContractInput: {
            contract: {
              id: undefined,
              regionId: +contract.regionId,
              vendorId: +contract.vendorId,
              typeId: +contract.typeId,
              name: contract.name,
              permitStatusId: +contract.permitStatusId,
              approverId: contract.approverId,
              grossCost: contract.grossCost,
              taxes: contract.taxes,
            },
            contractItems: contract.contractItems.map((contractItem) => {
              return {
                venueId: +contractItem.venueId,
                isExcluded: contractItem.isExcluded,
                day: contractItem.day,
                startDateTimeLocal: dateWithoutTimezone(
                  contractItem.startDateTimeLocal
                ),
                endDateTimeLocal: dateWithoutTimezone(
                  contractItem.endDateTimeLocal
                ),
                contractId: contractItem.contractId,
              };
            }),
          },
        },
      }).catch((err) => {
        dispatch(displayAlertError(err));
      });
      if (createResult) {
        const success = createResult.data.contractCreate.success;
        if (success) {
          dispatch(
            displayAlertSuccess(createResult.data.contractCreate.message)
          );
        } else {
          dispatch(displayAlertError(createResult.data.contractCreate.message));
        }
        // Once the contract is succesfully created create a note for it.
        const noteResult = await createContractNote({
          variables: {
            createContractNoteInput: {
              note: success
                ? contractNote
                : `Attempted create with note: ${contractNote}`,
              contractId: createResult.data.contractCreate.contract.id,
            },
          },
        });
        if (noteResult) {
          if (noteResult.data.createContractNote.success) {
            dispatch(
              displayAlertSuccess(noteResult.data.createContractNote.message)
            );
          } else {
            dispatch(
              displayAlertError(noteResult.data.createContractNote.message)
            );
          }
        } else {
          dispatch(displayAlertError("Something went wrong making the note"));
        }
        navigate("/ops/contracts");
      } else {
        dispatch(
          displayAlertError("Something went wrong updating the contract")
        );
      }
    } else {
      // update the existing contract
      const updateResult = await updateContract({
        variables: {
          updateContractInput: {
            contract: {
              id: contract.id,
              regionId: +contract.regionId,
              vendorId: +contract.vendorId,
              typeId: +contract.typeId,
              name: contract.name,
              permitStatusId: +contract.permitStatusId,
              approverId: contract.approverId,
              grossCost: contract.grossCost,
              taxes: contract.taxes,
            },
            contractItems: contract.contractItems.map((contractItem) => {
              return {
                id: contractItem.id,
                venueId: +contractItem.venueId,
                day: contractItem.day,
                startDateTimeLocal: dateWithoutTimezone(
                  contractItem.startDateTimeLocal
                ),
                endDateTimeLocal: dateWithoutTimezone(
                  contractItem.endDateTimeLocal
                ),
                contractId: contractItem.contractId,
                isExcluded: contractItem.isExcluded,
                isDeleted: contractItem.isDeleted,
              };
            }),
          },
        },
      }).catch((err) => {
        dispatch(displayAlertError(err));
      });
      if (updateResult) {
        const success = updateResult.data.contractUpdate.success;
        if (success) {
          dispatch(
            displayAlertSuccess(updateResult.data.contractUpdate.message)
          );
        } else {
          dispatch(displayAlertError(updateResult.data.contractUpdate.message));
        }
        // Once the contract is succesfully created create a note for it.
        const noteResult = await createContractNote({
          variables: {
            createContractNoteInput: {
              note: success
                ? contractNote
                : `Attempted update with note: ${contractNote}`,
              contractId: updateResult.data.contractUpdate.contract.id,
            },
          },
        });
        if (noteResult) {
          if (noteResult.data.createContractNote.success) {
            dispatch(
              displayAlertSuccess(noteResult.data.createContractNote.message)
            );
          } else {
            dispatch(
              displayAlertError(noteResult.data.createContractNote.message)
            );
          }
        } else {
          dispatch(displayAlertError("Something went wrong making the note"));
        }
      } else {
        dispatch(
          displayAlertError("Something went wrong updating the contract")
        );
      }
    }
  }

  const handleUpload = async () => {
    if (!files) return;
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    try {
      const response = await multipartFetch.post("/upload", formData);
      await createContractAttachment({
        variables: {
          createContractAttachmentInput: {
            contractId: contract.id,
            contractAttachments: response.data.files.map(
              (file: {
                originalFileName: string;
                gcpFileName: string;
                url: string;
              }) => {
                return {
                  originalFileName: file.originalFileName,
                  gcpFileName: file.gcpFileName,
                  publicUrl: file.url,
                };
              }
            ),
          },
        },
      });
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const pathsBreadcrumbs = [{ name: "Contracts", url: "/ops/contracts" }];

  if (
    isLoading ||
    loadingContract ||
    loadingContractApprovalUsers ||
    loadingContractStatuses ||
    loadingContractAttachment ||
    loadingCreateContract ||
    loadingUpdateContract
  )
    return <LoadingDialog open={true} />;

  return (
    <main
      ref={containerRef}
      className="max-h-screen pb-10 overflow-auto"
    >
      <div ref={topElement}></div>
      <BreadCrumbs
        paths={pathsBreadcrumbs}
        goBackTo="/ops/contracts"
      ></BreadCrumbs>
      <div className="flex flex-row items-center justify-between w-full max-h-screen mt-6">
        <Headline1Variable>
          {contract.id ? "Edit Contract" : "Create New Contract"}
        </Headline1Variable>

        <div className="h-10">
          <Button
            variant={"primary"}
            onClick={() => {
              const validationResult = ContractSchema.safeParse(contract);
              if (!validationResult.success) {
                setZodErrors(validationResult.error.format());
                dispatch(
                  displayAlertWarning("There is an issue with the form")
                );
                return;
              }
              setOpenConfirmModal(true);
            }}
          >
            {contract.id ? (
              <span> Update Contract</span>
            ) : (
              <span> Create Contract</span>
            )}
          </Button>
        </div>

        <Dialog
          open={openConfimrModal}
          onOpenChange={setOpenConfirmModal}
        >
          <DialogContent className="max-w-[600px]">
            <DialogHeader>
              <DialogTitle>
                {contract.id ? (
                  <span>Update Contract</span>
                ) : (
                  <span>Create Contract</span>
                )}
              </DialogTitle>
            </DialogHeader>
            <DialogDescription>
              <div className="flex flex-col gap-4">
                <Body1>
                  This note should reflect the changes you made to the contract.
                </Body1>
                <TextArea
                  className="mb-4"
                  name="notes"
                  label="Notes"
                  inputChange={(event) => {
                    handleContractNoteChange(event.target.value);
                  }}
                  value={contractNote}
                  rows={8}
                  cols={50}
                ></TextArea>
              </div>
            </DialogDescription>
            <DialogClose asChild>
              <div className="flex flex-row justify-between gap-4">
                <Button
                  variant="primary"
                  onClick={() => onSubmit()}
                  className="w-full"
                >
                  Confirm
                </Button>
                <Button
                  className="w-full"
                  variant="negative"
                >
                  Close
                </Button>
              </div>
            </DialogClose>
          </DialogContent>
        </Dialog>
      </div>
      <div className="flex flex-col gap-6">
        <Card>
          <Headline2Variable>Contract Details</Headline2Variable>
          <div className="flex flex-col">
            <div className="grid grid-cols-3 gap-6 mt-4 w-max-xl">
              <FormField
                type="name"
                name="name"
                id="name"
                value={contract.name}
                label="Name"
                inputChange={(value) => {
                  if (zodErrors.name && value) {
                    setZodErrors((prevState) => ({
                      _errors: prevState?._errors ?? [],
                      name: undefined,
                    }));
                  }
                  handleInputChange("name", value.target.value);
                }}
                error={zodErrors.name !== undefined}
                assistiveText={
                  zodErrors.name !== undefined ? zodErrors.name._errors[0] : ""
                }
                initialValue={contract.name}
              />
              <FormFieldSelect
                name="regions"
                id="regions"
                value={contract.regionId ? contract.regionId.toString() : "0"}
                inputChange={(value) => {
                  if (zodErrors.regionId && value) {
                    setZodErrors((prevState) => ({
                      _errors: prevState?._errors ?? [],
                      regionId: undefined,
                    }));
                  }
                  handleInputChange("regionId", +value);
                }}
                error={zodErrors.regionId !== undefined}
                assistiveText={
                  zodErrors.regionId !== undefined
                    ? zodErrors.regionId._errors[0]
                    : ""
                }
                label="Region"
                placeholder="Select Region"
                className="m-0"
              >
                {[
                  ...[{ id: "0", name: "Select a Region" }],
                  ...selectedRegions,
                ]}
              </FormFieldSelect>
              <FormFieldSelect
                name="vendors"
                id="vendors"
                value={contract.vendorId ? contract.vendorId.toString() : "0"}
                inputChange={(value) => {
                  if (zodErrors.vendorId && value) {
                    setZodErrors((prevState) => ({
                      _errors: prevState?._errors ?? [],
                      vendorId: undefined,
                    }));
                  }
                  handleInputChange("vendorId", +value);
                }}
                error={zodErrors.vendorId !== undefined}
                assistiveText={
                  zodErrors.vendorId !== undefined
                    ? zodErrors.vendorId._errors[0]
                    : ""
                }
                label="vendor"
                placeholder="Select vendor"
                className="m-0"
              >
                {[...[{ id: "0", name: "Select a Vendor" }], ...vendorList]}
              </FormFieldSelect>
            </div>
            <div className="grid grid-cols-3 gap-6 mt-4 w-max-xl">
              <FormFieldSelect
                name="typeId"
                id="typeId"
                value={contract.typeId ? contract.typeId.toString() : "0"}
                placeholder="All"
                label="Type"
                className="m-0"
                inputChange={(value) => {
                  if (zodErrors.typeId && value) {
                    setZodErrors((prevState) => ({
                      _errors: prevState?._errors ?? [],
                      typeId: undefined,
                    }));
                  }
                  handleInputChange("typeId", +value);
                }}
                error={zodErrors.typeId !== undefined}
                assistiveText={
                  zodErrors.typeId !== undefined
                    ? zodErrors.typeId._errors[0]
                    : ""
                }
              >
                {[{ id: "0", name: "Select Contract Type" }, ...contractTypes]}
              </FormFieldSelect>
              <FormFieldSelect
                value={
                  contract.permitStatusId
                    ? contract.permitStatusId.toString()
                    : "0"
                }
                className="m-0"
                name="permitStatusId"
                id="permitStatusId"
                placeholder="All"
                inputChange={(value) => {
                  if (zodErrors.permitStatusId && value) {
                    setZodErrors((prevState) => ({
                      _errors: prevState?._errors ?? [],
                      permitStatusId: undefined,
                    }));
                  }
                  handleInputChange("permitStatusId", +value);
                }}
                error={zodErrors.permitStatusId !== undefined}
                assistiveText={
                  zodErrors.permitStatusId !== undefined
                    ? zodErrors.permitStatusId._errors[0]
                    : ""
                }
                label="Permit Status"
              >
                {[{ id: "0", name: "Select Permit Status" }, ...permitStatuses]}
              </FormFieldSelect>
              <FormFieldSelect
                value={contract.approverId ? contract.approverId : "0"}
                name="approverId"
                id="approverId"
                placeholder="All"
                className="m-0"
                inputChange={(value) => {
                  if (zodErrors.approverId && value) {
                    setZodErrors((prevState) => ({
                      _errors: prevState?._errors ?? [],
                      approverId: undefined,
                    }));
                  }
                  handleInputChange("approverId", value);
                }}
                error={zodErrors.approverId !== undefined}
                assistiveText={
                  zodErrors.approverId !== undefined
                    ? zodErrors.approverId._errors[0]
                    : ""
                }
                label="Approver"
              >
                {[{ id: "0", name: "Select Approver" }, ...approvalUsers]}
              </FormFieldSelect>
            </div>
            <div className="grid grid-cols-1 gap-6 mt-4 md:grid-cols-3 md:max-xl">
              <FormField
                type="currency"
                id="grossCost"
                name="grossCost"
                label="Gross Cost"
                initialValue={
                  contract.grossCost ? contract.grossCost.toString() : "0"
                }
                inputChange={(e: any) =>
                  handleInputChange("grossCost", +e.target.value)
                }
              />
              <FormField
                type="currency"
                id="taxes"
                name="taxes"
                label="Taxes"
                initialValue={contract.taxes ? contract.taxes.toString() : "0"}
                inputChange={(value) =>
                  handleInputChange("taxes", +value.target.value)
                }
              ></FormField>
            </div>
          </div>
        </Card>
        <Card className="flex flex-col gap-4">
          <Headline2Variable>Contract Item Summary</Headline2Variable>
          <ContractItemsSummary items={contract.contractItems} />
        </Card>
        <Card className="flex flex-col gap-4">
          <Headline2Variable>Contract Items</Headline2Variable>
          {contract.contractItems.length > 0 && (
            <div className="flex flex-col gap-4">
              <FormFieldSelect
                name="venues"
                value={allVenue.toString()}
                inputChange={(value) => {
                  setContract((prevState) => ({
                    ...prevState,
                    contractItems: prevState.contractItems.map(
                      (item, index) => {
                        return {
                          ...item,
                          venueId: +value,
                        };
                      }
                    ),
                  }));
                  setAllVenue(value);
                }}
                label="Set All Venues"
                placeholder="Select Venue"
                className="h-full m-0 min-w-[550px] max-w-[550px]"
                containerClassName="w-fit"
              >
                {[...[{ id: "0", name: "Select all venue" }], ...venueList]}
              </FormFieldSelect>
              {contract.contractItems.map((contractItem, contractItemIndex) => {
                const isLastItem =
                  contractItemIndex === contract.contractItems.length - 1;
                return (
                  <div
                    className={cn("flex flex-col gap-4")}
                    key={contractItemIndex}
                    ref={isLastItem ? newElementRef : null}
                  >
                    <div className="flex flex-col gap-2 w-fit">
                      <div className="flex flex-row items-end justify-between gap-8">
                        <FormFieldSelect
                          name="venues"
                          disabled={contractItem.isDeleted}
                          value={
                            contractItem.venueId
                              ? contractItem.venueId.toString()
                              : "0"
                          }
                          inputChange={(value) =>
                            handleContractItemChange(contractItemIndex, [
                              { name: "venueId", value: +value },
                            ])
                          }
                          label="Venue"
                          placeholder="Select Venue"
                          className="h-full m-0 min-w-[550px] max-w-[550px]"
                          containerClassName="w-fit"
                        >
                          {[
                            ...[{ id: "0", name: "Select a Venue" }],
                            ...venueList,
                          ]}
                        </FormFieldSelect>
                        <div className="flex flex-row gap-2 h-[40px] items-center">
                          <Checkbox
                            className="hover:bg-secondary-opacity-08 flex h-[25px] w-[25px] min-w-[25px] min-h-[25px] border-2 border-neutral-20 appearance-none items-center justify-center rounded-sm bg-white outline-none focus:outline-none data-[state=checked]:bg-primary-50 data-[state=checked]:border-none"
                            checked={contractItem.isExcluded}
                            disabled={contractItem.isDeleted}
                            onCheckedChange={(value: boolean) => {
                              handleContractItemChange(contractItemIndex, [
                                { name: "isExcluded", value: value },
                              ]);
                            }}
                            id={`isExcluded-${contractItemIndex}`}
                            aria-label="isExcluded"
                          />
                          <Label
                            htmlFor={`isExcluded-${contractItemIndex}`}
                            className="hover:cursor-pointer"
                          >
                            <Body1 className=" whitespace-nowrap">
                              Exclusion Date
                            </Body1>
                          </Label>
                        </div>
                        {contractItem.isDeleted ? (
                          <Button
                            variant="secondary"
                            onClick={() => {
                              handleContractItemChange(contractItemIndex, [
                                { name: "isDeleted", value: false },
                              ]);
                            }}
                          >
                            <UndoIcon />
                          </Button>
                        ) : (
                          <Button
                            variant="negative"
                            onClick={() => {
                              if (contractItem.id === undefined) {
                                setContract((prevState) => ({
                                  ...prevState,
                                  contractItems: prevState.contractItems.filter(
                                    (item, index) => contractItemIndex !== index
                                  ),
                                }));
                              } else {
                                handleContractItemChange(contractItemIndex, [
                                  { name: "isDeleted", value: true },
                                ]);
                              }
                            }}
                          >
                            <Trash2 />
                          </Button>
                        )}
                      </div>
                      <div className="flex flex-row items-end justify-between gap-8">
                        <FormFieldViewOnly
                          label="Day"
                          text={
                            dayOfWeek.find(
                              (day) =>
                                day.id ===
                                contractItem.startDateTimeLocal.getDay()
                            )?.name || "Error"
                          }
                          className="h-10 max-w-52 w-52"
                          containerClassName="w-fit"
                          disabled={contractItem.isDeleted}
                        />
                        <DatePicker
                          className="w-52"
                          label="Date"
                          date={contractItem.startDateTimeLocal}
                          disabled={contractItem.isDeleted}
                          setDate={(date) => {
                            if (date) {
                              contract.contractItems.map((item, index) => {
                                if (contractItemIndex === index) {
                                  const newEndDate = dayjs(
                                    item.endDateTimeLocal
                                  );
                                  const newStartDate = dayjs(date)
                                    .set(
                                      "hour",
                                      contractItem.startDateTimeLocal.getHours()
                                    )
                                    .set(
                                      "minute",
                                      contractItem.startDateTimeLocal.getMinutes()
                                    )
                                    .set(
                                      "second",
                                      contractItem.startDateTimeLocal.getSeconds()
                                    );
                                  const day = newStartDate.get("day");
                                  handleContractItemChange(contractItemIndex, [
                                    {
                                      name: "startDateTimeLocal",
                                      value: newStartDate.toDate(),
                                    },
                                    { name: "day", value: day },
                                    {
                                      name: "endDateTimeLocal",
                                      value: newStartDate
                                        .set("hour", newEndDate.get("hour"))
                                        .set("minute", newEndDate.get("minute"))
                                        .toDate(),
                                    },
                                  ]);
                                }
                              });
                            }
                          }}
                        />
                        {/* <DatePicker
                          className="w-52"
                          label="End Date"
                          date={contractItem.endDateTimeLocal}
                          disabled={contractItem.isDeleted}
                          setDate={(date) => {
                            if (date) {
                              contract.contractItems.map((item, index) => {
                                if (contractItemIndex === index) {
                                  const newEndDate = dayjs(date)
                                    .set(
                                      "hour",
                                      contractItem.endDateTimeLocal.getHours()
                                    )
                                    .set(
                                      "minute",
                                      contractItem.endDateTimeLocal.getMinutes()
                                    )
                                    .set(
                                      "second",
                                      contractItem.endDateTimeLocal.getSeconds()
                                    );
                                  handleContractItemChange(contractItemIndex, [
                                    {
                                      name: "endDateTimeLocal",
                                      value: newEndDate.toDate(),
                                    },
                                  ]);
                                }
                              });
                            }
                          }}
                        /> */}
                        <div className="flex flex-col gap-2">
                          <div className="flex flex-row gap-2">
                            <Caption1 className="pl-3">Start Time</Caption1>
                          </div>
                          <TimePickerDemo
                            includeClock={false}
                            minuteStep={5}
                            id={`startTime-${contractItemIndex.toString()}`}
                            excludeSecond={true}
                            date={contractItem.startDateTimeLocal}
                            disabled={contractItem.isDeleted}
                            setDate={(date) => {
                              if (date) {
                                contract.contractItems.map((item, index) => {
                                  if (contractItemIndex === index) {
                                    handleContractItemChange(
                                      contractItemIndex,
                                      [
                                        {
                                          name: "startDateTimeLocal",
                                          value: date,
                                        },
                                      ]
                                    );
                                  }
                                  return item;
                                });
                              }
                            }}
                          />
                        </div>
                        <div className="flex flex-col gap-1">
                          <div className="flex flex-row gap-2">
                            <Caption1 className="pl-3">End Time</Caption1>
                          </div>
                          <TimePickerDemo
                            includeClock={false}
                            minuteStep={5}
                            id={`endTime-${contractItemIndex.toString()}`}
                            excludeSecond={true}
                            date={contractItem.endDateTimeLocal}
                            disabled={contractItem.isDeleted}
                            setDate={(date) => {
                              if (date) {
                                contract.contractItems.map((item, index) => {
                                  if (contractItemIndex === index) {
                                    handleContractItemChange(
                                      contractItemIndex,
                                      [
                                        {
                                          name: "endDateTimeLocal",
                                          value: date,
                                        },
                                      ]
                                    );
                                  }
                                  return item;
                                });
                              }
                            }}
                          />
                        </div>
                        <div className="flex flex-row gap-2 pb-2 w-fit">
                          <Checkbox
                            className="hover:bg-secondary-opacity-08 flex h-[25px] w-[25px] min-w-[25px] min-h-[25px] border-2 border-neutral-20 appearance-none items-center justify-center rounded-sm bg-white outline-none focus:outline-none data-[state=checked]:bg-primary-50 data-[state=checked]:border-none"
                            checked={contractItem.goesPastMidnight}
                            disabled={contractItem.isDeleted}
                            onCheckedChange={(value: boolean) => {
                              const newEndDate = dayjs(
                                contractItem.startDateTimeLocal
                              )
                                .add(value ? 1 : 0, "day")
                                .set(
                                  "hour",
                                  value
                                    ? contractItem.endDateTimeLocal.getHours()
                                    : contractItem.startDateTimeLocal.getHours()
                                )
                                .set(
                                  "minute",
                                  value
                                    ? contractItem.endDateTimeLocal.getMinutes()
                                    : contractItem.startDateTimeLocal.getMinutes()
                                )
                                .set(
                                  "second",
                                  value
                                    ? contractItem.endDateTimeLocal.getSeconds()
                                    : contractItem.startDateTimeLocal.getSeconds()
                                );
                              handleContractItemChange(contractItemIndex, [
                                { name: "goesPastMidnight", value: value },
                                {
                                  name: "endDateTimeLocal",
                                  value: newEndDate.toDate(),
                                },
                              ]);
                            }}
                            id={`goesPastMidnight-${contractItemIndex}`}
                            aria-label="goesPastMidnight"
                          />
                          <Label
                            htmlFor={`goesPastMidnight-${contractItemIndex}`}
                            className="hover:cursor-pointer"
                          >
                            <Body1 className=" whitespace-nowrap">
                              Past Midnight?
                            </Body1>
                          </Label>
                          <HoverCard>
                            <HoverCardTrigger asChild>
                              <CircleHelp className="w-4 h-4 text-light hover:text-dark hover:cursor-pointer" />
                            </HoverCardTrigger>
                            <HoverCardContent>
                              <Body1>
                                This will make the end date for the contract
                                item the next day. The end time should be the
                                time the contract item ends the day after ex.
                                1:00 AM.
                              </Body1>
                            </HoverCardContent>
                          </HoverCard>
                        </div>
                      </div>
                    </div>
                    <Divider />
                  </div>
                );
              })}
            </div>
          )}
          <div className="flex flex-row gap-4">
            <Button
              variant="primary"
              className="w-fit"
              onClick={() => {
                setContract((prevState) => ({
                  ...prevState,
                  contractItems: [
                    ...prevState.contractItems,
                    initialContractItem,
                  ],
                }));
              }}
            >
              New Contract Item
            </Button>
            {contract.contractItems.length > 0 && (
              <div className="flex flex-row gap-4">
                <Button
                  variant="primary"
                  className="w-fit"
                  onClick={() => {
                    // Get the last element of the contract items and add 1 week to it
                    const lastContractItem =
                      contract.contractItems[contract.contractItems.length - 1];
                    const newStartDate = dayjs(
                      lastContractItem.startDateTimeLocal
                    )
                      .add(7, "day")
                      .toDate();
                    const newEndDate = dayjs(lastContractItem.endDateTimeLocal)
                      .add(7, "day")
                      .toDate();
                    const newContractItem: ContractItemType = {
                      ...lastContractItem,
                      id: undefined,
                      startDateTimeLocal: newStartDate,
                      endDateTimeLocal: newEndDate,
                    };
                    setContract((prevState) => ({
                      ...prevState,
                      contractItems: [
                        ...prevState.contractItems,
                        newContractItem,
                      ],
                    }));
                  }}
                >
                  + 1 week
                </Button>
                <Button
                  variant="secondary"
                  className="w-fit"
                  onClick={() => {
                    setContract((prevState) => ({
                      ...prevState,
                      contractItems: prevState.contractItems.map(
                        (item, index) => {
                          if (contract.contractItems.length - 1 === index) {
                            return {
                              ...item,
                              startDateTimeLocal: dayjs(item.startDateTimeLocal)
                                .add(1, "day")
                                .toDate(),
                              endDateTimeLocal: dayjs(item.endDateTimeLocal)
                                .add(1, "day")
                                .toDate(),
                              day: item.day + 1,
                            };
                          }
                          return item;
                        }
                      ),
                    }));
                  }}
                >
                  + 1 day
                </Button>
                <Button
                  variant="secondary"
                  className="w-fit"
                  onClick={() => {
                    setContract((prevState) => ({
                      ...prevState,
                      contractItems: prevState.contractItems.map(
                        (item, index) => {
                          if (contract.contractItems.length - 1 === index) {
                            return {
                              ...item,
                              startDateTimeLocal: dayjs(item.startDateTimeLocal)
                                .subtract(1, "day")
                                .toDate(),
                              endDateTimeLocal: dayjs(item.endDateTimeLocal)
                                .subtract(1, "day")
                                .toDate(),
                              day: item.day - 1,
                            };
                          }
                          return item;
                        }
                      ),
                    }));
                  }}
                >
                  - 1 day
                </Button>
              </div>
            )}
          </div>
        </Card>
        {/* Attachments */}
        <Card>
          <Headline2Variable>Attachments</Headline2Variable>
          {contract.id ? (
            <>
              <div className="flex flex-col gap-4">
                {contractAttachment.map((attachment) => {
                  return (
                    <div className="flex flex-col gap-2">
                      <div className="flex flex-row items-center justify-start gap-4">
                        <a
                          className="underline cursor-pointer"
                          href={`${attachment.publicUrl}`}
                        >
                          <Subtitle1>{attachment.originalFileName}</Subtitle1>
                        </a>
                        <Dialog>
                          <DialogTrigger asChild>
                            <Button variant="secondary">
                              <Trash2 />
                            </Button>
                          </DialogTrigger>
                          <DialogContent className="max-w-96">
                            <DialogHeader>
                              <DialogTitle>Deleting Attachment</DialogTitle>
                            </DialogHeader>
                            <DialogDescription>
                              <div className="flex flex-col gap-4">
                                <Body1>
                                  Are you sure you want to delete the attachment
                                </Body1>
                                <Body1>{attachment.originalFileName}</Body1>
                              </div>
                            </DialogDescription>
                            <DialogClose>
                              <div className="flex flex-row justify-between w-full gap-4">
                                <Button
                                  className="w-full"
                                  variant="negative"
                                  onClick={() => {
                                    deleteContractAttachment({
                                      variables: {
                                        id: attachment.id,
                                      },
                                    });
                                  }}
                                >
                                  Delete
                                </Button>
                                <Button
                                  className="w-full"
                                  variant="secondary"
                                >
                                  Close
                                </Button>
                              </div>
                            </DialogClose>
                          </DialogContent>
                        </Dialog>
                      </div>
                      <Divider />
                    </div>
                  );
                })}
              </div>
              <div className="flex flex-col items-start w-full gap-4">
                <div className="max-w-lg">
                  <FileUploader
                    multiple={true}
                    handleChange={(fileList: FileList) => {
                      setFiles(fileList);
                    }}
                    name="file"
                    types={fileTypes}
                  />
                </div>
                <Button
                  variant="primary"
                  onClick={() => handleUpload()}
                  className="max-w-80"
                >
                  Submit
                </Button>
              </div>
            </>
          ) : (
            <Disclaimer
              variant="info"
              persist={true}
              size="large"
              content="Please save the contract before adding attachments."
            />
          )}
        </Card>

        <Card>
          <Headline2Variable>Contract Notes</Headline2Variable>
          {contract.contractNotes.length === 0 && <Body1>No Notes</Body1>}
          <List>
            {contract.contractNotes
              .sort((a, b) =>
                a.createdAt.toISOString() > b.createdAt.toISOString() ? -1 : 1
              )
              .map((contractNote) => {
                return (
                  <Fragment>
                    <ListItem>
                      <ListItemText
                        primary={` ${dayjs(contractNote.createdAt).format(
                          "MMMM DD, YYYY h:mm a"
                        )} by ${contractNote.createdByUser.firstName} ${
                          contractNote.createdByUser.lastName
                        } `}
                      />
                      <Dialog>
                        <DialogTrigger asChild>
                          <Button variant="secondary">View Note</Button>
                        </DialogTrigger>
                        <DialogContent className="max-w-96">
                          <DialogHeader>
                            <DialogTitle>
                              Note -{" "}
                              {dayjs(contractNote.createdAt).format(
                                "MMMM DD, YYYY h:mm a"
                              )}
                            </DialogTitle>
                          </DialogHeader>
                          <DialogDescription>
                            <div className="flex flex-col gap-4">
                              <Body1>{contractNote.note}</Body1>
                            </div>
                          </DialogDescription>
                          <DialogClose>
                            <Button variant={"negative"}>Close</Button>
                          </DialogClose>
                        </DialogContent>
                      </Dialog>
                    </ListItem>
                    <Divider />
                  </Fragment>
                );
              })}
          </List>
        </Card>
      </div>
      <LoadingDialog
        open={
          loadingCreateContractAttachment || loadingDeleteContractAttachment
        }
      />
      <Button
        className="fixed px-4 py-2 text-white rounded-full bottom-5 right-5"
        variant="secondary"
        onClick={() => {
          if (topElement.current && containerRef.current) {
            topElement.current.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }
        }}
      >
        <ArrowUpwardIcon />
      </Button>
    </main>
  );
};

export default EditContract;
