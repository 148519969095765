import React, { FC } from "react";
import {
  Tooltip as TooltipBody,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../shadcn/tooltip";
import Body1 from "../Text/Body/Body1";

interface TooltipProps {
  /**
   * The label the the user sees to trigger the tooltip hover
   */
  trigger: React.ReactChild;
  /**
   * The text inside the hover of the tooltip
   */
  hoverText: string;
}

const Tooltip: FC<TooltipProps> = ({
  trigger,
  hoverText,
  ...props
}: TooltipProps) => {
  return (
    <TooltipProvider delayDuration={300}>
      <TooltipBody>
        <TooltipTrigger>{trigger}</TooltipTrigger>
        <TooltipContent>
          <Body1>{hoverText}</Body1>
        </TooltipContent>
      </TooltipBody>
    </TooltipProvider>
  );
};

export default Tooltip;
