import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import React, { FC, HTMLProps, ReactNode, useEffect, useState } from "react";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Body2 from "../../Text/Body/Body2";
import { cn } from "../../../../lib/utils";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../shadcn/tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Caption1 from "../../Text/Caption/Caption1";
import { Check } from "lucide-react";

export type Item = { id: number; name: string };

interface FormFieldSelectMultiProps extends HTMLProps<HTMLSelectElement> {
  /**
   * The placeholder for the select
   */
  placeholder: string;
  /**
   * The label for the select (text above the field)
   */
  label?: string;
  /**
   * The assitive text for the field (text under the field)
   */
  assistiveText?: string;
  /**
   * Set to true if the input should be disabled.
   */
  disabled?: boolean;
  /**
   * Set to true if the input should be showing an error state
   */
  error?: boolean;
  /**
   * The content inside the select element
   */
  input: Item[];
  /**
   * The list of items selected
   */
  selectedItems: number[];
  /**
   * The function that is called whenever the input changes
   */
  setSelectedItems: (values: number[]) => void;
  /**
   * optional styling for the trigger
   */
  className?: string;
  /**
   * Tool tip trigger text
   */
  toolTipTrigger?: string;
  /**
   * Tool tip content text
   */
  toolTipContent?: string;
  /**
   * Add all selection
   */
  addAllOption?: boolean;
}

/**
 * @param children: Takes an array of strings as it's children and displays them as select options.
 */
const FormFieldSelectMulti: FC<FormFieldSelectMultiProps> = ({
  placeholder,
  label,
  assistiveText,
  disabled = false,
  error = false,
  toolTipTrigger,
  toolTipContent = "",
  input,
  selectedItems,
  setSelectedItems,
  className,
  addAllOption = false,
  value,
  ...props
}: FormFieldSelectMultiProps) => {
  const mode = disabled
    ? "border-2 border-neutral-60 bg-neutral-90 focus:outline-none"
    : error
    ? "border border-error-30 hover:border-error-30 focus:outline-2 focus:outline-error-30"
    : "border border-secondary-80 hover:border-secondary-50 focus:outline focus:outline-primary-80 focus:border-primary-80";

  function isSelected(item: Item) {
    return selectedItems.findIndex((el) => el === item.id) !== -1
      ? true
      : false;
  }

  if (addAllOption) {
    input.splice(0, 0, { id: -1, name: "All" });
  }

  return (
    <div className={`flex flex-col w-full text-black`}>
      <div
        className={cn(
          `px-3 text-xs font-medium`,
          error && !disabled && "text-error-10",
          toolTipContent !== "" && "flex flex-row gap-1"
        )}
      >
        {label}

        {toolTipContent !== "" && (
          <TooltipProvider delayDuration={300}>
            <Tooltip>
              <TooltipTrigger>
                <InfoOutlinedIcon
                  className="text-info-20"
                  sx={{
                    width: "16px",
                    height: "16px",
                  }}
                />
              </TooltipTrigger>
              <TooltipContent className="max-w-[200px]">
                <Caption1 whiteSpace="normal">{toolTipContent}</Caption1>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
      <div className="relative flex flex-row items-center gap-2">
        <Listbox
          value={selectedItems}
          disabled={disabled}
          onChange={(values) => {
            let newValues: number[] = [];
            if (addAllOption) {
              // This is the case where all items are selected and the user presses all
              if (
                values.length === input.length &&
                selectedItems.length === input.length - 1
              ) {
                newValues = [];
              }
              // If all wasn't selected before and is now selected set seleted items as all
              else if (!selectedItems.includes(-1) && values.includes(-1)) {
                newValues = input
                  .filter((item) => item.id !== -1)
                  .map((item) => item.id);
              }
              // else means all was not the select that was clicked so return the values and filter out all select
              else {
                newValues = values.filter((item) => item !== -1);
              }
            } else {
              newValues = values;
            }
            setSelectedItems(newValues);
          }}
          multiple
        >
          <ListboxButton
            placeholder={placeholder}
            className={cn(
              mode,
              "w-full flex justify-between items-center rounded-xl bg-white px-3 py-2 text-left",
              className
            )}
          >
            <Body2 className="block truncate">
              {selectedItems.length < 1
                ? `Select ${placeholder}`
                : `Selected ${placeholder}s (${
                    addAllOption
                      ? input.length - 1 === selectedItems.length &&
                        input.length > 1
                        ? "All"
                        : selectedItems.length
                      : input.length === selectedItems.length &&
                        input.length > 1
                      ? "All"
                      : selectedItems.length
                  })`}
            </Body2>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <svg
                className="w-5 h-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="none"
                stroke="currentColor"
              >
                <path
                  d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </ListboxButton>
          <ListboxOptions
            anchor="bottom"
            className="w-[var(--button-width)] rounded-xl bg-white p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none cursor-default border border-secondary-10 z-50"
          >
            {input.map((item) => {
              let selected;
              if (addAllOption && item.id === -1) {
                selected =
                  input.length - 1 === selectedItems.length && input.length > 1;
              } else {
                selected = isSelected(item);
              }
              return (
                <ListboxOption
                  key={item.id}
                  value={item.id}
                  className="relative w-full py-1 pl-6 hover:bg-primary-95 rounded-xl data-[focus]:bg-primary-95"
                >
                  <div className="flex flex-row">
                    {selected && (
                      <Check className="absolute left-0 w-5 h-5 inset-y-1 text-primary-30" />
                    )}
                    <Body2 className={cn(selected && "font-bold text-black")}>
                      {item.name}
                    </Body2>
                  </div>
                </ListboxOption>
              );
            })}
          </ListboxOptions>
        </Listbox>
        {/* The error icon will appear to the right of the input if it's in an error state */}
        {error && !disabled && (
          <ErrorOutlineOutlinedIcon className="absolute right-2 text-error-30" />
        )}
      </div>
      <div
        className={`px-3 text-xxs font-normal ${
          error && !disabled ? "text-error-10" : "text-light"
        }`}
      >
        {assistiveText}
      </div>
    </div>
  );
};

export { FormFieldSelectMulti };
