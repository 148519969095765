import React, { useState, useCallback, useRef, useEffect } from "react";
import ReactCrop, { Crop, PixelCrop } from "react-image-crop";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "../Button/Button";
import "react-image-crop/dist/ReactCrop.css";

interface CropImageDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  handleCroppedImage: (croppedImage: Blob) => void;
  src: string;
}

const CropImageDialog = ({
  open,
  onOpenChange,
  handleCroppedImage,
  src,
}: CropImageDialogProps) => {
  const [crop, setCrop] = useState<Crop>({
    unit: "%", // Use percentage-based crop for responsiveness
    width: 100, // Starting width
    height: 80, // Starting height
    x: 0, // Starting position X
    y: 0, // Starting position Y
  });

  const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null); // Initialize with null

  // Set the image reference when it loads
  const onImageLoad = useCallback((img: HTMLImageElement) => {
    imgRef.current = img;
  }, []);

  // Generate the cropped image when the button is clicked
  const generateCroppedImage = () => {
    if (!completedCrop || !imgRef.current || !canvasRef.current) {
      console.log("Either the image or canvas or crop is missing");
      return;
    }

    const image = imgRef.current;
    const canvas = canvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    canvas.toBlob((blob) => {
      if (blob) {
        handleCroppedImage(blob); // Pass the cropped image back
        onOpenChange(false); // Close the dialog
      }
    }, "image/png");
  };

  return (
    <Dialog
      open={open}
      maxWidth="xl"
      aria-labelledby="responsive-dialog-title"
      sx={{
        zIndex: 30,
        marginTop: "8vh",
      }}
    >
      <DialogContent>
        <div className="w-[70vw] max- max-h-[70vh]">
          <ReactCrop
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={16 / 9}
          >
            {/* Set the image ref when it's loaded */}
            <img
              src={src}
              onLoad={(e) => onImageLoad(e.currentTarget)}
              alt="Crop source"
            />
          </ReactCrop>
        </div>
        {/* Conditionally render the canvas when the crop is completed */}
        {completedCrop && (
          <canvas
            ref={canvasRef} // Set the canvas ref
            style={{ display: "none" }} // Hide the canvas (if needed)
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="secondary"
          onClick={() => onOpenChange(false)}
        >
          Close
        </Button>
        <Button
          variant="primary"
          onClick={generateCroppedImage}
          disabled={!completedCrop} // Disable button until crop is completed
        >
          Crop Image
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CropImageDialog;
