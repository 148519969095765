import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../app/store";
import { Column } from "react-table";
import AddIcon from "@mui/icons-material/Add";
import { Pagination } from "../../types/types";
import BaseTable from "../UI/Table/Table";
import Button from "../UI/Button/Button";
import ActionsCell from "../UI/ActionCell";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import TablePagination from "../UI/Pagination/Pagination";
import {
  BugReportPaginatedQuery,
  useBugReportPaginatedLazyQuery,
} from "../../generated/graphql";
import LoadingDialog from "../UI/Dialog/LoadingDialog";
import { FormFieldSelect } from "../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { getSports } from "../../app/venueMasterSlice";
import dayjs from "dayjs";

// List of options for action col
const options = [{ id: 1, text: "Edit" }];

// Type that is needed for action col cell
interface CellParamBug {
  row: {
    original: BugReportPaginatedQuery["bugReportPaginated"]["bugReports"][0];
  };
  rows: {
    original: BugReportPaginatedQuery["bugReportPaginated"]["bugReports"][0];
  }[];
}

const BugReport: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { selectedRegions, sports }: any = useSelector(
    (state: RootState) => state.venueMaster
  );

  //Pagination Page Size
  const pageSize = 10;

  /*** STATES ***/

  // Basic filter add new id types here for more filtering
  const [filter, setFilter] = useState<{
    pagination: Pagination;
  }>({
    pagination: {
      page: 0,
      pageCount: 0,
      pageSize: pageSize,
    },
  });

  /*** QUERIES ***/
  // Query to get whatever will be dislayed on the table
  const [BugReport, { loading: loadingBugReport, data: bugReportData }] =
    useBugReportPaginatedLazyQuery({
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        const pageCount = Math.ceil(data.bugReportPaginated.count / pageSize);

        setFilter((prevState) => ({
          ...prevState,
          pagination: {
            ...prevState.pagination,
            pageCount: pageCount,
          },
        }));
      },
    });

  /*** USE EFFECTS ***/
  // Queries the data whenever the filter changes add any new filterable ids to the dependancy array
  useEffect(() => {
    if (filter) {
      BugReport({
        variables: {
          page: filter.pagination.page,
          pageSize: filter.pagination.pageSize,
        },
      });
    }
  }, [filter.pagination.page]);

  /*** UTILITY FUNCTIONS ***/
  // Handles the state for the pagination component
  const handleChangePagination = (value: number) => {
    setFilter((prevState) => ({
      ...prevState,
      pagination: {
        pageCount: prevState.pagination.pageCount,
        pageSize: prevState.pagination.pageSize,
        page: value - 1,
      },
    }));
  };

  // Table col definition
  // The type for the row should be a single record of the data that is being returned (access the first index of the array)
  const COLUMNS: Column<
    BugReportPaginatedQuery["bugReportPaginated"]["bugReports"][0]
  >[] = [
    {
      Header: "Id",
      id: "Id",
      accessor: (d) => {
        return <div>{d.id}</div>;
      },
    },
    {
      Header: "Is Admin",
      id: "isAdmin",
      accessor: (d) => {
        return <div>{d.isAdmin ? "Yes" : "No"}</div>;
      },
    },
    {
      Header: "Submitting User Name",
      id: "name",
      accessor: (d) => {
        return <div>{d.submittingUser.firstName}</div>;
      },
    },

    {
      Header: "Url of Report",
      id: "relativeUrl",
      accessor: (d) => {
        return <div>{d.relativeUrl}</div>;
      },
    },
    {
      Header: "Device Type",
      id: "deviceType",
      accessor: (d) => {
        return <div>{d.deviceType}</div>;
      },
    },
    {
      Header: "Operating System",
      id: "operatingSystem",
      accessor: (d) => {
        return <div>{d.operatingSystem}</div>;
      },
    },
    {
      Header: "Browser Version",
      id: "browserVersion",
      accessor: (d) => {
        return <div>{d.browserVersion}</div>;
      },
    },
    {
      Header: "Created At",
      id: "createdAt",
      accessor: (d) => {
        return <div>{dayjs(d.createdAt).format("YYYY-MM-DD HH:mm")}</div>;
      },
    },
    {
      Header: "Comment",
      id: "comment",
      accessor: (d) => {
        return <div dangerouslySetInnerHTML={{ __html: d.comment }}></div>;
      },
    },
  ];

  // Constants for the table
  const columns = useMemo(() => COLUMNS, []);
  const tableData = useMemo(() => {
    if (bugReportData) return bugReportData.bugReportPaginated.bugReports;
  }, [bugReportData]);

  return (
    <main className="flex flex-col gap-4">
      <LoadingDialog open={loadingBugReport} />
      <div className="flex flex-row justify-between w-full">
        <Headline1Variable>Bug Report</Headline1Variable>
      </div>
      {/* Table section */}
      {tableData && (
        <BaseTable
          columns={columns}
          data={tableData}
        />
      )}
      {filter.pagination && (
        <div className="flex justify-end mt-4">
          <TablePagination
            page={filter.pagination.page || 0}
            pageCount={filter.pagination.pageCount || 0}
            onChange={handleChangePagination}
          />
        </div>
      )}
    </main>
  );
};

export default BugReport;
