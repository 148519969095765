import React, { useEffect, useState } from "react";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import { useWeatherByRegionIdQuery } from "../../../generated/graphql";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/src/app/store";
import { useWeatherContext } from "../../../context/WeatherContext";
import dayjs from "dayjs";
import WeatherDayCard from "./WeatherDayCard";
import WeatherForecast from "./WeatherForcast";
import { Switch } from "../../UI/shadcn/switch";
import { Label } from "../../UI/shadcn/label";

const Weather = () => {
  const { selectedRegions }: any = useSelector(
    (state: RootState) => state.venueMaster
  );

  /*** STATES ***/
  const { weather, setWeather, filter, setFilter, setAmericanMode } =
    useWeatherContext();

  /*** QUERIES ***/
  const { loading: loadingWeather, data: weatherData } =
    useWeatherByRegionIdQuery({
      notifyOnNetworkStatusChange: true,
      variables: {
        regionId: filter.regionId!,
      },
      skip: filter.regionId === undefined,
      onCompleted: (data) => {
        const sortedWeather = [...data.weatherByRegionId].sort(
          (a, b) => dayjs(a.dateTime).unix() - dayjs(b.dateTime).unix()
        );
        setWeather(sortedWeather);
      },
    });

  return (
    <main className="flex flex-col gap-10 pb-10">
      <div className="flex flex-col gap-4">
        <Headline1Variable>Weather</Headline1Variable>
        <div className="flex flex-row justify-start gap-2">
          <div className="max-w-60 min-w-60">
            <FormFieldSelect
              name="regionId"
              label="Region"
              placeholder="Select Region"
              defaultValue={filter.regionId?.toString() ?? "0"}
              value={filter.regionId?.toString() ?? "0"}
              inputChange={(value: string) => {
                setFilter((prevState) => ({
                  ...prevState,
                  regionId: +value,
                }));
              }}
            >
              {[{ id: "0", name: "Select a Region" }, ...selectedRegions]}
            </FormFieldSelect>
          </div>
          <div className="flex items-center space-x-2">
            <Switch
              onCheckedChange={(value) => setAmericanMode(value)}
              id="airplane-mode"
            />
            <Label htmlFor="airplane-mode">Freedom Units</Label>
          </div>
        </div>
      </div>
      <WeatherForecast weatherData={weather} />
    </main>
  );
};

export default Weather;
