import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../app/store";
import "react-quill/dist/quill.snow.css";
import {
  useCreateThirdPartyContractorMutation,
  useThirdPartyContractorByIdQuery,
  useUpdateThirdPartyContractorMutation,
} from "../../../../generated/graphql";
import Headline1Variable from "../../../UI/Text/Headline/Headline1Variable";
import { FormFieldSelect } from "../../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import Button from "../../../UI/Button/Button";
import {
  displayAlertError,
  displayAlertSuccess,
  displayAlertWarning,
} from "../../../../app/globalSlice";
import LoadingDialog from "../../../UI/Dialog/LoadingDialog";
import { z, ZodFormattedError } from "zod";
import FormFieldControlled from "../../../UI/FormField/FormFieldControlled";
import { isEmailValid } from "../../../../utils/emailRegex";

// Replace ThirdPartyContractor with the name of the component

// Schema for the form, used for both update and create
const ThirdPartyContractorSchema = z
  .object({
    id: z.number().optional(),
    name: z.string(),
    email: z.string(),
    regionId: z.number(),
  })
  .refine((data) => data.name.length > 0, {
    message: "Name cannot be empty",
    path: ["name"],
  })
  .refine((data) => data.regionId > 0, {
    message: "You must select a region",
    path: ["regionId"],
  })
  .refine((data) => isEmailValid(data.email), {
    message: "You must enter a valid email",
    path: ["email"],
  });

type ThirdPartyContractorFormValues = z.infer<
  typeof ThirdPartyContractorSchema
>;

const initialThirdPartyContractorData = {
  id: undefined,
  name: "",
  email: "",
  regionId: 0,
};

const ThirdPartyContractor: React.FC = () => {
  /*** IMPORTED FUNTION DECLARATIONS ***/
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams();
  const navigate = useNavigate();

  // The id will be used to know if we're editing or creating data
  const { id } = params;
  // The id should be "new" if we're creating data so this will return false
  const isEdit = id !== undefined && !isNaN(+id);

  const { selectedRegions }: any = useSelector(
    (state: RootState) => state.venueMaster
  );

  /*** STATES ***/
  const [thirdPartyContractorData, setThirdPartyContractorData] =
    useState<ThirdPartyContractorFormValues>(initialThirdPartyContractorData);
  // Zod errors used to show errors on the form
  const [zodErrors, setZodErrors] = useState<
    ZodFormattedError<ThirdPartyContractorFormValues, string>
  >({ _errors: [] });

  /*** QUERIES ***/
  // Query to get data based on the id if it's being edited
  const { loading: loadingThirdPartyContractor } =
    useThirdPartyContractorByIdQuery({
      variables: {
        id: +id!,
      },
      skip: !isEdit,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        setThirdPartyContractorData((prev) => ({
          id: data.thirdPartyContractorById.id,
          name: data.thirdPartyContractorById.name,
          email: data.thirdPartyContractorById.email,
          regionId: data.thirdPartyContractorById.regionId,
        }));
      },
    });

  /*** MUTATIONS ***/
  const [
    CreateThirdPartyContractor,
    { data: createData, loading: createLoading, error: createError },
  ] = useCreateThirdPartyContractorMutation({});
  const [
    UpdateThirdPartyContractor,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useUpdateThirdPartyContractorMutation();

  React.useEffect(() => {
    console.log(zodErrors);
  }, [zodErrors]);

  /*** UTILITY FUNCTIONS ***/
  const handleSubmit = () => {
    // Parses the form and sets errors if there is an issue with validation
    const result = ThirdPartyContractorSchema.safeParse(
      thirdPartyContractorData
    );
    if (!result.success) {
      setZodErrors(result.error.format());
      dispatch(displayAlertWarning("There is an issue with the form"));
      return;
    }
    // Creates or updates data
    if (thirdPartyContractorData.id === undefined) {
      CreateThirdPartyContractor({
        variables: {
          thirdPartyContractorArgs: {
            name: thirdPartyContractorData.name,
            email: thirdPartyContractorData.email,
            regionId: thirdPartyContractorData.regionId,
          },
        },
        onCompleted: (data) => {
          if (data.createThirdPartyContractor.success) {
            dispatch(
              displayAlertSuccess(data.createThirdPartyContractor.message)
            );
            navigate(-1);
          } else {
            dispatch(
              displayAlertError(data.createThirdPartyContractor.message)
            );
          }
        },
        onError: (error) => {
          dispatch(displayAlertError(error.message));
        },
      });
    } else {
      UpdateThirdPartyContractor({
        variables: {
          thirdPartyContractorArgs: {
            id: thirdPartyContractorData.id,
            name: thirdPartyContractorData.name,
            email: thirdPartyContractorData.email,
            regionId: thirdPartyContractorData.regionId,
          },
        },
        onCompleted: (data) => {
          if (data.updateThirdPartyContractor.success) {
            dispatch(
              displayAlertSuccess(data.updateThirdPartyContractor.message)
            );
            navigate(-1);
          } else {
            dispatch(
              displayAlertError(data.updateThirdPartyContractor.message)
            );
          }
        },
        onError: (error) => {
          dispatch(displayAlertError(error.message));
        },
      });
    }
    return;
  };

  return (
    <main className="flex flex-col w-full gap-4">
      <LoadingDialog
        open={createLoading || updateLoading || loadingThirdPartyContractor}
      />
      <div className="flex flex-row justify-between w-full">
        <Headline1Variable>
          {!isEdit ? "New" : "Edit"} Third Party Contractor{" "}
        </Headline1Variable>
      </div>
      <div className="flex flex-col w-1/3 gap-4">
        {/* Region selector */}
        <FormFieldSelect
          value={
            thirdPartyContractorData.regionId
              ? thirdPartyContractorData.regionId.toString()
              : "0"
          }
          inputChange={(value) => {
            if (zodErrors.regionId && value) {
              setZodErrors((prevState) => ({
                ...prevState,
                _errors: prevState?._errors ?? [],
                regionId: undefined,
              }));
            }
            setThirdPartyContractorData((prevState) => ({
              ...prevState,
              regionId: +value,
            }));
          }}
          name="regionId"
          id="regionId"
          label="Region"
          placeholder="Select Region"
          className="h-[46px] m-0"
          disabled={isEdit ? true : false}
          error={zodErrors.regionId !== undefined}
          assistiveText={
            zodErrors.regionId !== undefined
              ? zodErrors.regionId._errors[0]
              : ""
          }
        >
          {selectedRegions.length > 0
            ? [...[{ id: 0, name: "Select Region" }], ...selectedRegions]
            : [...[{ id: 0, name: "None" }]]}
        </FormFieldSelect>
        {/* Text Input Field */}
        <FormFieldControlled
          name="name"
          value={thirdPartyContractorData.name}
          label="Name"
          error={zodErrors.name !== undefined}
          assistiveText={
            zodErrors.name !== undefined ? zodErrors.name._errors[0] : ""
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (zodErrors.name && e.target.value) {
              setZodErrors((prevState) => ({
                ...prevState,
                email: undefined,
              }));
            }
            setThirdPartyContractorData((prevState) => ({
              ...prevState,
              name: e.target.value,
            }));
          }}
        />
        <FormFieldControlled
          name="email"
          value={thirdPartyContractorData.email}
          label="Email"
          error={zodErrors.email !== undefined}
          assistiveText={
            zodErrors.email !== undefined ? zodErrors.email._errors[0] : ""
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (zodErrors.email && isEmailValid(e.target.value)) {
              setZodErrors((prevState) => ({
                ...prevState,
                email: undefined,
              }));
            }
            setThirdPartyContractorData((prevState) => ({
              ...prevState,
              email: e.target.value,
            }));
          }}
        />
      </div>
      <Button
        variant="primary"
        onClick={handleSubmit}
        className="w-fit whitespace-nowrap"
      >
        {isEdit
          ? "Update Third Party Contractor"
          : "Create Third Party Contractor"}
      </Button>
    </main>
  );
};

export default ThirdPartyContractor;
