import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/src/app/store";
import BreadCrumbs from "../../UI/Breadcrumbs/Breadcrumbs";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import Button from "../../UI/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import Card from "../../UI/Card/Card";
import FormField from "../../UI/FormField/FormField";
import LoadingDialog from "../../UI/Dialog/LoadingDialog";
import { getPayrollBatches } from "../../../app/financeSlice";
import {
  displayAlertError,
  displayAlertSuccess,
} from "../../../app/globalSlice";
import {
  usePayrollBatchCreateMutation,
  usePayrollBatchDetailsQuery,
  usePayrollBatchUpdateMutation,
} from "../../../generated/graphql";
import FormFieldDate from "../../UI/FormField/FormFieldDate/FormFieldDate";
import dayjs from "dayjs";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";

const monthArray: any = [
  { id: 0, name: "Select a month" },
  { id: 1, name: "January" },
  { id: 2, name: "February" },
  { id: 3, name: "March" },
  { id: 4, name: "April" },
  { id: 5, name: "May" },
  { id: 6, name: "June" },
  { id: 7, name: "July" },
  { id: 8, name: "August" },
  { id: 9, name: "September" },
  { id: 10, name: "October" },
  { id: 11, name: "November" },
  { id: 12, name: "December" },
];

const initialPayrollBatch = {
  name: "",
  year: 0,
  month: 0,
  shiftCutoffDate: "",
};

const CreatePayrollBatches = () => {
  const { isLoading }: any = useSelector((state: RootState) => state.shift);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const params = useParams();
  const isEditBatch = params.id;
  const pathsBreadcrumbs = [
    { name: "Payroll Batches", url: "/finance/payrollBatches" },
  ];
  const [payrollBatch, setPayrollBatch] = React.useState(initialPayrollBatch);

  const [createPayrollBatch, { loading: loadingCreatePayrollBatch }] =
    usePayrollBatchCreateMutation({});
  const [updatePayrollBatch, { loading: loadingUpdatePayrollBatch }] =
    usePayrollBatchUpdateMutation({});

  const {
    data: payrollData,
    loading,
    error,
  } = usePayrollBatchDetailsQuery({
    variables: {
      payrollBatchId: +isEditBatch!,
    },
    skip: isEditBatch === undefined,
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.payrollBatch) {
        setPayrollBatch(data.payrollBatch);
      } else {
        navigate(`/finance/payrollBatches/`);
        dispatch(displayAlertError("No data found"));
      }
    },
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPayrollBatch((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmitForm = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (isEditBatch) {
      const res = await updatePayrollBatch({
        variables: {
          payrollBatchUpdateId: isEditBatch,
          payrollBatchArgs: {
            name: payrollBatch.name,
            year: payrollBatch.year,
            month: payrollBatch.month,
            shiftCutoffDate: payrollBatch.shiftCutoffDate,
          },
        },
        onCompleted: (data) => {
          if (data.payrollBatchUpdate.success) {
            dispatch(displayAlertSuccess("Payroll Batch updated successfully"));
          } else {
            dispatch(displayAlertError("Something went wrong"));
          }
          dispatch(
            getPayrollBatches({
              page: null,
              pageSize: null,
            })
          );
          navigate(`/finance/payrollBatches/`);
        },
        onError: (error) => {
          dispatch(displayAlertError(error.message ?? "Something went wrong"));
        },
      });
    } else {
      await createPayrollBatch({
        variables: {
          payrollBatchArgs: {
            name: payrollBatch.name,
            year: payrollBatch.year,
            month: payrollBatch.month,
            shiftCutoffDate: payrollBatch.shiftCutoffDate,
          },
        },
        onCompleted: (data) => {
          if (data.payrollBatchCreate.success) {
            dispatch(displayAlertSuccess("Payroll Batch created successfully"));
          } else {
            dispatch(displayAlertError("Something went wrong"));
          }
          dispatch(
            getPayrollBatches({
              page: null,
              pageSize: null,
            })
          );
          navigate(`/finance/payrollBatches/`);
        },
        onError(error, clientOptions) {
          dispatch(displayAlertError(error.message ?? "Something went wrong"));
        },
      });
    }
  };

  if (isLoading || loadingCreatePayrollBatch || loadingUpdatePayrollBatch)
    return (
      <LoadingDialog
        open={
          isLoading || loadingCreatePayrollBatch || loadingUpdatePayrollBatch
        }
      />
    );

  return (
    <main>
      <BreadCrumbs
        paths={pathsBreadcrumbs}
        goBackTo="/finance/payrollBatches"
      ></BreadCrumbs>
      <div className="flex flex-row justify-between w-full mt-6">
        <Headline1Variable>
          {isEditBatch ? "Edit Payroll Batch" : "Create New Payroll Batch"}
        </Headline1Variable>
        <div className="h-10">
          <Button
            variant="primary"
            onClick={(e) => handleSubmitForm(e)}
          >
            {isEditBatch ? (
              <span> Edit Payroll Batch</span>
            ) : (
              <span> Create Payroll Batch</span>
            )}
          </Button>
        </div>
      </div>
      <form autoComplete="off">
        <Card className="mt-6">
          <div className="flex flex-col">
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
              <FormField
                name="name"
                value={payrollBatch.name}
                label="Name"
                inputChange={handleChange}
                initialValue={payrollBatch.name ? payrollBatch.name : ""}
              />
            </div>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
              <FormFieldSelect
                inputChange={(value: string) => {
                  setPayrollBatch((prevState) => ({
                    ...prevState,
                    year: +value,
                  }));
                }}
                name="year"
                placeholder="Year"
                label="Year"
                value={
                  payrollBatch.year.toString()
                    ? payrollBatch.year.toString()
                    : "0"
                }
              >
                {Array.from(
                  { length: 3 },
                  (_, i) => new Date().getFullYear() + 1 - i
                ).map((year) => {
                  return { id: year.toString(), name: year.toString() };
                })}
              </FormFieldSelect>
              <FormFieldSelect
                value={payrollBatch.month ? payrollBatch.month.toString() : "0"}
                name="month"
                placeholder="All"
                inputChange={(value: string) => {
                  setPayrollBatch((prevState) => ({
                    ...prevState,
                    month: +value,
                  }));
                }}
                label="Month"
              >
                {monthArray}
              </FormFieldSelect>
            </div>
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:max-xl">
              <FormFieldDate
                label="Shift Cutoff Date"
                initialValue={
                  payrollBatch.shiftCutoffDate
                    ? dayjs(payrollBatch.shiftCutoffDate).toDate()
                    : undefined
                }
                dateChange={(date) => {
                  setPayrollBatch((prevState) => ({
                    ...prevState,
                    shiftCutoffDate: dayjs(date).format("YYYY-MM-DD"),
                  }));
                }}
              />
            </div>
          </div>
        </Card>
      </form>
    </main>
  );
};

export default CreatePayrollBatches;
