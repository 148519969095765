import { createContext, useContext, useState } from "react";
import { Pagination } from "../types/types";
import dayjs from "dayjs";

type AllContractorShiftContextType = {
  filter: {
    pagination: Pagination;
    regionId: number;
    dayOfWeek: number;
    contractorId: number;
    fromDate: Date;
    toDate: Date;
  };
  setFilter: React.Dispatch<
    React.SetStateAction<{
      pagination: Pagination;
      regionId: number;
      dayOfWeek: number;
      contractorId: number;
      fromDate: Date;
      toDate: Date;
    }>
  >;
};

const AllContractorShiftContextDefault: AllContractorShiftContextType = {
  filter: {
    pagination: {
      page: 0,
      pageCount: 0,
      pageSize: 10,
    },
    regionId: 0,
    dayOfWeek: -1,
    contractorId: 0,
    fromDate: dayjs().subtract(1, "week").toDate(),
    toDate: new Date(),
  },
  setFilter: () => {},
};

const AllContractorShiftContext = createContext<AllContractorShiftContextType>(
  AllContractorShiftContextDefault
);

export const AllContractorShiftContextProvider = ({ children }: any) => {
  // Basic filter add new id types here for more filtering
  const [filter, setFilter] = useState<{
    pagination: Pagination;
    regionId: number;
    dayOfWeek: number;
    contractorId: number;
    fromDate: Date;
    toDate: Date;
  }>({
    pagination: {
      page: 0,
      pageCount: 0,
      pageSize: 10,
    },
    regionId: 0,
    dayOfWeek: -1,
    contractorId: 0,
    fromDate: dayjs().subtract(1, "week").hour(0).minute(0).toDate(),
    toDate: new Date(),
  });

  const value = {
    filter,
    setFilter,
  };

  return (
    <AllContractorShiftContext.Provider value={value}>
      {children}
    </AllContractorShiftContext.Provider>
  );
};

export const useAllContractorShiftContext = () =>
  useContext(AllContractorShiftContext);
