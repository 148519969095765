import {
  createAsyncThunk,
  createSlice,
  MiddlewareArray,
  ThunkMiddleware,
  AnyAction,
} from "@reduxjs/toolkit";
import { RootState } from "./store";
import axios from "axios";
import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";
import { client } from "../graphql";
import { Permission, User } from "../types/types";
import {
  CREATE_PERMISSION,
  GET_PERMISSIONS,
  GET_USER,
} from "../graphql/queries/user";
import { toast as toastStackable } from "sonner";
import Cookies from "js-cookie"; // Add this library for cookie management
import { baseApiUrl } from "../utils/baseUrl";
import { jwtDecode } from "jwt-decode";

// Helper functions to manage cookies
const TOKEN_COOKIE_NAME = "adminJwtToken";

const setJwtCookie = (token: string): void => {
  const decoded = jwtDecode(token);
  let days = 7;

  // Set the cookie expiry as one day less so it gets removed before the jwt itself expires
  if (decoded.exp) {
    days =
      +((decoded.exp - new Date().getTime() / 1000) / (60 * 60 * 24)).toFixed(
        0
      ) - 1;
  }

  Cookies.set(TOKEN_COOKIE_NAME, token, {
    expires: days,
    secure: true,
  });
};

export const getJwtCookie = (): string | null => {
  return Cookies.get(TOKEN_COOKIE_NAME) ?? null;
};

export const removeJwtCookie = (): void => {
  Cookies.remove(TOKEN_COOKIE_NAME);
};

interface InitialStateAuth {
  isLoading: boolean;
  // user: IUser | null;
  user: User | null;
  token: string | null;
  userLocation: number | null;
  permissions: Permission[];
}

const initialState: InitialStateAuth = {
  isLoading: false,
  user: null,
  token: getJwtCookie(),
  userLocation: null,
  permissions: [],
};

let store: ToolkitStore<
  {
    auth: InitialStateAuth;
  },
  AnyAction,
  MiddlewareArray<
    [
      ThunkMiddleware<
        {
          auth: InitialStateAuth;
        },
        AnyAction,
        undefined
      >
    ]
  >
>;

export const injectStore = (_store: any) => {
  store = _store;
};

export const multipartFetch = axios.create({
  baseURL: baseApiUrl,
  headers: {
    "Content-type": "multipart/form-data",
  },
  withCredentials: true,
});

multipartFetch.interceptors.request.use(
  (config) => {
    const jwt = getJwtCookie();
    config.headers["Authorization"] = `Bearer ${jwt}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const verifyMFA = createAsyncThunk(
  "auth/verifyMFA",
  async (
    currentUser: {
      email: string;
      password: string;
      mfaCode: string;
      rememberMe: boolean;
    },
    thunkAPI
  ) => {
    const url = `${baseApiUrl}/auth/verify-mfa`;
    console.log(currentUser);
    try {
      const response = await axios.post(url, currentUser, {
        headers: {
          "Content-type": "application/json",
        },
        withCredentials: true,
      });
      const { user, token } = response.data;
      if (response.data) {
        console.log(token);
        setJwtCookie(token);
      }
      return await response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const sendMFACode = createAsyncThunk(
  "auth/sendMFACode",
  async (
    currentUser: {
      email: string;
      password: string;
      communicationMethod: string;
    },
    thunkAPI
  ) => {
    const url = `${baseApiUrl}/auth/delivery-method`;
    try {
      const response = await axios.post(url, currentUser, {
        headers: {
          "Content-type": "application/json",
        },
        withCredentials: true,
      });
      return await response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const loginUser = createAsyncThunk(
  "auth/login",
  async (currentUser: { email: string; password: string }, thunkAPI) => {
    const url = `${baseApiUrl}/auth`;

    try {
      const response = await axios.post(url, currentUser, {
        headers: {
          "Content-type": "application/json",
        },
        withCredentials: true,
      });
      return await response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const getAllPermissions = createAsyncThunk(
  "auth/getAllPermissions",
  async (_, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_PERMISSIONS,
        fetchPolicy: "no-cache",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const permissions = response.data.permissions;
      return permissions;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const createPermission = createAsyncThunk(
  "auth/createPermission",
  async (name: string, thunkAPI) => {
    try {
      const response = await client.mutate({
        mutation: CREATE_PERMISSION,
        variables: {
          name: name,
        },
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getUserDetails = createAsyncThunk(
  "auth/getUserDetails",
  async (jwt: string, thunkAPI) => {
    try {
      const response = await client.mutate({
        mutation: GET_USER,
        variables: { jwt: jwt },
      });
      return response.data.getUser;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.userLocation = null;
      state.permissions = [];
      removeJwtCookie();
      window.location.href = "/login";
    },
    getUser: (state, action) => {
      state.user = action.payload;
    },
    getPermissions: (state, action) => {
      state.permissions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyMFA.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyMFA.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
      })
      .addCase(verifyMFA.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(action.payload as string);
      })
      .addCase(sendMFACode.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendMFACode.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(sendMFACode.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(action.payload as string);
      })
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(action.payload as string);
      })
      // .addCase(refToken.pending, (state) => {
      //   state.isLoading = true;
      // })
      // .addCase(refToken.fulfilled, (state, action) => {
      //   state.isLoading = false;
      //   state.user = action.payload.user;
      //   state.token = action.payload.accessToken;
      // })
      // .addCase(refToken.rejected, (state, action) => {
      //   state.isLoading = false;
      // toastStackable.error("Cant create Permission");
      // })
      .addCase(getAllPermissions.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllPermissions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.permissions = action.payload;
      })
      .addCase(getAllPermissions.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(action.payload as string);
      })
      .addCase(createPermission.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createPermission.fulfilled, (state, action) => {
        state.isLoading = false;
        toastStackable.success(action.payload.permissionCreate.message);
      })
      .addCase(createPermission.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error("Cant create Permission");
      })
      .addCase(getUserDetails.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(action.error.message ?? "Error fetching user");
      });
  },
});

export const authSelector = (state: RootState) => state.auth;

export const { logout, getUser, getPermissions } = authSlice.actions;

export default authSlice.reducer;
