export const genderIdentityIdClassify = (id: number) => {
  // Identifies as man
  if (id === 1 || id === 4) {
    return 1;
  }
  // Identifies as woman
  else if (id === 2 || id === 5) {
    return 2;
  }
  // Identifies as X
  else {
    return 3;
  }
};
