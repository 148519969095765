import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../app/store";
import "react-quill/dist/quill.snow.css";
import {
  ListAllOperations,
  Region,
  useCreateShiftMutation,
  useShiftByIdQuery,
  useStaffMembersQuery,
  useStaffTypeByRegionIdQuery,
  useThirdPartyContractorByRegionIdQuery,
  useUpdateShiftMutation,
  useVenuesTreeQuery,
} from "../../../../generated/graphql";
import Headline1Variable from "../../../UI/Text/Headline/Headline1Variable";
import { FormFieldSelect } from "../../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import Button from "../../../UI/Button/Button";
import {
  displayAlertError,
  displayAlertSuccess,
  displayAlertWarning,
} from "../../../../app/globalSlice";
import LoadingDialog from "../../../UI/Dialog/LoadingDialog";
import { z, ZodFormattedError } from "zod";
import FormFieldControlled from "../../../UI/FormField/FormFieldControlled";
import CheckBox from "../../../UI/Checkbox/Checkbox";
import dayjs from "dayjs";
import {
  convertLocalToJSDate,
  dateWithoutTimezone,
} from "../../../../utils/timeFunctions";
import Card from "../../../../components/UI/Card/Card";
import Subtitle2 from "../../../../components/UI/Text/Subtitle/Subtitle2";
import {
  EPermission,
  filterPermittedRegions,
} from "../../../../utils/permissions";
import {
  getVenuesTree,
  getStaffTypeByRegion,
} from "../../../../app/shiftSlice";
import MultipleDatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { DatePicker } from "../../../../components/UI/shadcn/Time/date-picker";
import Caption1 from "../../../../components/UI/Text/Caption/Caption1";
import { TimePickerDemo } from "../../../../components/UI/shadcn/Time/time-picker-demo";
import ReactQuill from "react-quill";
import Alert from "../../../UI/Alerts/Alert";
import Body1 from "../../../UI/Text/Body/Body1";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
} from "../../../UI/shadcn/dialog";
import {
  FormFieldSelectMultiSearch,
  MultiSelectRef,
} from "../../../UI/FormField/FormFieldSelectMulti/FormFieldSelectMultiSearch";

// Schema for the form, used for both update and create
const ShiftSchema = z
  .object({
    id: z.number().optional(),
    regionId: z.number().min(1, { message: "Region is required" }),
    venueId: z.number().min(1, { message: "Venue is required" }),
    startLocal: z.date(),
    endLocal: z.date(),
    isShiftOvernight: z.boolean(),
    staffTypeId: z.number().min(1, { message: "Staff type is required" }),
    userId: z.string().optional(),
    contractorId: z.number().optional(),
    rate: z.number(),
    exclusionDates: z.array(z.date()),
    notes: z.string(),
  })
  .refine(
    (data) => {
      if (data.isShiftOvernight) {
        return (
          dayjs(data.startLocal).get("hour") > dayjs(data.endLocal).get("hour")
        );
      } else {
        return true;
      }
    },
    {
      message: "End time must be earlier than start time on overnight shifts.",
      path: ["overnightTime"],
    }
  )
  .refine(
    (data) => {
      if (!data.isShiftOvernight) {
        return (
          dayjs(data.startLocal).get("hour") < dayjs(data.endLocal).get("hour")
        );
      } else {
        return true;
      }
    },
    {
      message: "End time must occur after start time.",
      path: ["normalTime"],
    }
  )
  .refine(
    (data) => {
      if (
        dayjs(data.startLocal).day() !== dayjs(data.endLocal).day() &&
        data.id === undefined
      ) {
        return false;
      } else {
        return true;
      }
    },
    {
      message: "Start and end date must be on the same weekday",
      path: ["weekdayCheck"],
    }
  )
  .refine(
    (data) => {
      if (
        dayjs(data.startLocal).isBefore(data.endLocal, "day") ||
        dayjs(data.startLocal).isSame(data.endLocal, "day")
      ) {
        return true;
      } else {
        return false;
      }
    },
    {
      message: "End date must be after start date",
      path: ["dayCheck"],
    }
  )
  .refine(
    (data) => {
      if (data.userId == null && data.contractorId == null) {
        return false;
      } else {
        return true;
      }
    },
    {
      message: "REPLACE is required.",
      path: ["contractorOrStaff"],
    }
  );

type ShiftFormValues = z.infer<typeof ShiftSchema>;

const initialShiftData: ShiftFormValues = {
  id: undefined,
  venueId: 0,
  regionId: 0,
  startLocal: dayjs()
    .set("hour", 18)
    .set("minute", 0)
    .set("second", 0)
    .toDate(),
  endLocal: dayjs().set("hour", 18).set("minute", 0).set("second", 0).toDate(),
  isShiftOvernight: false,
  staffTypeId: 0,
  userId: undefined,
  contractorId: undefined,
  rate: 0,
  exclusionDates: [],
  notes: "",
};

declare module "react-quill" {
  interface QuillStatic {
    register: (module: any, suppressWarning?: boolean) => void;
  }
}

export interface ShiftProps {
  requiredPermission: EPermission;
}

const Shift: React.FC<ShiftProps> = ({ requiredPermission }) => {
  /*** IMPORTED FUNTION DECLARATIONS ***/
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams();
  const navigate = useNavigate();
  // The id will be used to know if we're editing or creating data
  const { id } = params;
  // The id should be "Create" if we're creating data so this will return false
  const isEdit = id !== undefined && !isNaN(+id);
  const { selectedRegions }: any = useSelector(
    (state: RootState) => state.venueMaster
  );
  const { user } = useSelector((state: RootState) => state.auth);

  /*** REFERENCES ***/
  const multiSelectRef = React.useRef<MultiSelectRef>(null);

  const handleClear = () => {
    multiSelectRef.current?.clearState();
  };

  /*** STATES ***/
  const [shiftData, setShiftData] = useState<ShiftFormValues>(initialShiftData);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] =
    useState<boolean>(false);
  const [isPrepopulateDialogOpen, setIsPrepopulateDialogOpen] =
    useState<boolean>(false);
  const [isContractedShift, setIsContractedShift] = useState<boolean>(false);
  const [hasShiftReport, setHasShiftReport] = useState<boolean>(false);
  const [permittedRegions, setPermittedRegions] = useState<Region[]>([]);
  // Zod errors used to show errors on the form
  const [zodErrors, setZodErrors] = useState<
    ZodFormattedError<
      ShiftFormValues & {
        normalTime: string;
        overnightTime: string;
        contractorOrStaff: string;
        dayCheck: string;
        weekdayCheck: string;
      },
      string
    >
  >({ _errors: [] });

  /*** QUERIES ***/
  // Query to get data based on the id if it's being edited
  const { loading: loadingShift, data: shiftByIdData } = useShiftByIdQuery({
    variables: {
      id: +id!,
    },
    skip: !isEdit,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data.shiftById.contractorId) {
        setIsContractedShift(true);
        if (multiSelectRef.current) {
          handleClear();
        }
      }
      if (
        data.shiftById.shiftReport &&
        data.shiftById.shiftReport.shiftReportStatusId != null &&
        +data.shiftById.shiftReport.shiftReportStatusId === 2
      ) {
        setHasShiftReport(true);
      }
      setShiftData({
        id: +data.shiftById.id,
        venueId: data.shiftById.venueId,
        regionId: data.shiftById.regionId,
        startLocal: convertLocalToJSDate(
          data.shiftById.regionId,
          data.shiftById.startLocal
        ),
        endLocal: convertLocalToJSDate(
          data.shiftById.regionId,
          data.shiftById.endLocal
        ),
        staffTypeId: data.shiftById.staffTypeId,
        userId: data.shiftById.userId ?? undefined,
        contractorId: data.shiftById.contractorId ?? undefined,
        rate: data.shiftById.rate ?? 0,
        isShiftOvernight:
          dayjs(data.shiftById.startLocal).day() !==
          dayjs(data.shiftById.endLocal).day(),
        exclusionDates: [],
        notes: "",
      });
    },
  });

  const { loading: loadingStaffTypeData, data: staffTypeData } =
    useStaffTypeByRegionIdQuery({
      variables: {
        regionId: shiftData.regionId,
      },
    });

  const { loading: loadingStaffMemberData, data: staffMemberData } =
    useStaffMembersQuery({});
  const {
    loading: loadingThirdPartyContractorsData,
    data: thirdPartyContractorsData,
  } = useThirdPartyContractorByRegionIdQuery({
    variables: {
      regionId: shiftData.regionId,
    },
    fetchPolicy: "network-only",
  });

  const { loading: loadingVenues, data: venueData } = useVenuesTreeQuery({
    variables: {
      regionId: shiftData.regionId,
    },
  });

  /*** MUTATIONS ***/
  const [
    CreateShift,
    { data: createData, loading: createLoading, error: createError },
  ] = useCreateShiftMutation();
  const [
    UpdateShift,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useUpdateShiftMutation();

  /*** UTILITY FUNCTIONS ***/
  function isDataValid() {
    // Parses the form and sets errors if there is an issue with validation
    const result = ShiftSchema.safeParse(shiftData);
    if (!result.success) {
      setZodErrors(result.error.format());
      dispatch(displayAlertWarning("There is an issue with the form"));
      return false;
    }
    return true;
  }

  const handleSubmit = () => {
    if (!isDataValid()) {
      return;
    }
    // Creates or updates data
    if (shiftData.id === undefined) {
      CreateShift({
        variables: {
          createShiftInput: {
            venueId: shiftData.venueId,
            regionId: shiftData.regionId,
            staffTypeId: shiftData.staffTypeId,
            startLocal: dateWithoutTimezone(shiftData.startLocal),
            endLocal: dateWithoutTimezone(shiftData.endLocal),
            isShiftOvernight: shiftData.isShiftOvernight,
            exclusionDateFields: shiftData.exclusionDates.map(
              (exclusionDate) => {
                return dateWithoutTimezone(exclusionDate);
              }
            ),
            assignedStaffId:
              shiftData.userId === undefined || shiftData.userId === "-1"
                ? null
                : shiftData.userId,
            contractorId:
              shiftData.contractorId === undefined
                ? null
                : shiftData.contractorId,
            rate: shiftData.rate,
            notes: shiftData.notes,
          },
        },
        onCompleted: (data) => {
          if (data.createShift.success) {
            dispatch(displayAlertSuccess(data.createShift.message));
            setIsPrepopulateDialogOpen(true);
          } else {
            dispatch(displayAlertError(data.createShift.message));
          }
        },
        onError: (error) => {
          dispatch(displayAlertError(error.message));
        },
      });
    } else {
      UpdateShift({
        variables: {
          updateShiftInput: {
            id: shiftData.id,
            venueId: shiftData.venueId,
            regionId: shiftData.regionId,
            staffTypeId: shiftData.staffTypeId,
            startLocal: dateWithoutTimezone(shiftData.startLocal),
            endLocal: dateWithoutTimezone(shiftData.endLocal),
            assignedStaffId:
              shiftData.userId === undefined ? null : shiftData.userId,
            contractorId:
              shiftData.contractorId === undefined
                ? null
                : shiftData.contractorId,
            rate: shiftData.rate,
          },
        },
        refetchQueries: [ListAllOperations.Query.ShiftById],
        onCompleted: (data) => {
          if (data.updateShift.success) {
            dispatch(displayAlertSuccess(data.updateShift.message));
            if (shiftData.contractorId == null) {
              navigate("/staffing/all-staff-shifts");
            } else {
              navigate("/staffing/all-contractor-shifts");
            }
          } else {
            dispatch(displayAlertError(data.updateShift.message));
          }
        },
        onError: (error) => {
          dispatch(displayAlertError(error.message));
        },
      });
    }
    return;
  };

  function hoursPerShift(): number {
    return shiftData.isShiftOvernight
      ? 24 -
          dayjs(shiftData.startLocal).hour() +
          dayjs(shiftData.endLocal).hour()
      : dayjs(shiftData.endLocal).hour() - dayjs(shiftData.startLocal).hour();
  }

  /*** USE EFFECTS ***/
  useEffect(() => {
    if (id === undefined) {
      setShiftData(initialShiftData);
    } else {
      if (id !== undefined && isNaN(+id)) {
        navigate("/*");
      }
    }
  }, [id]);

  useEffect(() => {
    // set Permitted Regions
    const permittedRegion = filterPermittedRegions(
      user?.permission,
      requiredPermission
    );
    if (permittedRegion === null) {
      setPermittedRegions(selectedRegions);
      return;
    }
    // Check if the user has permission to edit a shift in the region of the shift that is being edited
    let regionWithoutPerm = "";
    if (isEdit && shiftByIdData) {
      if (
        permittedRegion.every((region) => {
          if (+region.id !== shiftByIdData.shiftById.regionId) {
            regionWithoutPerm = shiftByIdData.shiftById.region.name;
            return true;
          }
          return false;
        })
      ) {
        dispatch(
          displayAlertWarning(
            `You don't have permission to edit a shift in ${regionWithoutPerm}`
          )
        );
        if (shiftByIdData.shiftById.contractorId == null) {
          navigate("/staffing/all-staff-shifts");
        } else {
          navigate("/staffing/all-contractor-shifts");
        }
      }
    }
    setPermittedRegions(permittedRegion);
  }, [shiftByIdData]);

  useEffect(() => {
    const result = ShiftSchema.safeParse(shiftData);
    if (
      !result.success &&
      (zodErrors.overnightTime !== undefined ||
        zodErrors.normalTime !== undefined ||
        zodErrors.dayCheck !== undefined)
    ) {
      const errors: ZodFormattedError<
        ShiftFormValues & {
          normalTime: string;
          overnightTime: string;
          contractorOrStaff: string;
          dayCheck: string;
          weekdayCheck: string;
        },
        string
      > = result.error.format();
      setZodErrors((prevState) => ({
        ...prevState,
        normalTime: errors.normalTime,
        overnightTime: errors.overnightTime,
        dayCheck: errors.dayCheck,
        weekdayCheck: errors.weekdayCheck,
      }));
    }
  }, [shiftData.startLocal, shiftData.endLocal, shiftData.isShiftOvernight]);

  return (
    <main className="flex flex-col w-full gap-4 pb-20">
      <LoadingDialog
        open={
          createLoading ||
          updateLoading ||
          loadingShift ||
          loadingStaffMemberData ||
          loadingStaffTypeData ||
          loadingThirdPartyContractorsData ||
          loadingVenues
        }
      />
      <Dialog
        open={isConfirmDialogOpen}
        onOpenChange={(value) => {
          setIsConfirmDialogOpen(value);
        }}
      >
        <DialogContent>
          <DialogHeader>Confirm Long Shift</DialogHeader>
          <DialogDescription>
            You are trying to create a shift greater than 24 hours in length. If
            this is intional confirm below. Otherwise please revise the start
            and end timings.
          </DialogDescription>
          <DialogFooter>
            <div className="flex gap-4 flwx-row">
              <DialogClose>
                <Button variant="primary">Cancel</Button>
              </DialogClose>
              <DialogClose>
                <Button
                  variant="secondary"
                  onClick={() => {
                    const result = ShiftSchema.safeParse(shiftData);
                    if (!result.success) {
                      setZodErrors(result.error.format());
                      dispatch(
                        displayAlertWarning("There is an issue with the form")
                      );
                      return;
                    } else {
                      handleSubmit();
                    }
                  }}
                >
                  Confirm
                </Button>
              </DialogClose>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isPrepopulateDialogOpen}
        onOpenChange={(value) => {
          setIsPrepopulateDialogOpen(value);
        }}
      >
        <DialogContent className="max-w-fit">
          <DialogHeader>Shift Created</DialogHeader>
          <div className="flex flex-col items-center justify-center gap-2">
            <Body1>
              The shift was created successfully what action would you like to
              do next?
            </Body1>
            <div className="flex flex-row gap-4 w-fit">
              <DialogClose>
                <Button
                  className="whitespace-nowrap"
                  onClick={() => {
                    if (shiftData.contractorId === undefined) {
                      navigate("/staffing/all-staff-shifts");
                    } else {
                      navigate("/staffing/all-contractor-shifts");
                    }
                  }}
                  variant="secondary"
                >
                  View {shiftData.contractorId !== undefined && " Contractor "}
                  Shifts
                </Button>
              </DialogClose>
              <DialogClose>
                <Button
                  className="whitespace-nowrap"
                  onClick={() => {
                    setShiftData(initialShiftData);
                    setIsContractedShift(false);
                  }}
                  variant="secondary"
                >
                  New Blank Shift
                </Button>
              </DialogClose>
              <DialogClose>
                <Button
                  variant="secondary"
                  className="whitespace-nowrap"
                >
                  Prepopulate Shift
                </Button>
              </DialogClose>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <div className="flex flex-row justify-between w-full">
        <Headline1Variable>
          {!isEdit ? "New" : "Update"} Shift{" "}
        </Headline1Variable>
      </div>
      <div className="flex flex-col w-2/3 gap-4">
        <Card>
          <div className="flex flex-col w-2/3 gap-4">
            <Subtitle2>Venue Details</Subtitle2>
            <FormFieldSelect
              name="regionId"
              label="Region"
              placeholder="Region"
              disabled={hasShiftReport}
              value={shiftData.regionId.toString()}
              error={zodErrors.regionId !== undefined}
              assistiveText={
                zodErrors.regionId !== undefined
                  ? zodErrors.regionId._errors[0]
                  : ""
              }
              inputChange={(value: string) => {
                if (zodErrors.regionId && +value > 0) {
                  setZodErrors((prevState) => ({
                    ...prevState,
                    regionId: undefined,
                  }));
                }
                setShiftData((prevState) => ({
                  ...prevState,
                  regionId: +value,
                }));
                dispatch(getVenuesTree({ regionId: +value }));
                dispatch(getStaffTypeByRegion(+value));
              }}
            >
              {[...[{ id: 0, name: "Select Region" }], ...permittedRegions]}
            </FormFieldSelect>
            <FormFieldSelect
              name="venueId"
              label="Venue"
              placeholder="Venue"
              disabled={hasShiftReport}
              value={shiftData.venueId.toString()}
              error={zodErrors.venueId !== undefined}
              assistiveText={
                zodErrors.venueId !== undefined
                  ? zodErrors.venueId._errors[0]
                  : ""
              }
              inputChange={(value: string) => {
                if (zodErrors.venueId && +value > 0) {
                  setZodErrors((prevState) => ({
                    ...prevState,
                    venueId: undefined,
                  }));
                }
                setShiftData((prevState) => ({
                  ...prevState,
                  venueId: +value,
                }));
              }}
            >
              {[
                { id: 0, name: "Select Venue (Choose Region first)" },
                ...(venueData ? venueData.venuesTree : []),
              ]}
            </FormFieldSelect>
          </div>
        </Card>
        <Card>
          <div className="flex flex-col w-2/3 gap-4">
            <Subtitle2>Date and Timings</Subtitle2>
            <div className="flex flex-col gap-4">
              {zodErrors.normalTime !== undefined && (
                <Alert
                  size="large"
                  persist={true}
                  content={
                    zodErrors.normalTime !== undefined
                      ? zodErrors.normalTime._errors[0]
                      : ""
                  }
                  variant="error"
                />
              )}
              {zodErrors.overnightTime !== undefined && (
                <Alert
                  size="large"
                  persist={true}
                  content={
                    zodErrors.overnightTime !== undefined
                      ? zodErrors.overnightTime._errors[0]
                      : ""
                  }
                  variant="error"
                />
              )}
              {zodErrors.weekdayCheck !== undefined && (
                <Alert
                  size="large"
                  persist={true}
                  content={
                    zodErrors.weekdayCheck !== undefined
                      ? zodErrors.weekdayCheck._errors[0]
                      : ""
                  }
                  variant="error"
                />
              )}
              {zodErrors.dayCheck !== undefined && (
                <Alert
                  size="large"
                  persist={true}
                  content={
                    zodErrors.dayCheck !== undefined
                      ? zodErrors.dayCheck._errors[0]
                      : ""
                  }
                  variant="error"
                />
              )}
              <div className="flex flex-row justify-between gap-4">
                <div className="flex flex-col w-full gap-4">
                  <DatePicker
                    className="w-full"
                    label="Start Date"
                    date={shiftData.startLocal}
                    disabled={hasShiftReport}
                    setDate={(date) => {
                      if (date) {
                        const newStartDate = dayjs(date)
                          .set("hour", shiftData.startLocal.getHours())
                          .set("minute", shiftData.startLocal.getMinutes())
                          .set("second", shiftData.startLocal.getSeconds());
                        setShiftData((prevState) => ({
                          ...prevState,
                          startLocal: newStartDate.toDate(),
                        }));
                      }
                    }}
                  />
                  <div>
                    <Caption1 className="pl-3">Start Time</Caption1>
                    <TimePickerDemo
                      includeClock={false}
                      minuteStep={5}
                      id={`startTime`}
                      excludeSecond={true}
                      date={shiftData.startLocal}
                      setDate={(date) => {
                        if (date) {
                          setShiftData((prevState) => ({
                            ...prevState,
                            startLocal: date,
                          }));
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-col w-full gap-4">
                  <DatePicker
                    className="w-full"
                    label="End Date"
                    date={shiftData.endLocal}
                    disabled={isEdit}
                    setDate={(date) => {
                      if (date) {
                        const newStartDate = dayjs(date)
                          .set("hour", shiftData.endLocal.getHours())
                          .set("minute", shiftData.endLocal.getMinutes())
                          .set("second", shiftData.endLocal.getSeconds());
                        setShiftData((prevState) => ({
                          ...prevState,
                          endLocal: newStartDate.toDate(),
                        }));
                      }
                    }}
                  />

                  <div>
                    <Caption1 className="pl-3">End Time</Caption1>
                    <TimePickerDemo
                      includeClock={false}
                      minuteStep={5}
                      id={`endTime`}
                      excludeSecond={true}
                      date={shiftData.endLocal}
                      setDate={(date) => {
                        if (date) {
                          setShiftData((prevState) => ({
                            ...prevState,
                            endLocal: date,
                          }));
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-row gap-4">
                <Body1>Hours/Shift: {hoursPerShift()}</Body1>
                {!isEdit && (
                  <Body1>
                    Total Hours:{" "}
                    {hoursPerShift() *
                      (dayjs(shiftData.endLocal).diff(
                        shiftData.startLocal,
                        "week"
                      ) +
                        (shiftData.isShiftOvernight ? 2 : 1) -
                        shiftData.exclusionDates.length)}
                  </Body1>
                )}
              </div>
              {!isEdit && (
                <div className="flex items-center gap-6 align-middle">
                  <CheckBox
                    id="isShiftOvernight"
                    checkedState={shiftData.isShiftOvernight}
                    inputChange={(value) => {
                      setShiftData((prevState) => ({
                        ...prevState,
                        isShiftOvernight: value,
                      }));
                    }}
                    label="Shift extends overnight"
                  />
                </div>
              )}
              {!isEdit && (
                <div className={`flex flex-col w-full text-black`}>
                  <div className={`px-3 text-xs font-medium`}>
                    Exclusion Dates
                  </div>
                  <MultipleDatePicker
                    multiple
                    plugins={[<DatePanel />]}
                    inputClass={
                      "font-normal pt-2 pb-2 mt-0.5 mb-0.5 pl-3 pr-3 w-full h-full rounded-lg bg-[var(--white)] border border-[var(--secondary-80)] hover:border-[var(--secondary-50)] focus:outline-2 focus:outline-[var(--primary-80)]"
                    }
                    containerClassName={"w-full"}
                    value={shiftData.exclusionDates}
                    name="exclusionDateFields"
                    //onChange throws Date as DateObject only
                    onChange={(selectedDates: DateObject[]) => {
                      if (Array.isArray(selectedDates)) {
                        const dates = selectedDates.map((date) => {
                          return new Date(date.format());
                        });
                        setShiftData((prevState) => ({
                          ...prevState,
                          exclusionDates: dates,
                        }));
                      }
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </Card>
        <Card>
          <div className="flex flex-col w-2/3 gap-4">
            <Subtitle2>Staff Details</Subtitle2>
            <div className="flex flex-col gap-2">
              <CheckBox
                checkedState={isContractedShift}
                inputChange={(value) => {
                  if (value) {
                    setShiftData((prevState) => ({
                      ...prevState,
                      userId: undefined,
                    }));
                  } else {
                    setShiftData((prevState) => ({
                      ...prevState,
                      contractorId: undefined,
                    }));
                  }
                  handleClear();
                  setIsContractedShift(value);
                }}
                id="contractorShift"
                label="Contractor Shift?"
              />
              <FormFieldSelect
                name="staffTypeId"
                label="Staff Type"
                placeholder="Staff Type"
                value={shiftData.staffTypeId.toString()}
                error={zodErrors.staffTypeId !== undefined}
                assistiveText={
                  zodErrors.staffTypeId !== undefined
                    ? zodErrors.staffTypeId._errors[0]
                    : ""
                }
                inputChange={(value: string) => {
                  if (zodErrors.staffTypeId && +value > 0) {
                    setZodErrors((prevState) => ({
                      ...prevState,
                      staffTypeId: undefined,
                    }));
                  }
                  let rate = 0;
                  if (staffTypeData) {
                    const staffType = staffTypeData.staffTypeByRegionId.find(
                      (staffType) => staffType.id === +value
                    );
                    rate = staffType ? staffType.baseRate : 0;
                  }
                  setShiftData((prevState) => ({
                    ...prevState,
                    staffTypeId: +value,
                    rate,
                  }));
                }}
              >
                {[
                  { id: 0, name: "Select Staff Type (Choose Region first)" },
                  ...(staffTypeData ? staffTypeData.staffTypeByRegionId : []),
                ]}
              </FormFieldSelect>
              {isContractedShift ? (
                <>
                  <FormFieldSelectMultiSearch
                    oneItem={true}
                    ref={multiSelectRef}
                    label="Assign Contractor"
                    name="contractorId"
                    assistiveText={
                      zodErrors.contractorOrStaff !== undefined
                        ? zodErrors.contractorOrStaff._errors[0].replace(
                            "REPLACE",
                            "Contractor "
                          )
                        : ""
                    }
                    error={zodErrors.contractorOrStaff !== undefined}
                    options={
                      thirdPartyContractorsData
                        ? thirdPartyContractorsData.thirdPartyContractorByRegionId.map(
                            (contractor) => {
                              return {
                                value: contractor.id.toString(),
                                label: contractor.name,
                              };
                            }
                          )
                        : []
                    }
                    values={
                      shiftData.contractorId
                        ? [shiftData.contractorId.toString()]
                        : []
                    }
                    onValueChange={(values) => {
                      if (zodErrors.contractorOrStaff && values.length === 1) {
                        setZodErrors((prevState) => ({
                          ...prevState,
                          contractorOrStaff: undefined,
                        }));
                      }
                      setShiftData((prevState) => ({
                        ...prevState,
                        contractorId:
                          values.length > 0 ? +values[0] : undefined,
                      }));
                    }}
                    disabled={hasShiftReport}
                    placeholder="Assign Contractor"
                    maxCount={1}
                  />
                </>
              ) : (
                <>
                  <FormFieldSelectMultiSearch
                    ref={multiSelectRef}
                    oneItem={true}
                    label="Assign Staff"
                    name="assignedStaffId"
                    assistiveText={
                      zodErrors.contractorOrStaff !== undefined
                        ? zodErrors.contractorOrStaff._errors[0].replace(
                            "REPLACE",
                            "Staff"
                          )
                        : ""
                    }
                    error={zodErrors.contractorOrStaff !== undefined}
                    options={[
                      { value: "-1", label: "Open" },
                      ...(staffMemberData
                        ? staffMemberData.staffMembers.map((staffMember) => {
                            return {
                              value: staffMember.id,
                              label:
                                staffMember.firstName +
                                " " +
                                staffMember.lastName,
                            };
                          })
                        : []),
                    ]}
                    values={shiftData.userId ? [shiftData.userId] : []}
                    onValueChange={(values) => {
                      if (zodErrors.contractorOrStaff && values.length === 1) {
                        setZodErrors((prevState) => ({
                          ...prevState,
                          contractorOrStaff: undefined,
                        }));
                      }
                      setShiftData((prevState) => ({
                        ...prevState,
                        userId: values.length > 0 ? values[0] : undefined,
                      }));
                    }}
                    disabled={hasShiftReport}
                    placeholder="Select Staff"
                    maxCount={1}
                  />
                </>
              )}
              <FormFieldControlled
                name="rate"
                label="Hourly Rate ($)"
                value={shiftData.rate.toString()}
                type="number"
                error={zodErrors.rate !== undefined}
                assistiveText={
                  zodErrors.rate !== undefined ? zodErrors.rate._errors[0] : ""
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setShiftData((prevState) => ({
                    ...prevState,
                    rate: +e.target.value,
                  }));
                }}
              />
            </div>
          </div>
        </Card>
        {!isEdit && (
          <Card>
            <div className="flex flex-col w-2/3 gap-4">
              <Subtitle2>Shift Note</Subtitle2>
              <div className="mb-12">
                <ReactQuill
                  style={{ height: "200px" }}
                  theme="snow" // you can use 'bubble' theme as well
                  value={shiftData.notes}
                  onChange={(text: string) => {
                    if (zodErrors.notes && text.length > 0) {
                      setZodErrors((prevState) => ({
                        ...prevState,
                        note: undefined,
                      }));
                    }
                    setShiftData((prevState) => ({
                      ...prevState,
                      notes: text,
                    }));
                  }}
                />
              </div>
            </div>
          </Card>
        )}
      </div>
      <Button
        variant="primary"
        onClick={() => {
          if (hoursPerShift() > 24) {
            if (isDataValid()) {
              setIsConfirmDialogOpen(true);
            } else {
              return;
            }
          } else {
            handleSubmit();
          }
        }}
        className="w-60"
      >
        {isEdit ? "Update Shift" : "Create Shift"}
      </Button>
    </main>
  );
};

export default Shift;
