import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../../app/store";
import "react-quill/dist/quill.snow.css";
import {
  useCreateStaffTypeMutation,
  useStaffTypeByIdQuery,
  useUpdateStaffTypeMutation,
} from "../../../../generated/graphql";
import Headline1Variable from "../../../UI/Text/Headline/Headline1Variable";
import { FormFieldSelect } from "../../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import Button from "../../../UI/Button/Button";
import {
  displayAlertError,
  displayAlertSuccess,
  displayAlertWarning,
} from "../../../../app/globalSlice";
import LoadingDialog from "../../../UI/Dialog/LoadingDialog";
import { z, ZodFormattedError } from "zod";
import FormFieldControlled from "../../../UI/FormField/FormFieldControlled";
import CheckBox from "../../../UI/Checkbox/Checkbox";

const StaffTypeSchema = z
  .object({
    id: z.number().optional(),
    name: z.string(),
    baseRate: z.number(),
    isContractor: z.boolean(),
    regionId: z.number(),
  })
  .refine((data) => data.regionId > 0, {
    message: "You must select a region",
    path: ["regionId"],
  });

type StaffTypeFormValues = z.infer<typeof StaffTypeSchema>;

const initialStaffTypeData = {
  id: undefined,
  name: "",
  baseRate: 0,
  isContractor: false,
  regionId: 0,
};

const StaffTypeManagement: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams();
  const navigate = useNavigate();
  const { id } = params;
  const isEdit = id !== undefined && !isNaN(+id);

  const { selectedRegions }: any = useSelector(
    (state: RootState) => state.venueMaster
  );

  const [staffTypeData, setStaffTypeData] =
    useState<StaffTypeFormValues>(initialStaffTypeData);
  const [zodErrors, setZodErrors] = useState<
    ZodFormattedError<StaffTypeFormValues, string>
  >({ _errors: [] });

  const { loading: loadingStaffType } = useStaffTypeByIdQuery({
    variables: {
      id: +id!,
    },
    skip: !isEdit,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setStaffTypeData((prev) => ({
        name: data.staffTypeById.name,
        id: data.staffTypeById.id,
        isContractor: data.staffTypeById.isContractor ?? false,
        regionId: data.staffTypeById.regionId ?? 0,
        baseRate: data.staffTypeById.baseRate,
      }));
    },
  });

  const [
    CreateStaffType,
    { data: createData, loading: createLoading, error: createError },
  ] = useCreateStaffTypeMutation();
  const [
    UpdateStaffType,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useUpdateStaffTypeMutation();

  const handleSubmit = () => {
    const result = StaffTypeSchema.safeParse(staffTypeData);
    if (!result.success) {
      setZodErrors(result.error.format());
      dispatch(displayAlertWarning("There is an issue with the form"));
      return;
    }
    // submit content to database
    if (staffTypeData.id === undefined) {
      CreateStaffType({
        variables: {
          staffTypeArgs: {
            baseRate: staffTypeData.baseRate,
            name: staffTypeData.name,
            isContractor: staffTypeData.isContractor ?? false,
            regionId: staffTypeData.regionId,
          },
        },
        onCompleted: (data) => {
          if (data.createStaffType.success) {
            dispatch(displayAlertSuccess(data.createStaffType.message));
            navigate(-1);
          } else {
            dispatch(displayAlertError(data.createStaffType.message));
          }
        },
        onError: (error) => {
          dispatch(displayAlertError(error.message));
        },
      });
    } else {
      UpdateStaffType({
        variables: {
          staffTypeArgs: {
            id: staffTypeData.id,
            baseRate: staffTypeData.baseRate,
            isContractor: staffTypeData.isContractor,
            name: staffTypeData.name,
            regionId: staffTypeData.regionId,
          },
        },
        onCompleted: (data) => {
          if (data.updateStaffType.success) {
            dispatch(displayAlertSuccess(data.updateStaffType.message));
            navigate(-1);
          } else {
            dispatch(displayAlertError(data.updateStaffType.message));
          }
        },
        onError: (error) => {
          dispatch(displayAlertError(error.message));
        },
      });
    }
    return;
  };

  return (
    <main className="flex flex-col w-full gap-4">
      <LoadingDialog
        open={createLoading || updateLoading || loadingStaffType}
      />
      <div className="flex flex-row justify-between w-full">
        <Headline1Variable>
          {!isEdit ? "New" : "Edit"} Staff Type{" "}
        </Headline1Variable>
      </div>
      <div className="flex flex-col w-1/3 gap-4">
        <FormFieldSelect
          value={
            staffTypeData.regionId ? staffTypeData.regionId.toString() : "0"
          }
          inputChange={(value) => {
            if (zodErrors.regionId && value) {
              setZodErrors((prevState) => ({
                _errors: prevState?._errors ?? [],
                regionId: undefined,
              }));
            }
            setStaffTypeData((prevState) => ({
              ...prevState,
              regionId: +value,
            }));
          }}
          name="regionId"
          id="regionId"
          label="Region"
          placeholder="Select Region"
          className="h-[46px] m-0"
          disabled={isEdit ? true : false}
          error={zodErrors.regionId !== undefined}
          assistiveText={
            zodErrors.regionId !== undefined
              ? zodErrors.regionId._errors[0]
              : ""
          }
        >
          {selectedRegions.length > 0
            ? [...[{ id: 0, name: "Select Region" }], ...selectedRegions]
            : [...[{ id: 0, name: "None" }]]}
        </FormFieldSelect>
        <div className="flex flex-col gap-2">
          <FormFieldControlled
            name="name"
            value={staffTypeData.name}
            label="Name"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setStaffTypeData((prevState) => ({
                ...prevState,
                name: e.target.value,
              }));
            }}
          />
          <FormFieldControlled
            name="baseRate"
            value={staffTypeData.baseRate.toString()}
            type="number"
            label="Base Rate"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setStaffTypeData((prevState) => ({
                ...prevState,
                baseRate: +e.target.value,
              }));
            }}
          />

          <CheckBox
            checkedState={staffTypeData.isContractor}
            inputChange={(value) => {
              setStaffTypeData((prevState) => ({
                ...prevState,
                isContractor: value,
              }));
            }}
            label="Contractor"
          />
        </div>
        <Button
          variant="primary"
          onClick={handleSubmit}
          className="w-60"
        >
          {isEdit ? "Update Staff Type" : "Create Staff Type"}
        </Button>
      </div>
    </main>
  );
};

export default StaffTypeManagement;
