import { gql } from "@apollo/client";

const GET_VENUE_SIZE = gql`
  query VenueSizes {
    venueSizes {
      id
      name
    }
  }
`;
gql`
  query VenueSizeByTypeId($venueTypeId: Int!) {
    venueSizeByTypeId(venueTypeId: $venueTypeId) {
      id
      name
    }
  }
`;

export { GET_VENUE_SIZE };
