import React, { useEffect, useMemo, useState } from "react";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import Button from "../UI/Button/Button";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { getVendors, getVendorsCount } from "../../app/venueMasterSlice";
import {
  Box,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTable, useSortBy, Column } from "react-table";
import { Vendor } from "../../types/types";
import moment from "moment";
import classes from "./Vendors.module.css";
import Link1 from "../UI/Text/Link/Link1";
import TablePagination from "../UI/Pagination/Pagination";
import { LoadingMaterialUI } from "../UI";
import ActionsCell from "../UI/ActionCell";
import { DELETE_VENDOR } from "../../graphql/queries/vendor";
import { useMutation } from "@apollo/client";
import { displayAlertError, displayAlertSuccess } from "../../app/globalSlice";

const options = [
  { id: 1, text: "Edit" },
  { id: 2, text: "Delete" },
];

const Vendors = () => {
  const publicUrl = process.env.PUBLIC_URL;
  //Pagination Page Size
  const pageSize = 10;

  const dispatch = useDispatch<AppDispatch>();
  const { isLoading, vendorList, vendorListCount }: any = useSelector(
    (state: RootState) => state.venueMaster
  );
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<any>(null);
  const [deleteModal, setDeleteModal] = React.useState(0);

  const handleChangePagination = (value: number) => {
    setPagination({
      ...pagination,
      page: value - 1,
    });
  };
  const [DeleteVendor] = useMutation(DELETE_VENDOR);

  useEffect(() => {
    if (!vendorList.length) {
      dispatch(getVendorsCount());
    }
  }, [dispatch, vendorList]);

  useEffect(() => {
    if (vendorListCount) {
      const page = 0;
      const pageCount = Math.ceil(vendorListCount / pageSize);
      setPagination({
        page,
        pageSize,
        pageCount,
      });
    }
  }, [vendorListCount]);

  useEffect(() => {
    if (pagination) {
      dispatch(
        getVendors({
          page: pagination.page,
          pageSize: pagination.pageSize,
        })
      );
    }
  }, [pagination]);

  const handleCreateVendor = () => {
    // navigate(`${publicUrl}/ops/vendor/${vendorId}`, { state: { vendorId } });
    navigate(`${publicUrl}/ops/vendor`);
  };

  const handleDeleteVendor = () => {
    DeleteVendor({
      variables: {
        vendorDeleteId: deleteModal,
      },
    }).then((res) => {
      if (res.data.vendorDelete.success) {
        dispatch(
          getVendors({
            page: pagination.page,
            pageSize: pagination.pageSize,
          })
        );
        dispatch(displayAlertSuccess(res.data.vendorDelete.message));
      } else {
        dispatch(displayAlertError("Failed to delete vendor"));
      }
      setDeleteModal(0);
    });
  };

  const handleSelectedOption = (row: any, idx: number) => {
    if (idx === 1) {
      navigate(`${publicUrl}/ops/vendor/${row.values.id}`, {
        state: { vendorId: row.values.id },
      });
    }
    if (idx === 2) {
      setDeleteModal(row.values.id);
    }
  };

  const COLUMNS: Column<Vendor>[] = [
    {
      Header: "VendorId#",
      accessor: "id",
    },
    {
      Header: "Vendor",
      id: "name",
      accessor: (d: any) => {
        return (
          <a
            className="underline"
            href={`/ops/vendor/${d.id}`}
          >
            {d["name"]}
          </a>
        );
      },
    },
    {
      Header: "Type",
      id: "vendorType.id",
      accessor: (d: any) => {
        return <div className="truncate text-nowrap">{d.vendorType.name}</div>;
      },
    },
    {
      Header: "Contact",
      id: "primaryContact",
      accessor: (d: any) => {
        return <div className="truncate">{d.primaryContact}</div>;
      },
    },
    {
      Header: "Phone",
      id: "primaryPhone",
      accessor: (d: any) => {
        return <div>{d["primaryPhone"]}</div>;
      },
    },
    {
      Header: "After Hours Phone",
      accessor: "afterHoursPhone",
    },
    {
      Header: "Created",
      id: "createdAt",
      accessor: (d: any) => {
        return <div>{moment(d.createdAt).local().format("YYYY-MM-DD")}</div>;
      },
    },
    {
      Header: "Last Edit",
      id: "updatedAt",
      accessor: (d: any) => {
        return <div>{moment(d.updatedAt).local().format("YYYY-MM-DD")}</div>;
      },
    },
    {
      Header: " ",
      Cell: (data: any) => {
        return (
          <ActionsCell
            row={data.row}
            options={options}
            handleSelectedOption={handleSelectedOption}
          />
        );
      },
    },
  ];
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => vendorList, [vendorList]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: columns,
        data: data,
        initialState: {
          hiddenColumns: ["id"],
        },
      },
      useSortBy
    );
  const vendorTable = (
    <TableContainer
      className={classes.shift__container}
      sx={{
        maxHeight: "80vh",
        width: "95vw",
        maxWidth: "100%",
        bgcolor: "white",
        padding: "20px",
        borderRadius: "12px",
      }}
    >
      <Table
        stickyHeader
        aria-label="sticky table"
        {...getTableProps()}
      >
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  sx={{
                    bgcolor: "white",
                    color: "var(--textColor)",
                    fontSize: "16px",
                    fontWeight: 550,
                    borderColor: "var(--table-border)",
                    textAlign: "left",
                    paddingBottom: "5px",
                  }}
                  {...column
                    .getHeaderProps
                    // column.getSortByToggleProps()
                    ()}
                >
                  <div className={classes.headAlign}>
                    {column.render("Header")}
                    {/* <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ArrowDownwardIcon />
                      ) : (
                        <ArrowUpwardIcon />
                      )
                    ) : (
                      ''
                    )}
                  </span> */}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <TableRow
                hover
                sx={{
                  borderBottom: "5px",
                  borderWidth: "2px",
                }}
                role="checkbox"
                tabIndex={-1}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      {...cell.getCellProps()}
                      sx={{
                        textAlign: "left",
                        fontSize: "15px",
                        color: "var(--textColor)",
                        // borderWidth: '2px',
                        borderColor: "var(--table-border)",
                        paddingBottom: "5px",
                        paddingTop: "5px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        maxWidth: "200px",
                        // '&:hover': {
                        //   whiteSpace: 'wrap',
                        // },
                      }}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <div className="flex justify-end mt-4">
        <TablePagination
          page={pagination?.page}
          pageCount={pagination?.pageCount}
          onChange={handleChangePagination}
        />
      </div>
    </TableContainer>
  );

  if (isLoading) {
    return (
      <div>
        <LoadingMaterialUI />
      </div>
    );
  }

  return (
    <main>
      <div className="flex flex-row justify-between w-full mb-4">
        <Headline1Variable>Vendors</Headline1Variable>
        <div className="flex flex-row items-end h-full gap-4">
          {/* <Button variant="secondary" onClick={handleCreateVendor}>
            <FilterListIcon
              fontSize="small"
              sx={{
                width: '18px',
                height: '18px',
              }}
            />
            <span> Filters</span>
          </Button> */}
          <Button
            variant="primary"
            onClick={handleCreateVendor}
          >
            <AddIcon
              fontSize="large"
              sx={{
                width: "18px",
                height: "18px",
              }}
            />
            <span> New Vendor</span>
          </Button>
        </div>
      </div>
      {vendorList.length > 0 && (
        <div className={classes.table__venues}>{vendorTable}</div>
      )}
      {!(vendorList.length > 0) && (
        <div className={classes.table__contracts}>No Data Found</div>
      )}
      <Modal
        open={deleteModal ? true : false}
        onClose={() => {
          setDeleteModal(0);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "12px",
          }}
        >
          <p> Do you want to delete the vendor?</p>
          <div className="flex flex-row-reverse gap-3 mt-6">
            <Button
              variant="primary"
              onClick={handleDeleteVendor}
            >
              Delete
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setDeleteModal(0);
              }}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </main>
  );
};

export default Vendors;
