import { AllContractorShiftContextProvider } from "./AllContractorShiftContext";
import { EmailToolMarketingContextProvider } from "./EmailToolMarketingContext";
import { ImageManagerContextProvider } from "./ImageMangerContext";
import { PageTitleContextProvider } from "./PageTitleContext";
import { SessionWorkflowContextProvider } from "./SessionWorkflowContext";
import { StaffTypeContextProvider } from "./StaffTypeContext";
import { VenueOverviewContextProvider } from "./VenueOverviewContext";
import { WeatherContextProvider } from "./WeatherContext";

export const WrapperProvider = ({ children }: any) => {
  return (
    <SessionWorkflowContextProvider>
      <WeatherContextProvider>
        <VenueOverviewContextProvider>
          <AllContractorShiftContextProvider>
            <EmailToolMarketingContextProvider>
              <StaffTypeContextProvider>
                <ImageManagerContextProvider>
                  {children}
                </ImageManagerContextProvider>
              </StaffTypeContextProvider>
            </EmailToolMarketingContextProvider>
          </AllContractorShiftContextProvider>
        </VenueOverviewContextProvider>
      </WeatherContextProvider>
    </SessionWorkflowContextProvider>
  );
};
