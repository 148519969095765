import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  MenuItem,
  Menu,
  Toolbar,
  Typography,
  Checkbox,
} from "@mui/material";
import NormalLogo from "../../assets/logos/JAMsports_logo_smaller.png";
import SmallLogo from "../../assets/logos/JAMsports_logo_smallest.png";

import React, { useRef, useState } from "react";
import {
  getJwtCookie,
  getUserDetails,
  logout,
  removeJwtCookie,
} from "../../app/authSlice";
import { useNavigate } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import { MenuProps } from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { getRegions, handleSelectedRegions } from "../../app/venueMasterSlice";
import PersonOutlineRoundedIcon from "@mui/icons-material/PersonOutlineRounded";
import { Users } from "@/src/types/types";
import FormField from "../UI/FormField/FormField";
import {
  GetUsersQuery,
  useGetUsersLazyQuery,
} from "../../../src/generated/graphql";
import WeatherNavBar from "../Admin/Weather/WeatherNavBar";

const settings = [
  // { id: 1, text: 'Profile', to: `/profile` },
  { id: 3, text: "Logout", to: `/logout` },
];

const Navbar = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { regions }: any = useSelector((state: RootState) => state.venueMaster);
  const { user, token }: any = useSelector((state: RootState) => state.auth);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElRegion, setAnchorElRegion] = useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [openMenu, setOpenMenu] = useState<number | null>(null);
  const [selectedRegions, setSelectedRegions] = React.useState([]);
  const [userFilter, setUserFilter] = useState<string | null>(null);
  const [usersListAutoComplete, setUsersListAutoComplete] = useState<
    GetUsersQuery["getUsers"]["users"]
  >([]);

  const open = Boolean(anchorElRegion);

  const inputRef = useRef<HTMLDivElement>(null); // Reference for the input field
  const autocompleteRef = useRef<HTMLDivElement>(null); // Reference for the autocomplete list

  const [getUserAutoCompleteList] = useGetUsersLazyQuery({
    variables: {
      userFilter: userFilter,
    },
    onCompleted: (data) => {
      setUsersListAutoComplete(data.getUsers.users);
    },
    fetchPolicy: "network-only",
  });

  const handleClickRegion = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElRegion(event.currentTarget);
  };

  const handleCloseRegion = () => {
    setAnchorElRegion(null);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleUserMenuOptions = (
    event: React.MouseEvent<HTMLElement>,
    idx: number
  ) => {
    if (idx === 3) {
      handleLogout();
    }
  };

  const pathname: string = window.location.pathname;

  const handleLogout = () => {
    dispatch(logout());
  };

  const onChangeRegions = (e: any, index: number) => {
    const regionsCopy: any = JSON.parse(JSON.stringify(selectedRegions));
    regionsCopy[index].selected = e.target.checked;
    setSelectedRegions(regionsCopy);
  };

  const onClickChangeAll = (state: boolean) => {
    const regionsCopy: any = JSON.parse(JSON.stringify(selectedRegions));
    const selectAllRegions = regionsCopy.map((region: any) => ({
      ...region,
      selected: state,
    }));
    setSelectedRegions(selectAllRegions);
  };

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node) &&
        autocompleteRef.current &&
        !autocompleteRef.current.contains(event.target as Node)
      ) {
        setUsersListAutoComplete([]); // Clear the autocomplete list on outside click
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  React.useEffect(() => {
    dispatch(getRegions(""));
    if (!user) {
      const token = getJwtCookie();
      if (token == null) {
        // navigate(`/login`);
        return;
      }
      dispatch(getUserDetails(token));
    }
  }, [dispatch]);

  React.useEffect(() => {
    const regionsCopy: any = [...regions];
    const selectedRegions = regionsCopy.map((region: any) => ({
      ...region,
      selected: true,
    }));
    setSelectedRegions(selectedRegions);
  }, [regions]);

  React.useEffect(() => {
    const selectedRegionsArray = selectedRegions.filter(
      (region: any) => region.selected === true
    );
    dispatch(handleSelectedRegions(selectedRegionsArray));
  }, [selectedRegions]);

  React.useEffect(() => {
    getUserAutoCompleteList({
      variables: {
        userFilter: userFilter,
        page: null,
        pageSize: null,
      },
    });
  }, [userFilter]);

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }: any) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor:
          process.env.REACT_APP_ENV === "production"
            ? "var(--secondary-10)"
            : "var(--staging-nav)",

        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar className="flex justify-between">
        {/* Part 1: JAM ICON */}
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{
            mr: 2,
            paddingBottom: "5px",
            paddingTop: "5px",
          }}
        >
          <img
            src={SmallLogo}
            srcSet={`${SmallLogo} 1x, ${NormalLogo} 2x`}
            sizes="100px"
            alt="JAMsports Logo"
            style={{ width: "100px", height: "auto" }}
          />
        </IconButton>

        <div className="flex gap-2">
          <div className="flex flex-row items-end h-full gap-4">
            <div
              className="w-96 h-[90%]"
              ref={inputRef}
            >
              <FormField
                initialValue={userFilter || ""}
                inputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.value.length > 2) setUserFilter(e.target.value);
                  else setUserFilter(null);
                }}
                onKeyDown={(event) => {
                  if (
                    usersListAutoComplete.length > 0 &&
                    userFilter &&
                    event.key === "Enter"
                  ) {
                    setUsersListAutoComplete([]);
                  }
                }}
                name="search"
                placeholder="Player Search"
                width="full"
                className="bg-gray-100"
              ></FormField>
              {usersListAutoComplete.length > 0 && userFilter ? (
                <div
                  className="absolute z-10 w-96"
                  ref={autocompleteRef}
                >
                  <div className="max-w-md p-4 bg-white border rounded-lg shadow-md sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                    <div className="flow-root">
                      <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                        {usersListAutoComplete.map((e: any) => (
                          <li
                            className="py-3 cursor-pointer sm:py-2"
                            onClick={() => {
                              setUsersListAutoComplete([]);
                              navigate(`/players/user/${e.id}`);
                            }}
                          >
                            <div className="flex items-center space-x-4 rounded-md">
                              <div className="flex-1 min-w-0">
                                <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                  {e.firstName} {e.lastName}
                                </p>
                                <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                  {e.email} - {e.phoneNumber}
                                </p>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <Button
            id="demo-customized-button"
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disableElevation
            onClick={handleClickRegion}
            style={{ color: "white", paddingRight: "20px" }}
            endIcon={<KeyboardArrowDownIcon />}
          >
            Regions
          </Button>
          <StyledMenu
            id="demo-customized-menu"
            anchorEl={anchorElRegion}
            open={open}
            onClose={handleCloseRegion}
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
          >
            <>
              <MenuItem
                style={{ padding: "0px 10px", justifyContent: "center" }}
                disableRipple
              >
                <Button
                  size="small"
                  variant="text"
                  onClick={() => onClickChangeAll(true)}
                >
                  SELECT ALL
                </Button>
              </MenuItem>
              <MenuItem
                style={{ padding: "0px 10px", justifyContent: "center" }}
                disableRipple
              >
                <Button
                  size="small"
                  variant="text"
                  style={{ color: "gray" }}
                  onClick={() => onClickChangeAll(false)}
                >
                  DESELECT ALL
                </Button>
              </MenuItem>
              {selectedRegions &&
                selectedRegions.map((region: any, index: number) => (
                  <MenuItem
                    key={region.id}
                    style={{ padding: "0px 10px" }}
                    disableRipple
                  >
                    <Checkbox
                      checked={region.selected}
                      style={{ padding: "5px 10px" }}
                      onChange={(e) => onChangeRegions(e, index)}
                    />
                    {region.name}
                  </MenuItem>
                ))}
            </>
          </StyledMenu>
          <WeatherNavBar />

          <Box sx={{ flexGrow: 0 }}>
            <IconButton
              onClick={handleOpenUserMenu}
              sx={{ p: 0, color: "white" }}
            >
              <PersonOutlineRoundedIcon color="inherit" />
            </IconButton>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting.id}
                  onClick={(event) => {
                    handleUserMenuOptions(event, setting.id);
                  }}
                >
                  <Typography textAlign="center">{setting.text}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
