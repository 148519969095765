import { FC, useState } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "../../UI/shadcn/dialog";
import {
  ListAllOperations,
  useCreateBugReportMutation,
  useCreateFeatureReportMutation,
  useTypeOfBugsQuery,
} from "../../../generated/graphql";
import { FormFieldSelect } from "../FormField/FormFieldDropdown/FormFieldSelectV2";
import { z, ZodFormattedError } from "zod";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import Button from "../Button/Button";
import ReactQuill from "react-quill";
import { useLocation } from "react-router-dom";
import UAParser from "ua-parser-js";
import { useDispatch } from "react-redux";
import {
  displayAlertError,
  displayAlertSuccess,
  displayAlertWarning,
} from "../../../app/globalSlice";
import { AppDispatch } from "../../../app/store";
import LoadingDialog from "./LoadingDialog";

const FeedbackSchema = z.object({
  bugOrFeature: z.number().refine((val) => val === 0 || val === 1),
  typeOfBugId: z.number(),
  comment: z.string(),
});

type FeedbackType = z.infer<typeof FeedbackSchema>;

declare module "react-quill" {
  interface QuillStatic {
    register: (module: any, suppressWarning?: boolean) => void;
  }
}

const FeedbackDialog: FC = () => {
  let location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const [feedbackForm, setFeedbackForm] = useState<FeedbackType>({
    bugOrFeature: 0,
    typeOfBugId: 0,
    comment: "",
  });
  // Zod errors used to show errors on the form
  const [zodErrors, setZodErrors] = useState<
    ZodFormattedError<FeedbackType, string>
  >({ _errors: [] });

  const { data: typeOfBugData, loading: loadingTypeOfBug } = useTypeOfBugsQuery(
    {
      nextFetchPolicy: "cache-first",
    }
  );
  const [CreateBugReport, { loading: loadingCreateBugReport }] =
    useCreateBugReportMutation({});
  const [CreateFeatureReport, { loading: loadingCreateFeatureReport }] =
    useCreateFeatureReportMutation({});

  async function handleSubmit() {
    const validationResult = FeedbackSchema.safeParse(feedbackForm);
    if (!validationResult.success) {
      setZodErrors(validationResult.error.format());
      dispatch(displayAlertWarning("There is an issue with the form"));
      return;
    }
    const parser = new UAParser();
    const result = parser.getResult();
    if (feedbackForm.bugOrFeature === 0) {
      CreateBugReport({
        variables: {
          createBugReportInput: {
            comment: feedbackForm.comment,
            relativeUrl: location.pathname,
            typeOfBugId: feedbackForm.typeOfBugId,
            operatingSystem: `${result.os.name} ${result.os.version}`,
            deviceType: result.device.type || "Desktop",
            browserVersion: `${result.browser.name} ${result.browser.version}`,
          },
        },
        refetchQueries: [ListAllOperations.Query.BugReportPaginated],
        onCompleted: (data) => {
          if (data.createBugReport.success) {
            dispatch(displayAlertSuccess(data.createBugReport.message));
          } else {
            dispatch(displayAlertError(data.createBugReport.message));
          }
        },
        onError: (err) => {
          dispatch(displayAlertError(err.message));
        },
      });
    } else {
      CreateFeatureReport({
        variables: {
          createFeatureReportInput: {
            comment: feedbackForm.comment,
            relativeUrl: location.pathname,
          },
        },
        refetchQueries: [ListAllOperations.Query.FeatureReportPaginated],
        onCompleted: (data) => {
          if (data.createFeatureReport.success) {
            dispatch(displayAlertSuccess(data.createFeatureReport.message));
          } else {
            dispatch(displayAlertError(data.createFeatureReport.message));
          }
        },
        onError: (err) => {
          dispatch(displayAlertError(err.message));
        },
      });
    }
  }

  return (
    <Dialog>
      <LoadingDialog
        open={
          loadingCreateBugReport ||
          loadingCreateFeatureReport ||
          loadingTypeOfBug
        }
      />
      <DialogTrigger>
        <Button variant="secondary">
          <AddCommentOutlinedIcon />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>Provide Feedback</DialogHeader>
        <DialogDescription>
          <div className="flex flex-col gap-4">
            <FormFieldSelect
              value={feedbackForm.bugOrFeature.toString()}
              inputChange={(value: string) => {
                const select = +value;
                if (select === 0 || select === 1) {
                  setFeedbackForm((prevState) => ({
                    ...prevState,
                    bugOrFeature: select,
                  }));
                }
              }}
              label="Bug or Feature"
              placeholder="Bug or Feature?"
            >
              {[
                { id: 0, name: "Bug" },
                { id: 1, name: "Feature" },
              ]}
            </FormFieldSelect>
            {feedbackForm.bugOrFeature === 0 && (
              <FormFieldSelect
                value={feedbackForm.typeOfBugId.toString()}
                inputChange={(value: string) => {
                  setFeedbackForm((prevState) => ({
                    ...prevState,
                    typeOfBugId: +value,
                  }));
                }}
                error={zodErrors.typeOfBugId !== undefined}
                assistiveText={
                  zodErrors.typeOfBugId !== undefined
                    ? zodErrors.typeOfBugId._errors[0]
                    : ""
                }
                label="Type of Bug"
                placeholder="Type of Bug"
              >
                {[
                  { id: 0, name: "Select Type of Bug" },
                  ...(typeOfBugData ? typeOfBugData.typeOfBugs : []),
                ]}
              </FormFieldSelect>
            )}
            <div className="mb-12">
              <ReactQuill
                style={{ height: "200px", width: "auto" }}
                theme="snow" // you can use 'bubble' theme as well
                value={feedbackForm.comment}
                onChange={(text: string) => {
                  setFeedbackForm((prevState) => ({
                    ...prevState,
                    comment: text,
                  }));
                }}
              />
            </div>
          </div>
        </DialogDescription>
        <DialogFooter>
          <DialogClose>
            <Button
              variant="primary"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
export default FeedbackDialog;
