import { gql } from "@apollo/client";

const GET_DYNAMIC_SPORT_FORMAT = gql`
  query SportFormatDynamic(
    $sportFormatDynamicFilter: SportFormatDynamicFilter!
  ) {
    sportFormatDynamic(sportFormatDynamicFilter: $sportFormatDynamicFilter) {
      id
      name
    }
  }
`;
const GET_SPORTS_FORMAT = gql`
  query SportFormats(
    $sportFormatFilters: SportFormatFilters
    $page: Int
    $pageSize: Int
  ) {
    sportFormats(
      sportFormatFilters: $sportFormatFilters
      page: $page
      pageSize: $pageSize
    ) {
      sportsFormat {
        genderFormat {
          name
        }
        id
        leagueType {
          name
        }
        region {
          name
        }
        sport {
          name
        }
        name
        officiatedStatus {
          name
        }
        venueType {
          id
          name
        }
        peopleOnField
        minWomen
        minMen
        maxRosterSizeTeam
      }
      count
    }
  }
`;

const GET_SPORT_FORMAT_BY_ID = gql`
  query SportFormat($sportFormatId: Int!) {
    sportFormat(id: $sportFormatId) {
      abbreviation
      defaultEmail
      genderFormatId
      id
      weeksOfPlayOffs
      venueTypeId
      venueSizeId
      ruleId
      tieBreakPolicyId
      sportId
      maxRosterSizeIndy
      peopleOnField
      officiatedStatusId
      regionId
      name
      minWomenIndy
      minWomen
      minMenIndy
      minMen
      maxRosterSizeTeam
      leagueTypeId
      scoringFormatId
      staffRequired {
        count
        staffTypeId
      }
    }
  }
`;

gql`
  mutation SportFormatCreate($sportFormatArgs: CreateSportFormatInput!) {
    sportFormatCreate(sportFormatArgs: $sportFormatArgs) {
      message
      code
      success
    }
  }
`;

gql`
  mutation SportFormatUpdate($sportFormatArgs: UpdateSportFormatInput!) {
    sportFormatUpdate(sportFormatArgs: $sportFormatArgs) {
      code
      message
      success
    }
  }
`;

export { GET_DYNAMIC_SPORT_FORMAT, GET_SPORTS_FORMAT, GET_SPORT_FORMAT_BY_ID };
