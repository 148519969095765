import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  HttpLink,
  ApolloLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import axios from "axios";
import { getJwtCookie, removeJwtCookie } from "../app/authSlice";
import { baseApiUrl } from "../utils/baseUrl";

const httpLink = new HttpLink({
  uri:
    process.env.REACT_APP_ENV === "production"
      ? "https://admin.jamsports.com/api/graphql"
      : process.env.REACT_APP_ENV === "staging"
      ? "https://admin.jamitall.com/api/graphql"
      : "http://localhost:3001/graphql",
  credentials: "same-origin",
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    process.env.REACT_APP_ENV === "development" &&
      console.log(`[GraphQL Error]: ${graphQLErrors}`);

    graphQLErrors.forEach(async (error) => {
      if (error.extensions?.code === "UNAUTHENTICATED") {
        removeJwtCookie();
        window.location.href = "/login";
        return;
      } else {
        process.env.REACT_APP_ENV === "development" &&
          console.log("GraphQL Error:", error);
      }
    });
  }

  if (networkError) {
    process.env.REACT_APP_ENV === "development" &&
      console.log(`[Network Error]: ${networkError}`);
    return;
  }
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from the coookie
  const token = getJwtCookie();
  if (!token) {
    console.log("NO token");
    window.location.href = "/login";
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "Apollo-Require-Preflight": "true",
      "Access-Control-Allow-Origin": "*",
      "X-Apollo-Operation-Name": "IntrospectionQuery",
    },
  };
});

export const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
});
