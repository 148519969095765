import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../app/store";
import classes from "./ContractManagement.module.css";
import { Column } from "react-table";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import { Pagination, Season } from "../../types/types";
import { getRegistrationBatch } from "../../app/venueMasterSlice";
import { useMutation } from "@apollo/client";
import { DELETE_REGISTRATION_BATCH } from "../../graphql/queries";
import { LoadingMaterialUI } from "../UI";
import BaseTable from "../UI/Table/Table";
import Button from "../UI/Button/Button";
import ActionsCell from "../UI/ActionCell";
import Headline1Variable from "../UI/Text/Headline/Headline1Variable";
import TablePagination from "../UI/Pagination/Pagination";

const options = [
  { id: 1, text: "Edit" },
  { id: 2, text: "Delete" },
];

const Seasons: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { registrationBatches, isLoading, registrationBatchesCount }: any =
    useSelector((state: RootState) => state.venueMaster);

  //Pagination Page Size
  const pageSize = 25;

  const [pagination, setPagination] = React.useState<null | Pagination>(null);

  const [
    DeleteRegistrationBatch,
    { dataDelete, loadingDelete, errorDelete },
  ]: any = useMutation(DELETE_REGISTRATION_BATCH);

  const redirectCreateSeason = () => {
    navigate(`/ops/season/`);
  };

  const redirectEditSeason = (seasonId: number) => {
    navigate(`/ops/season/${seasonId}`);
  };

  const handleSelectedOption = (row: any, idx: number) => {
    if (idx === 1) {
      navigate(`/ops/season/${row.original.id}`);
    }
    if (idx === 2) {
      DeleteRegistrationBatch({
        variables: { registrationBatchDeleteId: +row.original.id },
      }).then(() => {
        dispatch(getRegistrationBatch(""));
      });
    }
  };

  useEffect(() => {
    dispatch(
      getRegistrationBatch({
        page: pagination?.page,
        pageSize: pagination?.pageSize,
      })
    );
  }, []);

  useEffect(() => {
    if (pagination) {
      dispatch(
        getRegistrationBatch({
          page: pagination?.page,
          pageSize: pagination?.pageSize,
        })
      );
    }
  }, [pagination]);

  useEffect(() => {
    const page = 0;
    const pageCount = Math.ceil(registrationBatchesCount / pageSize);
    setPagination({
      page,
      pageSize,
      pageCount,
    });
  }, [registrationBatchesCount]);

  const handleChangePagination = (value: number) => {
    setPagination({
      pageCount: pagination?.pageCount,
      pageSize: pagination?.pageSize,
      page: value - 1,
    });
  };

  const COLUMNS: Column<Season>[] = [
    {
      Header: "Name",
      id: "name",
      accessor: (d: any) => {
        return (
          <a
            className="underline"
            href={`/ops/season/${d.id}`}
          >
            {d["name"]}
          </a>
        );
      },
    },
    {
      Header: "Reg Open Date",
      id: "regOpenDate",
      accessor: (d: any) => {
        return (
          <div>{moment(d.regOpenDate).local().format("YYYY-MM-DD HH:mm")}</div>
        );
      },
    },
    {
      Header: "Reg Close Date",
      id: "regCloseDate",
      accessor: (d: any) => {
        return (
          <div>{moment(d.regCloseDate).local().format("YYYY-MM-DD HH:mm")}</div>
        );
      },
    },
    {
      Header: "Display Price",
      id: "displayPrice",
      accessor: (d: any) => {
        return <div>{d.displayPrice ? "Yes" : "No"}</div>;
      },
    },
    {
      Header: "Display Session",
      id: "displaySession",
      accessor: (d: any) => {
        return <div>{d.displaySession ? "Yes" : "No"}</div>;
      },
    },
    {
      Header: " ",
      Cell: (data: any) => {
        return (
          <ActionsCell
            row={data.row}
            options={options}
            handleSelectedOption={handleSelectedOption}
          />
        );
      },
    },
  ];

  const columns: any = useMemo(() => COLUMNS, []);
  const data = useMemo(() => registrationBatches, [registrationBatches]);

  return (
    <main>
      <div className="flex flex-row justify-between w-full mb-4">
        <Headline1Variable>Seasons</Headline1Variable>
        <div className="mb-4 text-end">
          <Button
            variant="primary"
            onClick={redirectCreateSeason}
          >
            <AddIcon />
            New Season
          </Button>
        </div>
      </div>
      {(isLoading || loadingDelete) && <LoadingMaterialUI />}
      {registrationBatches.length > 0 && (
        <BaseTable
          columns={columns}
          data={data}
        />
      )}

      {pagination && (
        <div className="flex justify-end mt-4">
          <TablePagination
            page={pagination?.page || 0}
            pageCount={pagination?.pageCount || 0}
            onChange={handleChangePagination}
          />
        </div>
      )}
      {!(registrationBatches.length > 0) && (
        <div className={classes.table__contracts}>No Data Found</div>
      )}
    </main>
  );
};

export default Seasons;
