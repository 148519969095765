import * as React from "react";

import { cn } from "../../../lib/utils";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: boolean;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    return (
      <textarea
        className={cn(
          "w-full font-normal rounded-xl px-3 py-2",
          props.disabled
            ? "border-2 border-neutral-60 bg-neutral-90 focus:outline-none"
            : props.error
            ? "border border-error-30 hover:border-error-30 focus:outline-2 focus:outline-error-30"
            : "border border-secondary-80 hover:border-secondary-50 focus:outline-2 focus:outline-primary-80",
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
Textarea.displayName = "Textarea";

export { Textarea };
