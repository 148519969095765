import { gql } from "@apollo/client";

const GET_SESSIONS_FOR_CAPACITY_GROUP = gql`
  query SessionsForCapacityGroup($sessionFilters: SessionFilters) {
    sessions(sessionFilters: $sessionFilters) {
      id
      dayOfWeek
      startDate
      league {
        name
      }
    }
  }
`;

const GET_SESSION_BY_ID = gql`
  query Session($sessionId: Int!) {
    session(id: $sessionId) {
      setCapacity
      dayOfWeek
      endDate
      exceptionDates
      id
      indyTeams
      league {
        id
        venues {
          id
          name
        }
      }
      maxCapacity
      numberOfWeeks
      priceForIndy
      registrationBatch {
        id
      }
      priceForTeam
      sessionNotes
      startTimes
      startDate
      venues {
        id
      }
      weeksOfPlayoffs
      regSkillChoices {
        id
        name
      }
      capacityGroup {
        id
      }
      isActive
    }
  }
`;

const GET_SESSIONS_FOR_WAYFINDER = gql`
  query SessionsPaginated($sessionsPaginatedInput: SessionsWayfinderInput) {
    sessionsPaginated(sessionsPaginatedInput: $sessionsPaginatedInput) {
      count
      sessions {
        id
        region {
          id
          name
        }
        registrationBatch {
          id
          name
          regOpenDate
        }
        league {
          id
          name
        }
        dayOfWeek
        startDate
        setCapacity
        indyTeams
        teamCount
        freeAgentCount {
          totalCount
          men
          women
          other
        }
        divisions {
          id
          name
        }
      }
    }
  }
`;

gql`
  query SessionsForDivision($sessionFilters: SessionFilters) {
    sessions(sessionFilters: $sessionFilters) {
      id
      dayOfWeek
      startDate
      endDate
      numberOfWeeks
      weeksOfPlayoffs
      exceptionDates
      league {
        name
      }
      teams {
        id
        name
        registration {
          regQuestionChoice {
            regSkillChoice {
              name
            }
          }
        }
      }
      divisions {
        id
        vmColor
        vmName
        weeksOfPlayoffs
        numberOfWeeks
        startDate
        name
        exceptionDates
        endDate
        teams {
          id
          name
        }
      }
    }
  }
`;

const CREATE_SESSIONS = gql`
  mutation SessionCreate($sessionArgs: CreateSessionInput!) {
    sessionCreate(sessionArgs: $sessionArgs) {
      code
      message
      success
    }
  }
`;

const UPDATE_SESSIONS = gql`
  mutation SessionUpdate($sessionArgs: UpdateSessionInput!) {
    sessionUpdate(sessionArgs: $sessionArgs) {
      code
      message
      success
    }
  }
`;

gql`
  query SessionForSessionHQ($sessionId: Int!) {
    session(id: $sessionId) {
      setCapacity
      dayOfWeek
      endDate
      exceptionDates
      id
      indyTeams
      teams {
        id
        name
        isIndyTeam
      }
      league {
        id
        name
        sportFormat {
          id
          name
          sport {
            name
            id
          }
          region {
            name
          }
          maxRosterSizeIndy
        }
      }
      freeAgentCount {
        totalCount
        men
        women
        other
      }
      waitList {
        user {
          genderIdentityId
        }
        productTypeId
      }
      maxCapacity
      numberOfWeeks
      priceForIndy
      priceForTeam
      registrationBatch {
        name
      }
      divisions {
        id
        name
      }
      capacityGroup {
        id
      }
      sessionNotes
      startDate
      startTimes
      weeksOfPlayoffs
      teamsRegistered
      faRegistered
    }
  }
`;

const GET_SESSIONS = gql`
  query Sessions($sessionFilters: SessionFilters) {
    sessions(sessionFilters: $sessionFilters) {
      setCapacity
      dayOfWeek
      endDate
      exceptionDates
      id
      indyTeams
      teams {
        id
        name
        isIndyTeam
      }
      league {
        name
        sportFormat {
          sport {
            name
            id
          }
          region {
            name
          }
          maxRosterSizeIndy
        }
      }
      maxCapacity
      numberOfWeeks
      priceForIndy
      priceForTeam
      registrationBatch {
        name
      }
      divisions {
        id
        name
      }
      sessionNotes
      startDate
      startTimes
      weeksOfPlayoffs
      teamsRegistered
      faRegistered
    }
  }
`;

const GET_SESSION_BY_DAY_AND_LEAGUE_SEARCH = gql`
  query getSessionByDayAndLeagueSearch(
    $dayOfWeek: Int!
    $leagueSearch: String!
    $regions: [Int]!
    $userId: String!
  ) {
    getSessionByDayAndLeagueSearch(
      dayOfWeek: $dayOfWeek
      leagueSearch: $leagueSearch
      regions: $regions
      userId: $userId
    ) {
      id
      dayOfWeek
      startDate
      endDate
      startTimes
      setCapacity
      maxCapacity
      priceForTeam
      priceForIndy
      indyTeams
      numberOfWeeks
      weeksOfPlayoffs
      exceptionDates
      sessionNotes
      registrationBatch {
        id
        name
        regOpenDate
        regCloseDate
      }
      league {
        id
        name
        sportFormat {
          sport {
            name
          }
          region {
            id
            name
            taxRate
            txnFee {
              id
              productTypeId
              amount
            }
          }
        }
      }
      availability {
        freeAgent
        team
      }
    }
  }
`;

const GET_WAITLISTED_SESSIONS_BY_DAY_AND_LEAGUE_SEARCH = gql`
  query getWaitlistSessionsByDayAndLeagueSearch(
    $dayOfWeek: Int!
    $regions: [Int]!
    $userId: String!
    $leagueSearch: String!
  ) {
    getWaitlistSessionsByDayAndLeagueSearch(
      dayOfWeek: $dayOfWeek
      regions: $regions
      userId: $userId
      leagueSearch: $leagueSearch
    ) {
      id
      dayOfWeek
      startDate
      endDate
      startTimes
      setCapacity
      maxCapacity
      priceForTeam
      priceForIndy
      indyTeams
      numberOfWeeks
      weeksOfPlayoffs
      exceptionDates
      sessionNotes
      registrationBatch {
        id
        name
        regOpenDate
        regCloseDate
      }
      league {
        id
        name
        sportFormat {
          sport {
            name
          }
          region {
            id
            name
            taxRate
            txnFee {
              id
              productTypeId
              amount
            }
          }
        }
      }
      availability {
        freeAgent
        team
      }
    }
  }
`;

gql`
  query SessionForSessionEmailTool($sessionId: Int!) {
    session(id: $sessionId) {
      setCapacity
      dayOfWeek
      endDate
      exceptionDates
      id
      indyTeams
      league {
        id
        sportFormat {
          id
          name
          ruleId
          region {
            id
            name
            email
            address
            websiteLink
            country {
              countryCode
            }
          }
          sport {
            id
          }
        }
        venues {
          id
          name
        }
      }
      maxCapacity
      numberOfWeeks
      priceForIndy
      registrationBatch {
        id
      }
      priceForTeam
      sessionNotes
      startTimes
      startDate
      venues {
        id
      }
      weeksOfPlayoffs
      regSkillChoices {
        id
        name
      }
      capacityGroup {
        id
      }
      isActive
    }
  }
`;

export {
  GET_SESSIONS_FOR_CAPACITY_GROUP,
  GET_SESSIONS_FOR_WAYFINDER,
  GET_SESSION_BY_ID,
  GET_WAITLISTED_SESSIONS_BY_DAY_AND_LEAGUE_SEARCH,
  GET_SESSION_BY_DAY_AND_LEAGUE_SEARCH,
  CREATE_SESSIONS,
  UPDATE_SESSIONS,
  GET_SESSIONS,
};
